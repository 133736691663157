<template>
  <div>
    <identificationresult
      ref="predictResult"
      :predict-result-info="predictResultInfo"
    />
    <identification-resulttwo
      ref="predictResult2"
      :predict-result-info="predictResultInfo2"
    />
    <el-dialog
      v-bind="$attrs"
      v-model="dialogVisible"
      title="灾区识别"
      @open="onOpen"
      @close="onClose"
    >
      <el-row :gutter="15">
        <el-form
          ref="Predict"
          :model="formData"
          :rules="rules"
          size="medium"
          label-width="100px"
        >
          <el-col :span="8">
            <el-form-item
              label="模型选择"
              prop="model"
            >
              <el-select
                v-model="formData.model"
                placeholder="请选择模型"
                clearable
                :style="{ width: '100%' }"
              >
                <el-option
                  v-for="(item, index) in modelOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item
              label="识别类别"
              prop="classes"
            >
              <el-checkbox-group
                v-model="formData.classes"
                :min="1"
                :max="4"
                size="medium"
              >
                <el-checkbox
                  v-for="(item, index) in classesOptions"
                  :key="index"
                  :label="item.value"
                  :disabled="item.disabled"
                >
                  {{ item.label }}
                </el-checkbox>
              </el-checkbox-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="图像高度"
              prop="height"
            >
              <el-input-number
                v-model="formData.height"
                placeholder="输入图像高度"
                :step="50"
                :min="1"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="图像宽度"
              prop="width"
            >
              <el-input-number
                v-model="formData.width"
                placeholder="输入图像宽度"
                :step="1"
                :min="1"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="置信得分"
              prop="score"
            >
              <el-input-number
                v-model="formData.score"
                placeholder="置信得分"
                :step="0.05"
                :precision="2"
                :max="1"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="交并比"
              prop="iou"
            >
              <el-input-number
                v-model="formData.iou"
                placeholder="交并比"
                :step="0.05"
                :precision="2"
                :max="1"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="融合比"
              prop="blend"
            >
              <el-input-number
                v-model="formData.blend"
                placeholder="融合比"
                :step="0.1"
                :precision="2"
                :max="1"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="置信度"
              prop="conf"
            >
              <el-input-number
                v-model="formData.conf"
                placeholder="置信度"
                :step="0.005"
                :precision="2"
                :max="1"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="类别标签"
              prop="show_label"
              required
            >
              <el-switch
                v-model="formData.show_label"
                active-text="识别结果图中标出类别"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="目标边框"
              prop="show_boxes"
              required
            >
              <el-switch
                v-model="formData.show_boxes"
                active-text="识别结果图中标出边框"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="显示概率"
              prop="show_conf"
              required
            >
              <el-switch
                v-model="formData.show_conf"
                active-text="识别结果图中标出正确概率"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-row
              type="flex"
              justify="center"
              align="middle"
            >
              <el-col :span="16">
                <el-form-item
                  label="上传图片"
                  prop="fileList"
                  required
                >
                  <el-upload
                    ref="upload"
                    v-loading="loading"
                    :action="uploadUrl"
                    multiple
                    method="post"
                    :auto-upload="false"
                    :element-loading-text="loadingText"
                    :file-list="fileList"
                    :on-change="onChange"
                    :on-progress="onProgress"
                    :on-success="onSuccess"
                    :before-upload="beforeUpload"
                  >
                    <el-button
                      size="small"
                      type="primary"
                      icon="el-icon-upload"
                    >
                      点击上传图片或者ZIP压缩文件
                    </el-button>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item
                  label=""
                  prop="upload"
                >
                  <el-button
                    type="success"
                    icon="el-icon-s-promotion"
                    size="medium"
                    @click="queryResut()"
                  >
                    开始识别并查看结果
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-form>
      </el-row>
    </el-dialog>
  </div>
</template>
<script>
import {baseURLs} from "@/axios/http-config";
import {myMessage} from "@/assets/js/utils";
import {img_predict} from "@/axios/api/others-api";
// import { img_predict, regional_analysis } from "@/axios/api/OthersApi";
import identificationresult from  "@/components/disasterarea-identification-result.vue"
import IdentificationResulttwo from "@/components/disasterarea-identification-resulttwo.vue";
export default {
  name: "disasterarea-identification",
  components: {
      IdentificationResulttwo,
      identificationresult
  },
  inheritAttrs: false,
  props: [],
  data() {
    return {
      fileType: "image",
      predictResultInfo2:'',
      predictResultInfo: {
        information: {
          altitude: 2136.987,
          analysis: {
            intact: 5,
            severe: 1,
            slight: 6,
          },
          latitude: 25.695977,
          location: "云南省大理白族自治州漾濞彝族自治县苍山西镇",
          longitude: 99.87274441666666,
          time: "2021:05:25 17:37:52",
        },
        url: [
          "2024-03-23-20-52-19-6659.jpg",
          "2024-03-23-20-52-19-6659_predict.jpg",
          "https://file.zouran.top/imgs/fenxi/legend.png",
        ],
      },
      predict_preview: baseURLs.imgApi + "/predict_preview/",
      predict: "/predict/",
      predictVisible: false,
      fileList: [],
      maxSize: 10,
      loading: false,
      loadingText: "",
      dialogVisible: false,
      formData: {
        model: "yolov8-seg",
        classes: ["severe", "collapse"],
        height: 640,
        width: 640,
        score: 0.7,
        iou: 0.5,
        blend: 0.7,
        conf: 0.25,
        show_label: false,
        show_boxes: false,
        show_conf: false,
      },
      rules: {
        model: [
          {
            required: true,
            message: "请选择模型",
            trigger: "change",
          },
        ],
        classes: [
          {
            required: true,
            type: "array",
            message: "请至少选择一个识别类别",
            trigger: "change",
          },
        ],
        height: [
          {
            required: true,
            message: "输入图像高度",
            trigger: "blur",
          },
        ],
        width: [
          {
            required: true,
            message: "输入图像宽度",
            trigger: "blur",
          },
        ],
        score: [
          {
            required: true,
            message: "置信得分",
            trigger: "blur",
          },
        ],
        iou: [
          {
            required: true,
            message: "交并比",
            trigger: "blur",
          },
        ],
        blend: [
          {
            required: true,
            message: "融合比",
            trigger: "blur",
          },
        ],
        conf: [
          {
            required: true,
            message: "置信度",
            trigger: "blur",
          },
        ],
      },
      fileListAction: baseURLs.imgApi + "/upload/",
      modelOptions: [
        {
          label: "yolov8-seg",
          value: "yolov8-seg",
        },
        {
          label: "yolov9-seg",
          value: "yolov9-seg",
        },
        {
          label: "yolov5-seg",
          value: "yolov5-seg",
        },
      ],
      classesOptions: [
        {
          label: "完整",
          value: "intact",
        },
        {
          label: "轻微受损",
          value: "slight",
        },
        {
          label: "严重受损",
          value: "severe",
        },
        {
          label: "倒塌",
          value: "collapse",
        },
      ],
    };
  },
  computed: {
    uploadUrl() {
      if (this.fileType == "image") {
        return baseURLs.imgApi + "/upload/";
      } else {
        return baseURLs.imgApi + "/regional_analysis/";
      }
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    onOpen() {},
    onClose() {
      // this.$refs['Predict'].resetFields()
    },
    close() {
      // this.$emit('update:visible', false)
    },
    onChange(file, list) {
      {
        let isImage = new RegExp("^image/.*").test(file.raw.type);
        let isZip = new RegExp(
          "application/zip$|application/x-zip-compressed$"
        ).test(file.raw.type);

        let isRightSize =
          (isImage && file.size / 1024 / 1024 < 10) ||
          (isZip && file.size / 1024 / 1024 / 1024 < 1);

        if (!isRightSize) {
          if (isImage) {
            myMessage.elSuccess("上传图片文件大小不能超过10MB");
          } else if (isZip) {
            myMessage.elSuccess("上传压缩包文件大小不能超过1GB");
          } else {
            myMessage.elSuccess("上传文件大小不符合要求");
          }
        }

        let isAccept = isImage || isZip;
        if (!isAccept) {
          myMessage.elError("上传文件格式错误，必须为图片或 ZIP 压缩包");
          list.splice(0, 1);
        } else {
          this.fileType = isImage ? "image" : "zip";
          // console.log(this.fileType);
        }
      }
      if (file.status === "success") {
        myMessage.elSuccess("上传成功！");
      }
      if (list.length > 1 && file.status !== "fail") {
        list.splice(0, 1);
      } else if (file.status === "fail") {
        myMessage.elSuccess("上传失败，请重新上传！");
        list.splice(0, 1);
      }
      console.log(file);
      console.log(file.raw.type);
    },
    beforeUpload(file) {},
    onProgress(evt) {
      console.log(evt);
    },
    onSuccess(response) {
      console.log(this.fileType);
      const that=this
      if (this.fileType == "zip") {
        console.log("zip");
        this.loadingText = "正在识别整个区域";
        that.loading = true;
        myMessage.elSuccess("识别成功!");
        this.loading = false;
        // console.log(response);
        this.predictResultInfo2 = response.data;
        that.$refs.predictResult2.predictResultVisible = true;
      } else if (this.fileType == "image") {
        this.loadingText = "正在识别图像";
        this.loading = true;
        const that = this;
        // console.log(this.formData);
        this.formData["imgsz"] = [
          this.formData["width"],
          this.formData["height"],
        ];
        img_predict(this.predict + response.data, this.formData)
          .then((response) => {
            myMessage.elSuccess("识别成功!");
            that.loading = false;
            // console.log(response);
            that.predictResultInfo = response;
            that.$refs.predictResult.predictResultVisible = true;
          })
          .catch((error) => {
            console.log(error);
            that.loading = false;
          });
      }
    },
    queryResut() {
      this.$refs.upload.submit();
    },
  },
};
</script>
<style>
.el-upload__tip {
  line-height: 1.2;
}
</style>
