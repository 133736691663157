<template>
    <el-dialog title="烈度图快速生成" v-model="showContralParas" width="63%" class="eldialog"
               :modal="false">
       <el-form :model="earthquakePara" :rules="rulesearthquake">
           <el-divider style="margin-top: 0; margin-bottom: 4%"></el-divider>
           <el-row>
               <el-col :span="5" style="z-index: 10">
                   <span class="spanlabel">震级:</span>
                   <el-form-item  class="formitem" prop="mPara">
                       <el-input  class="left-margin"  v-model="earthquakePara.mPara" placeholder="请输入震级" size="large" style="width: 20vh"></el-input>
                   </el-form-item><br>
                   <span class="spanlabel">最大震级:</span>
                   <el-form-item  class="formitem" prop="maxPara">
                       <el-input  class="left-margin"  v-model="earthquakePara.maxPara" placeholder="请输入最大震级" size="large" style="width: 20vh" >
                       </el-input>
                   </el-form-item><br>
                   <span class="spanlabel">地震深度:</span>
                   <el-form-item  class="formitem" prop="depPara">
                       <el-input  class="left-margin"  v-model="earthquakePara.depPara" placeholder="请输入地震深度" size="large" style="width: 26vh" >
                           <slot name="append"></slot>
                           <template v-slot:append>m</template>
                       </el-input>
                   </el-form-item><br>
                   <span class="spanlabel">地震时间:</span>
                   <el-form-item  class="formitem" prop="Time" >
                       <div class="left-margin">
                           <el-date-picker
                               v-model="earthquakePara.Time"
                               style="width: 26vh"
                               align="right"
                               type="date"
                               placeholder="选择日期"
                               :picker-options="pickerOptions">
                           </el-date-picker>
                       </div>
                   </el-form-item><br>
                   <span class="spanlabel">震中经度:</span>
                   <el-form-item  class="formitem" prop="lonPara">
                       <el-input  class="left-margin"  v-model="earthquakePara.lonPara" placeholder="请输入震中经度" size="large" style="width: 26vh" >
                       </el-input>
                   </el-form-item><br>
                   <div style="display: flex; width: 500px">
                       <span class="spanlabel">震中纬度:</span>
                       <el-form-item  class="formitem" prop="latPara">
                           <el-input  class="left-margin"  v-model="earthquakePara.latPara" placeholder="请输入震中纬度" size="large" style="width: 26vh">
                           </el-input>
                       </el-form-item>
                       <el-button size="" style="margin-left: 1%;background-color: blanchedalmond" icon="el-icon-thumb"    @click="choicePoint()" circle></el-button>
                   </div><br>
                   <span class="spanlabel">地震烈度:</span>
                   <div style="display: flex">
                       <el-form-item  class="formitem" prop="intentity" >
                           <el-input class="left-margin" placeholder="点击评估烈度等级" v-model="earthquakePara.intentity"  size="large" style="width: 26vh"></el-input>
                       </el-form-item>
                       <el-button size="" style="margin-left: 2%;background-color: blanchedalmond;margin-bottom: 10%" icon="el-icon-more" @click="this.$refs.intentityestimate.showintensity = true" circle></el-button>
                   </div>
                <div style="position: relative">
                    <span class="spanlabel">
                    <svg t="1723561115524" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4217" width="20" height="20"><path d="M727.008 487.232l194.016-184.32a99.2 99.2 0 0 0 0-140.288l-48.416-48.416a99.2 99.2 0 0 0-138.464-1.76L544.64 292.384l-184.064-196.64-1.504-1.568a64.832 64.832 0 0 0-91.712-0.384L129.184 231.968a64.8 64.8 0 0 0-1.12 90.144l181.344 193.728-171.456 162.88a99.264 99.264 0 0 0-28.256 49.28l-28.992 123.744a65.632 65.632 0 0 0 82.4 77.92l119.296-35.136a99.744 99.744 0 0 0 40.32-23.232l169.056-160.608 203.616 217.536 1.504 1.568a64.832 64.832 0 0 0 91.712 0.384l138.176-138.176a64.8 64.8 0 0 0 1.12-90.144l-200.896-214.624zM319.424 786.176l-90.112-90.112a31.488 31.488 0 0 0-9.792-6.496L667.104 264.352l94.272 94.272c1.408 1.408 3.168 2.08 4.768 3.168L319.424 786.176zM778.208 158.784a35.2 35.2 0 0 1 49.12 0.64l48.416 48.416c13.76 13.76 13.76 36.032-0.64 50.4l-64.448 61.216c-1.28-2.08-2.24-4.288-4.064-6.112l-93.12-93.12 64.736-61.44zM288.512 399.904c8-0.128 16-3.168 22.112-9.28l48-48a31.968 31.968 0 1 0-45.248-45.248l-48 48a31.68 31.68 0 0 0-8.928 20.256L174.816 278.4c-0.512-0.512-0.512-1.024-0.352-1.152L312.64 139.04c0.128-0.128 0.672-0.128 1.248 0.416l184.384 196.992-142.432 135.328-67.328-71.872zM145.024 868.288a1.6 1.6 0 0 1-2.016-1.92l28.992-123.744c0.992-4.16 2.944-7.968 5.312-11.488a31.808 31.808 0 0 0 6.752 10.144l88.288 88.288a35.072 35.072 0 0 1-8 3.552l-119.328 35.168z m598.336 16.672c-0.128 0.128-0.672 0.128-1.248-0.416l-125.6-134.176a31.232 31.232 0 0 0 14.08-7.712l48-48a31.968 31.968 0 1 0-45.248-45.248l-48 48a31.68 31.68 0 0 0-7.296 11.904l-39.904-42.656 142.432-135.328 200.576 214.304c0.48 0.512 0.48 1.024 0.352 1.152l-138.144 138.176z" p-id="4218" fill="#f4ea2a"></path></svg>                    </span>
                    <el-button style="margin-left: 56%;margin-bottom: 3%;display: flex;background-color: #bce689;color: black" @click="adjustpara">烈度图参数调整</el-button>
                    <span class="spanlabel">
                        <svg t="1723561323353" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4595" width="20" height="20"><path d="M418.496 645.344l-159.456-90.752a32 32 0 0 0-31.68 55.616l159.456 90.752a32 32 0 0 0 31.68-55.616z" p-id="4596" fill="#f4ea2a"></path><path d="M790.208 379.808a32 32 0 0 0-31.616-55.648L508.8 466.336l-249.76-142.144a32 32 0 0 0-31.68 55.616l253.344 144.16V832a32 32 0 1 0 64 0v-309.312c0-0.992-0.48-1.856-0.576-2.848l246.08-140.032z" p-id="4597" fill="#f4ea2a"></path><path d="M880.288 232.48L560.192 45.12a95.648 95.648 0 0 0-96.64 0L143.68 232.48A96.64 96.64 0 0 0 96 315.904v397.664c0 34.784 18.624 66.88 48.736 84l320 181.92a95.52 95.52 0 0 0 94.496 0l320-181.92A96.576 96.576 0 0 0 928 713.568V315.904a96.64 96.64 0 0 0-47.712-83.424zM864 713.568c0 11.584-6.208 22.304-16.256 28l-320 181.92a31.776 31.776 0 0 1-31.488 0l-320-181.92A32.192 32.192 0 0 1 160 713.568V315.904c0-11.456 6.048-22.048 15.904-27.808l319.872-187.36a31.84 31.84 0 0 1 32.192 0l320.128 187.392c9.856 5.728 15.904 16.32 15.904 27.776v397.664z" p-id="4598" fill="#f4ea2a"></path><path d="M778.656 453.952l-149.344 88.128c-20.608 12.16-37.344 38.944-37.344 59.808v176.416c0 20.864 16.704 27.904 37.344 15.744l149.344-88.128c20.608-12.16 37.344-38.944 37.344-59.808v-176.416c0-20.864-16.704-27.904-37.344-15.744z" p-id="4599" fill="#f4ea2a"></path></svg>
                    </span>
                    <el-button
                        style="margin-left: 56%;background-color: #bce689;color: black" @click="this.$refs.quickpreviewintentityimg.showimgPara = true">生成快速预览图</el-button>
                </div>
               </el-col>
               <el-col :span="5"  class="intensitygraph">
                   <div style="height: 70%;width: 50%;position: absolute;top: -4%;right: 4%">
                    <el-image :src="intentityimgurl" :key="imageKey" fit="contain"></el-image>
                   </div>
                   <div style="display: flex;position: absolute;bottom: -8%;right: 5%">
                       <el-button circle icon="el-icon-refresh-left"
                                  style="background-color: #bce689"
                                  @click="refreshimg"
                       ></el-button>
                       <el-button circle icon="el-icon-s-flag" @click="contralshoweclipse"
                                  style="background-color: #bce689"
                                  @mouseover="myNotify.elSuccess('烈度图显示在cesium上')"></el-button>
                       <el-button icon="el-icon-zoom-in" circle @mouseover="myNotify.elSuccess('放大\预览')"
                                  @click="previewimg"
                                  style="background-color: #bce689"
                       ></el-button>
                       <el-button icon="el-icon-download" circle @mouseover="myNotify.elSuccess('下载烈度快速预览图')"
                                  style="background-color: #bce689"
                                  @click="triggerDownload(intentityimgurl,'eclipse_imagetemp.png')"
                       ></el-button>
                   </div>
                   <br>
               </el-col>
           </el-row><br><br>
       </el-form>
        <div class="dialog-footer">
            <el-button @click="showContralParas = false">取 消</el-button>
            <el-button type="primary">确 定</el-button>
        </div>
        <intentityEstimate ref="intentityestimate" :mPara="earthquakePara.mPara"
                           @updateList = "updateList"
                           @updatenuminten="updatenuminten"
        ></intentityEstimate>
        <quickpreviewIntentityimg ref="quickpreviewintentityimg"
                                  @submitimgparams="readytoprocessimg"
        ></quickpreviewIntentityimg>
    </el-dialog>
</template>

<script>
import intentityEstimate from "@/components/preview-earthquake-intentityestimate.vue";
import quickpreviewIntentityimg from "@/components/preview-earthquake-quickpreviewintentityimg.vue";
import {myMessage, myNotify} from "@/assets/js/utils";
import {earthquakepara} from "@/assets/js/elform-verify/validate";
import {triggerDownload} from "@/assets/js/reuse-function";
import processImg from "@/components/process-img.vue";
import {intentityImg} from "@/axios/api/others-api";


export default {
    name:'Previewearthquake',
    computed: {
        myNotify() {
            return myNotify
        }
    },
    components: {
        intentityEstimate,
        quickpreviewIntentityimg,
    },
    props:{
        longzz: {
            type: Number,
            required: true
        },
        latizz: {
            type: Number,
            required: true
        },
        modifyrecords:{
            type:Array,
            required:true
        }
    },
    data() {
        return {
            intentityimgurl:"https://www.xuuyy.icu/processimg/eclipse_imagetemp.png",
            showEclipse:true,
            showContralParas: false,
            tempintentityList:[],
            imageKey:0,
            earthquakePara:{
                mPara:"",
                maxPara:"",
                depPara:"",
                Time:"",
                lonPara:"",
                latPara:"",
                intentity:"",
                numIntentity:"",
                adjusteclipsepara:{
                    majoraxis:1,
                    minoraxis:1,
                    moveangle:0.0
                },
                paraListR1:{},
                paraListR2:{},
                paraListR3:{},
                imgParaForm:{}
            },
            rulesearthquake:earthquakepara,
            rateForm:{},
            rlist:[],
            assessForm:{},
            tempintensityList:[],
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        picker.$emit('pick', new Date());
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', date);
                    }
                }, {
                    text: '一周前',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', date);
                    }
                }]
            },
            value:'',
        };
    },
    mounted() {
    },
    watch:{
        longzz(newVal){
            this.earthquakePara.lonPara = newVal
        },
        latizz(newVal){
            this.earthquakePara.latPara = newVal
        },
        modifyrecords(newVal){
            this.earthquakePara.paraListR1 = newVal[0]
            this.earthquakePara.paraListR2 = newVal[1]
            this.earthquakePara.paraListR3 = newVal[2]
            this.earthquakePara.adjusteclipsepara = newVal[3]
        }
    },
    methods:{
        refreshimg(){
            // 改变 imageKey 的值
            this.imageKey += 1;
        },
        readytoprocessimg(imgform,flag){
            this.earthquakePara.imgParaForm = imgform
            console.log(this.earthquakePara)
            intentityImg(this.earthquakePara).then(res=>{
                console.log(res)
            })
        },
        adjustpara(){
            this.$emit("showEclipsefun",true,2) //复用函数接口
            this.showContralParas = false
        },
        previewimg(){
            window.open(this.intentityimgurl, '_blank');
        },
        updatenuminten(newVal){
            this.earthquakePara.numIntentity = newVal
            this.earthquakePara.intentity = this.computeintentity(newVal)
        },
        updateList(newDate1,newDate2,newDate3){
            this.earthquakePara.paraListR1 = newDate1
            this.earthquakePara.paraListR2 = newDate2
            this.earthquakePara.paraListR3 = newDate3
            this.$emit('uploadintentitydata',newDate1,newDate2,newDate3,'')
        },
        //控制展示椭圆在cesium上
        contralshoweclipse(){
            this.$emit("showEclipsefun",this.showEclipse,1)
            this.showEclipse = !this.showEclipse
            this.showContralParas = false
        },
        choicePoint(){
            this.showContralParas = false
            myNotify.elWarning('请从地图上选择地震发生点')
            this.$emit('choiceUpdate',true)
        },
        computeintentity(res){
            if (res>=1 &&res<2){
                return "烈度V"
            }else if(res>=2 &&res<3){
                return "烈度Ⅵ"
            }else if(res>=3 &&res<4){
                return "烈度Ⅶ"
            }else if(res>=4 &&res<5){
                return "烈度Ⅷ"
            }else if(res>=5 &&res<6){
                return "烈度Ⅸ"
            }else if(res>=6 &&res<7){
                return "烈度Ⅹ"
            }else {
                return "烈度Ⅹ"
            }
        },
        triggerDownload,
    },
};
</script>
<style scoped>
.eldialog{
    position: relative;
}
.left-margin{
    margin-left: 120px;
}
.spanlabel{
    position: absolute;
    margin-left: 30px;
    margin-top: 10px;
}
.spanlabel2{
    /*width: 40px;*/
    position: absolute;
    margin-left: 100px;
    margin-top: 10px;
}
.contralspan{
    position: absolute;
    right: -70px;
    margin-top: 10px;
}
.tishi {
    /* Set padding to ensure the height is 32px */
    padding: 6px 12px;
    background: linear-gradient(90deg, rgb(159, 229, 151), rgb(204, 229, 129));
}
.tishi::before {
    background: linear-gradient(45deg, #b2e68d, #bce689);
    right: 0;
}
.formitem{
    margin: 0;
    padding: 0;
}
</style>
