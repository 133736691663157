<template>
  <el-dialog
    v-model="SeismicZonesVisible"
    title="地震带图"
    width="68vw"
    center
    top="2.5vh"
  >
    <div class="el-dialog-div">
      <el-collapse>
        <el-collapse-item
          title="中国的地震活动主要分布"
          name="1"
        >
          <div>
            中国的地震活动主要分布在五个地区的23条地震带上。<br>
            这五个地区是：<br>
            ①台湾省及其附近海域；<br>
            ②西南地区，主要是西藏、四川西部和云南中西部；<br>
            ③西北地区，主要在甘肃河西走廊、青海、宁夏、天山南北麓；<br>
            ④华北地区，主要在太行山两侧、汾渭河谷、阴山-燕山一带、山东中部和渤海湾；<br>
            ⑤东南沿海的广东、福建等地。<br>
            中国的台湾省位于环太平洋地震带上，西藏、新疆、云南、四川、青海等省区位于地中海—喜马拉雅地震带上；<br>
            其他省区处于相关的地震带上。<br>
          </div>
        </el-collapse-item>
      </el-collapse>
      <el-collapse @change="getSeismicZonesData">
        <el-collapse-item title="中甸—大理地震带">
          <p>2000-2023年该地震带地震总数:{{ earthquakeInfoList.length }}</p>
          <p>最高级地震位置:{{ maxMagnitude['earthquakeName'] }}</p>
          <p>经纬度:({{ maxMagnitude['longitude'] }},{{ maxMagnitude['latitude'] }})</p>
          <p>地震时间:{{ maxMagnitude['earthquakeTime'] }}</p>
        </el-collapse-item>
      </el-collapse>
      <el-carousel :height="bannerHeight + 'px'">
        <el-carousel-item
          v-for="item in imgsUrl"
          :key="item"
        >
          <div style="margin-left: 25%">
            <img
              :src="item.url"
              class="bannerimg"
              alt=""
            >
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </el-dialog>
</template>

<script>
import { getSeismicZonesEarthquakeInfo } from "@/axios/api/others-api";
import {selectEarthquakeList, sortByProperty,changeGeoJsonToPosition} from "@/assets/js/seismic-zones"
export default {
  name: "SeismicZones",
  data(){
    return{
      imgsUrl:[
        {url:'https://file.zouran.top/imgs/dizhendai/4.png'},
        {url:'https://file.zouran.top/imgs/dizhendai/23.png'},
      ],
      sendFlag:1,
      bannerHeight: 200,
      earthquakeInfoList:[],
      maxMagnitude:{},
      SeismicZonesVisible:false,
    }
  },
  mounted(){
    this.setSize();
    window.addEventListener('resize', ()=>{
      this.setSize();
    },false);
  },
  methods:{
    getSeismicZonesData(){
      if(this.sendFlag===0) {
        this.sendFlag=1
        return;
      }
      this.sendFlag=0
      const self=this
      const position=changeGeoJsonToPosition()
      getSeismicZonesEarthquakeInfo(position)
          .then(res=> {
            const earthquakeList=selectEarthquakeList(res.data)
            self.earthquakeInfoList=sortByProperty(earthquakeList,'magnitude')
            self.maxMagnitude=self.earthquakeInfoList[self.earthquakeInfoList.length-1]
          })
    },
    setSize(){
      this.bannerHeight = document.body.clientWidth / 4
    }
  }
}
</script>

<style scoped>
.el-dialog-div{
  height: 70vh;
  overflow: auto;
  padding: 10px;
}
.bannerimg{
  width: 70%;
  height: inherit;
}
</style>