<template>
    <span class="spanText" >设施损毁配置：</span>
    <el-button round style="background-color: brown;color: white;" @click="addbasedatafun">添加</el-button>
    <el-button round style="background-color: brown;color: white;" @click="showdestorybase = true">破坏概率</el-button>
    <el-button round style="background-color: skyblue;color: aliceblue" @click="basesubmit">配置完成</el-button>
    <div style="height: 450px;margin-top: 5%">
        <div style="overflow-y: auto; max-height: 450px;">
            <el-descriptions v-for="(item,index) in baseList" :key="index" :column="3" border style="margin-bottom: 10px">
                <el-descriptions-item label="工程名称" label-class-name="my-label" content-class-name="my-content">
                    {{ item.systemName }}</el-descriptions-item>
                <el-descriptions-item label="分项名称">
                    {{item.detailName}}
                </el-descriptions-item>
                <el-descriptions-item label="重建估价">
                    <el-input size="mini" style="width: 80px" v-model="item.price" placeholder="单位w"></el-input>
                </el-descriptions-item>
            </el-descriptions>
        </div>
    </div>
    <el-dialog v-model="showdestorybase" :modal="false">
        <div>
            <span>基础设施破坏概率设置：</span>
            <el-divider></el-divider>
            <el-form label-position="left" label-width="80px" :model="PjParabase" :rules="rulers">
                <el-form-item label="基本完好" prop="level1">
                    <el-input v-model="PjParabase.level1" style="width: 20vh"></el-input>
                </el-form-item>
                <el-form-item label="轻微破坏" prop="level2">
                    <el-input v-model="PjParabase.level2" style="width: 20vh"></el-input>
                </el-form-item>
                <el-form-item label="中等破坏" prop="level3">
                    <el-input v-model="PjParabase.level3" style="width: 20vh"></el-input>
                </el-form-item>
                <el-form-item label="严重破坏" prop="level4">
                    <el-input v-model="PjParabase.level4" style="width: 20vh"></el-input>
                </el-form-item>
                <el-form-item label="倒塌" prop="level5">
                    <el-input v-model="PjParabase.level5" style="width: 20vh"></el-input>
                </el-form-item>
            </el-form>
            <el-button @click="setcreate" round type="primary">设置完成</el-button>
        </div>
    </el-dialog>
    <el-dialog v-model="Addbaseproject" :modal="false" width="80%">
        <div style="height: 300px;overflow-y: auto; ">
            <el-table
                :data="baseListrequest"
                style="width: 100%">
                <el-table-column
                    prop="systemName"
                    label="工程名称"
                    width="100">
                </el-table-column>
                <el-table-column
                    prop="detailName"
                    label="分项名称"
                    width="400">
                </el-table-column>
                <el-table-column
                    label="操作">
                    <template v-slot="scope">
                        <el-button type="primary" icon="el-icon-edit" circle @click="addbase(scope.row)"></el-button>
                        <span style="margin-left: 20px">添加</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </el-dialog>
</template>

<script>
import {baseStructureByall} from "@/axios/api/others-api";
import {rulerlevel} from "@/assets/js/elform-verify/validate";
import {myMessage, myNotify} from "@/assets/js/utils";

export default {
    name: "infrastructure-configure",
    data(){
        return{
            baseList:[
                {systemName:"name1",detailName:"name2",price:1000,},
                {systemName:"name2",detailName:"name2",price:1000,},
                {systemName:"name3",detailName:"name2",price:1000,},
                {systemName:"name4",detailName:"name2",price:1000,},
                {systemName:"name5",detailName:"name2",price:1000,}
            ],
            PjParabase:{
                level1:0.4,
                level2:0.2,
                level3:0.1,
                level4:0.15,
                level5:0.15
            },
            showdestorybase:false,
            Addbaseproject:false,
            baseListrequest:[],
            rulers:rulerlevel
        }
    },
    computed:{
        isprice(){
            for (let i=0;i<this.baseList.length;i++){
                if (this.baseList[i].price === ""){
                    return false
                }
            }
            return true
        }
    },
    methods:{
        setcreate(){
            let sum = 0;
            Object.keys(this.PjParabase).forEach(key=>{
                let value = parseFloat(this.PjParabase[key])
                sum +=value
            })
            if (sum === 1){
                myMessage.elSuccess('设置成功')
                this.showdestorybase = false
            }else{
                console.log(this.PjParabase)
                myMessage.elError('请检查设置的比例，加和期望为1')
            }
        },
        addbasedatafun(){
            let that = this
            if(!this.addbaseflag){
                this.baseList = []
                this.addbaseflag = true
            }
            //请求获得所有的建筑物材质列表
            baseStructureByall().then(res=>{
                that.baseListrequest = res
            })
            this.Addbaseproject = true
        },
        basesubmit(){
            if (!this.isprice){
                myMessage.elWarning('请检查是否设置完成基础设施重建价钱配置')
            }else {
                for (let i = 0;i<this.baseList.length;i++){
                    this.baseList[i].price = this.baseList[i].price*10000
                }
                myMessage.elSuccess('基础设施损毁模块配置完成')

                this.$emit('updatedate',this.baseList,this.PjParabase,'base')
            }
        },
        addbase(row){
            Object.assign(row, { price: '' });
            this.baseList.push(row)
            myNotify.elSuccess('添加成功')
        },
    }
}
</script>

<style scoped>
.spanText{
    font-size: larger;
    font-weight: bold;
}
</style>