<template>
    <vc-entity
            v-for="(item,index) in tempintentityList "
            :id="'intensity_'+index"
            :key="index"
            :position="[longzz, latizz,0]"
            :description="'最外圈烈度:'+item.intensity"
            :show="isShowtempEclipse"
    >
        <!--              :material="[126, (10-item.intensity)*30,180, 125]"-->
        <vc-graphics-ellipse
                :semi-minor-axis="item.longRadius*1000"
                :semi-major-axis="item.shortRadius*1000"
                :material="[255, (10-item.intensity)*30, 0, 125]"
                :rotation="item.angle"
                :fill="true"
        />
    </vc-entity>
    <vc-entity
            ref="entity"
            :position="[longzz, latizz]"
            description="您所点击的位置所表示的区域"
    >
        <vc-graphics-ellipse
                :semi-minor-axis="50.0"
                :semi-major-axis="50.0"
                :material="[255, 0, 0, 125]"
        />
        <vc-graphics-label
                text="震中位置"
                font="20px sans-serif"
                :pixel-offset="[0, 20]"
                fill-color="yellow"
        />
    </vc-entity>
</template>

<script>
import list from "@element-plus/icons/lib/List";

export default {
    name: "cesium-tempintentityimg",
    props:{
        longzz: {
            type: Number,
            required: true
        },
        latizz: {
            type: Number,
            required: true
        },
        paraList1:{
            type:Object,
            required:true
        },
        paraList2:{
            type:Object,
            required:true
        },
        paraList3:{
            type:Object,
            required:true
        },
        isShowtempEclipse:{
            type:Boolean,
            required:true
        }
    },
    data(){
        return{
            tempintentityList:[]
        }
    },
    watch:{
       paraList1(newVal){
           let paralistuse = []
           paralistuse[0] = this.paraList1
           paralistuse[1] = this.paraList2
           paralistuse[2] = this.paraList3
           this.tempintentityList = paralistuse
       }
    }
}
</script>

<style scoped>

</style>