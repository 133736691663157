<template>
    <div w50rem h24rem p3 flex="~ col" justify-center items-center bg-dark>
        <div>
            <dv-scroll-ranking-board :config="config" style="width:400px;height:200px" />
        </div>
    </div>
</template>

<script setup>
import {reactive, ref, watch, watchEffect} from "vue";
import {getEarthquakeInfoByCondition} from "@/axios/api/others-api";
import {dateFtt} from "@/assets/js/utils";

const props = defineProps({
    province: {
        type: String,
        default: ''
    },
});

const datalist = ref([]);
const config = reactive({
    data: datalist.value,
    unit: '级',
});

let params = {
    name: '',
    min: 0,
    max: 10,
    limit: 50,
    page: 1,
    start: dateFtt("yyyy-MM-dd hh:mm:ss", new Date(2000, 10, 10, 10, 10)),
    end: dateFtt("yyyy-MM-dd hh:mm:ss", new Date())
};

// 监听 province 的变化
watch(() => props.province, (newProvince) => {
    if (newProvince === "全国") {
        params.name = '';
    } else {
        params.name = newProvince;
    }

    // 重新获取地震信息
    getEarthquakeInfoByCondition(params).then(res => {
        const tempList = res.map(item => ({
            name: item.earthquakeName,
            value: item.magnitude,
        }));
        datalist.value = tempList;
    });
}, { immediate: true }); // immediate 选项确保初始时也会执行一次

// 确保 config.data 在 datalist 变化时同步更新
watchEffect(() => {
    config.data = datalist.value;
});

</script>
