
//封装在vue的methods中不同组件复用同一函数问题


import {myMessage, myNotify} from "@/assets/js/utils";

function triggerDownload(url, filename) {
    let image = new Image();
    image.setAttribute("crossOrigin", "anonymous");
    image.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        let dataURL = canvas.toDataURL("image/png");

        let a = document.createElement("a");
        let event = new MouseEvent("click");
        a.download = filename || "img";
        a.href = dataURL;
        a.dispatchEvent(event);

        myNotify.elSuccess('图片拉取成功!');
    }
    image.onerror = () => {
        myNotify.elError('下载图片失败。');
    }
    image.src = url;
};

function changeselected(){
    this.$confirm('请选择修改方式：从列表选择或添加地震?', '提示', {
        confirmButtonText: '添加地震',
        cancelButtonText: '地震列表',
        type: 'warning',
        center: true
    }).then(() => {
        myMessage.elWarning('请重新选择你需要的评估的地震信息~')
        this.$emit("openothermodule",'previewearthquake')
    }).catch(() => {
        myMessage.elWarning('请添加地震信息并完成烈度评估~')
        this.$emit("openothermodule",'select')
    });
};

export {
    triggerDownload,
    changeselected
}