<template>
  <el-dialog
    v-model="predictVisible"
    title="受灾识别"
    width="50%"
  >
    <el-upload
      ref="upload"
      v-loading="loading"
      drag
      :action="upload"
      multiple
      method="post"
      :element-loading-text="loadingText"
      :file-list="fileList"
      :on-change="onChange"
      :on-progress="onProgress"
      :on-success="onSuccess"
      :before-upload="beforeUpload"
    >
      <el-icon class="el-icon--upload">
        <upload-filled />
      </el-icon>
      <div class="el-upload__text">
        Drop file here or <em>click to upload</em>
      </div>
      <template #tip>
        <div style="text-align: center">
          <p>jpg/png files with a size less than {{ maxSize }} MB</p>
          <p
            class="text-blue"
            @click="$router.push('/flightPath')"
          >
            连续图像路径加载
          </p>
        </div>
      </template>
    </el-upload>
  </el-dialog>
</template>

<script>
import {UploadFilled} from "@element-plus/icons";
import {myMessage} from "@/assets/js/utils";
import { img_predict } from "@/axios/api/others-api"
import {baseURLs} from "@/axios/http-config";
export default {
  name: "Predict",
  components: {UploadFilled},
  data() {
    return{
      upload:baseURLs.imgApi+'/upload/',
      predict_preview:baseURLs.imgApi+'/predict_preview/',
      predict:baseURLs.imgApi+'/predict/',
      predictVisible:false,
      fileList:[],
      maxSize:10,
      loading:false,
      loadingText:''
    }
  },
  methods:{
    onChange(file, list) {
      if(file.status==="success"){
        myMessage.elSuccess('上传成功！')
      }
      if (list.length > 1 && file.status !== "fail") {
        list.splice(0, 1);
      } else if (file.status === "fail") {
        myMessage.elSuccess('上传失败，请重新上传！')
        list.splice(0, 1);
      }
    },
    beforeUpload(file){
      let sizeIsValid=false,formatIsValid=false
      if(file.size/1024/1024<10)
        sizeIsValid=true
      else myMessage.elSuccess('上传文件大小不能超过10MB')
      const suffix=file.name.split('.')[1].toLowerCase()
      console.log(suffix)
      if(suffix==='jpg'||suffix==='png'||suffix==='jpeg'){
        formatIsValid=true
      }
      else {
        myMessage.elSuccess('上传文件格式错误')
      }
      return sizeIsValid&&formatIsValid
    },
    onProgress(evt){
      console.log(evt)
    },
    onSuccess(response){
      this.loadingText='正在识别图像'
      this.loading=true
      const self=this
      img_predict(this.predict+response.data).then(response=>{
        myMessage.elSuccess('识别成功,即将跳转至结果页面....')
        self.loading=false
        setTimeout(function() {window.open(self.predict_preview+response.data.name)},1000)
      })
    }
  }
}
</script>

<style scoped>
/deep/ .el-upload{
  width: 100%;
}
/deep/ .el-upload .el-upload-dragger{
    display: flex;
    justify-content: center;
    align-items: center;
  width: 100%;
}
</style>