<template>
    <el-dialog
            title="救援物资/力量显示"
            v-model="dialogVisible"
            custom-class="custom-dialog"
            :modal="false"
            width="30%">
        <el-select v-model="cityvalue" clearable size="small">
            <el-option
                v-for="item in cityoption"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
        </el-select>
        <el-button circle icon="el-icon-view" style="background-color: papayawhip;margin-left: 50%" @click="showForce"></el-button>
        <el-divider></el-divider>
        <el-table
            :data="tableData"
            height="320"
            border
            style="width: 100%">
            <el-table-column
                prop="name"
                label="名称"
                width="220">
            </el-table-column>
            <el-table-column
                prop="adress"
                label="地址"
                width="195">
            </el-table-column>
            <el-table-column
                label="操作">
                <template v-slot="scope">
                <el-button @click="showPointforce(scope.row)" type="text" size="small">位置</el-button>
                <el-button @click="showPointGoods(scope.row)" type="text" size="small">点物资</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            style="margin-left: 2%;margin-top: 2%"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[4, 5, 6, 7]"
            :page-size="pageSize"
            layout="sizes, prev, pager, next"
            :total="100">
        >
        </el-pagination>
    </el-dialog>
</template>

<script>
import {getPointGoodsByid, getSauverForceBylimit} from "@/axios/api/others-api";
import {pcTextArr} from "element-china-area-data";
import {myNotify} from "@/assets/js/utils";

export default {
    name: "save-showmodule",
    components:{

    },
    data(){
        return{
            dialogVisible:false,
            tableData: [],
            cityvalue:"",
            cityoption:pcTextArr,
            currentPage:1,
            pageSize: 5,
            showindex:false,
            pointgoodsdetail:"",
            showgoods:false,
        }
    },
    computed:{
      formatStringGoods(){
          let list = this.pointgoodsdetail
          return "大米/面："+list.item1+" 吨 "
              +"食用油："+list.item2+" 桶 "
              +"衣服："+list.item3+" 套 "
              +"被子："+list.item4+" 床 "
              +"帐篷："+list.item5+" 顶 "
              +"彩布条"+list.item6+" 件 "
              +"折叠床"+list.item7+" 张 "
              +"床上用品"+list.item8+" 套 "
      }
    },
    mounted() {
        this.getsauverforce()
    },
    watch:{
        cityvalue(newVal){
            this.getsauverforce()
        }
    },
    methods:{
        handleSizeChange(newSize){
            this.pageSize = newSize
            this.getsauverforce()
        },
        handleCurrentChange(newPage){
            this.currentPage = newPage
            this.getsauverforce()
        },
        showForce(){
            this.showindex =!this.showindex
            this.$emit("showForce",this.tableData,this.showindex)
        },
        showPointforce(row){
            let templist = [row]
            this.$emit("showForce",templist,true)
        },
        showPointGoods(row){
            let that = this
            that.showgoods = true
            getPointGoodsByid(row.id).then(res=>{
                that.pointgoodsdetail = res
                let resultString = "单位名称："+row.name+"  位置："+row.lontitude+","+row.latitude+this.formatStringGoods
                myNotify.elSuccess(resultString)
                that.$emit("opendetailbox",res)
            })
        },
        getsauverforce(){
            let that = this
            let params={
                cityvalue:this.cityvalue,
                page:this.currentPage.toString(),
                limit:this.pageSize.toString()
            }

            getSauverForceBylimit(params).then(res=>{
                that.tableData = res
            })

        }
    }
}
</script>

<style>
.custom-dialog {
    position: absolute;
    top: 10%; /* 离顶部的距离 */
    right: 2%; /* 离右边的距离 */
    margin-right: 2%; /* 重置默认的 margin */
    width: 34%;
}
</style>