<template>
    <div style="display: flex">
    <cesium  ref="cesium" @earthquekeselectInfo="getselectearthqueakeInfo"
                          @openModule="this.showDementity = true"
    ></cesium>
        <div v-if="showDementity">
    <div class="header">
        <div style="position: absolute;width: 100px;left: 0;top: 0;z-index: 10;">
            <dv-button :bg="false" fontColor="#ffffff" @click="showleftBody=!showleftBody">左侧</dv-button>
        </div>
        <div style="position: absolute;width: 100px;right: 0;top: 0;z-index: 10;color: white">
            <dv-button :bg="false" fontColor="#ffffff" @click="showrightBody = !showrightBody">右侧</dv-button>
        </div>
        <div small-bg style="position: absolute;left: 15%">
            <dv-decoration3 style="width:250px;height:30px;"/>
        </div>
        <div small-bg style="position: absolute;left: 6%">
            <dv-decoration3 style="width:250px;height:30px;"/>
        </div>
        <div small-bg style="position: absolute;right: 6%">
            <dv-decoration3 style="width:250px;height:30px;"/>
        </div>
        <div small-bg style="position: absolute;right: 15%">
            <dv-decoration3 style="width:250px;height:30px;"/>
        </div>
    <div class="bg_header">
    <div class="header_nav fl t_title">
        <dv-decoration-11 style="width:400px;height:80px;" :color="['#1122ff', '#1122ff']">
            <div  font-600 bg="~ dark/0" >
                中国地震应急搜救中心
            </div>
        </dv-decoration-11>
        <el-button circle icon="el-icon-arrow-down" style="background-color: palegoldenrod; margin-left: 2%"
                   @click="backMain"
        ></el-button>
    </div>
    </div>
    </div>
    <div class="left-body" v-if="showleftBody">
        <dv-border-box1 class="left-top public-bg" >
                <div class="public-title">
                    <el-select v-model="selectyear" size="mini" style="width: 80px" >
                        <el-option
                            v-for="item in yearoption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    年地震
                </div>
                <div class="top-body">
                    <div class="top-left">
                        <div class="top-left-title">
                            <h6>地震次数</h6>
                            <span><b>{{statisticForm.numearthquake}}</b>次</span>
                        </div>
                    </div>
                    <div class="top-right">
                        <div class="top-left-titleright">
                            <h6>最高震级</h6>
                            <span>{{statisticForm.maxmani}}</span>
                            <h6>最低震级</h6>
                            <span>{{statisticForm.minmani}}</span>
                            <h6>全年平均震级</h6>
                            <span>{{statisticForm.avgmani}}</span>
                        </div>
                    </div>
                </div>
        </dv-border-box1>
        <dv-border-box8 :reverse="true" class="left-con public-bg">
                <div class="public-title"><span>震区情况:</span><el-button round size="small"
                                                                           style="margin-left: 60%;background-color: papayawhip;color: black"
                                                                           @click="showmodule = !showmodule"
                >切换</el-button>
                    <div>
                        <div v-if="showmodule">
                            <innerPersontwo :affectpersonnum="affectpersonnum"></innerPersontwo>
                        </div>
<!--                        <innerPerson v-if="showmodule" :affectpersonnum="affectpersonnum"></innerPerson>-->
                        <div v-else style="width: 260px;height: 260px">
                            <el-image :src="intentityimgurl" fit="contain"></el-image>
                            <el-button circle icon="el-icon-zoom-in"
                                       style="position: absolute;background-color: papayawhip;right: 0;bottom: -250px"
                                       @click="previewimgfun"
                            ></el-button>
                        </div>
                    </div>
                </div>
                <div class="title-nav" id="leida"></div>
        </dv-border-box8>
        <dv-border-box8 :dur="5" class="left-bottom public-bg">
                <div class="public-title"><span>震发经济损失:</span>
                    <div style="width: 300px;height: 200px;position: absolute;bottom: -180px">
                        <economicLossnew :economiclossnum="economicLossnum" ref="economicloss" ></economicLossnew>
                    </div>
                </div>
                <div class="title-nav" id="wuran"></div>
        </dv-border-box8>

    </div>
    <div class="right-body" v-if="showrightBody">
        <dv-border-box3 class="right-top public-bg">
                <div class="public-title">
                    <span>近期地震情况</span>
                    <el-select v-model="selectprovince" style="width:120px;margin-left: 10%" size="small">
                        <el-option
                            v-for="item in provinceoption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="title-nav">
                    <rankEarthquake :province="selectprovince"></rankEarthquake>
                </div>
        </dv-border-box3>
        <dv-border-box4 :reverse="true" class="right-con public-bg">
                <div class="public-title">
                    <span>当前所选地震详细信息</span>
                    <el-button style="margin-left: 5%" size="small" circle type="primary" @click="this.$refs.cesium.cameraTo(selectearthquakeInfo.longitude,selectearthquakeInfo.latitude,100000);" >前往</el-button>
                    <div>
                        <span>地震编号：</span><span>{{selectearthquakeInfo.earthquakeId }}</span>
                        <br><span>地震名称：</span><span>{{selectearthquakeInfo.earthquakeName}}</span>
                        <span style="margin-left: 2%">时间：</span> <span>{{selectearthquakeInfo.earthquakeTime}}</span>
                        <br><span>震级：</span><span style="color: #EB6841">{{selectearthquakeInfo.magnitude}}</span>
                        <span style="margin-left: 10%">烈度：</span><span style="color: red">{{selectearthquakeInfo.highIntensity}}</span>
                        <br><span>震中经度：</span><span style="color: gold">{{selectearthquakeInfo.longitude}}</span><span  style="margin-left: 10%">震中纬度：</span><span  style="color: gold">{{selectearthquakeInfo.latitude}}</span>
                        <br><span>震发深度：</span><span>{{selectearthquakeInfo.depth}}m</span>
                    </div>
                </div>
                <div class="title-nav" id="huaxing"></div>
        </dv-border-box4>
        <dv-border-box8 :reverse="true" :dur="2" class="right-bottom public-bg">
            <div class="public-title">
                <span>震区物资需求量：</span>
                <div>
                    <needGoodstwo :datasource="datasource"></needGoodstwo>
                </div>
<!--                <div style="position: absolute;right: 15%;bottom: -150px;width: 300px;height: 200px">-->
<!--                    <neededGoods :datasource="datasource"></neededGoods>-->
<!--                </div>-->
            </div>
            <div class="title-nav" id="zhexian"></div>

        </dv-border-box8>
        <div small-bg style="position: absolute;bottom: -8%;right: 94%">
            <dv-decoration5 :dur="2" style="width:900px;height:40px;" />
        </div>
    </div>
        </div>
    </div>
</template>

<script>

import { pcaTextArr, } from 'element-china-area-data'
import {
    getEarthquakeInfoByyear,
    getEarthquakeInfoByCondition,
    computedR,
    getPoputationScope
} from "@/axios/api/others-api";
import cesium from "@/views/cesium.vue";
import {dateFtt} from "@/assets/js/utils";
import neededGoods from "@/components/echartsgraph/needed-goods.vue";
import economicLossnew from "@/components/echartsgraph/economic-lossnew.vue";
import innerPerson from "@/components/echartsgraph/inner-person.vue";
import needGoodstwo from "@/components/echartsgraph/need-goodstwo.vue";
import innerPersontwo from "@/components/echartsgraph/inner-persontwo.vue";
import rankEarthquake from "@/components/echartsgraph/rank-earthquake.vue"
export default {
    name: "mainView",
    components:{
        cesium,
        // neededGoods,
        needGoodstwo,
        economicLossnew,
        // innerPerson,
        innerPersontwo,
        rankEarthquake
    },
    data(){
        return{
            intentityimgurl:"http://www.xuuyy.icu:7789/processimg/eclipse_imagetemp.png",
            showmodule:true,
            showDementity:false,
            options:pcaTextArr,
            timer:null,
            showleftBody:true,
            showrightBody:true,
            selectedOptions: [],
            earthquakeList:[],
            selectearthquakeInfo:{},
            yearoption: [{value: 2024, label: '2024'}, {value: 2023, label: '2023'}, {value: 2022, label: '2022'}, {
                value: 2021, label: '2021'}, {value: 2020, label: '2020'}, {value: 2019, label: '2019'}, {value: 2018, label: '2018'},
                {value: 2017, label: '2017'}, {value: 2016, label: '2016'},{value: 2015, label: '2015'},{value: 2014, label: '2014'},
                {value: 2013, label: '2013'},{value: 2012, label: '2012'},{value: 2011, label: '2011'},{value: 2010, label: '2010'},
            ],
            selectyear:2023,
            statisticForm:{
                numearthquake:null,
                maxmani:null,
                minmani:null,
                avgmani:null
            },
            selectprovince:"全国",
            provinceoption: [{value: '全国', label: '全国'}, { value: '北京', label: '北京' }, { value: '天津', label: '天津' }, { value: '河北', label: '河北' }, { value: '山西', label: '山西' }, { value: '内蒙古', label: '内蒙古' }, { value: '辽宁', label: '辽宁' }, { value: '吉林', label: '吉林' }, { value: '黑龙江', label: '黑龙江' },
                { value: '上海', label: '上海' }, { value: '江苏', label: '江苏' }, { value: '浙江', label: '浙江' }, { value: '安徽', label: '安徽' }, { value: '福建', label: '福建' }, { value: '江西', label: '江西' }, { value: '山东', label: '山东' },
                { value: '河南', label: '河南' }, { value: '湖北', label: '湖北' }, { value: '湖南', label: '湖南' }, { value: '广东', label: '广东' }, { value: '广西', label: '广西' },
                { value: '海南', label: '海南' }, { value: '重庆', label: '重庆' }, { value: '四川', label: '四川' }, { value: '贵州', label: '贵州' }, { value: '云南', label: '云南' }, { value: '西藏', label: '西藏' },
                { value: '陕西', label: '陕西' }, { value: '甘肃', label: '甘肃' }, { value: '青海', label: '青海' }, { value: '宁夏', label: '宁夏' }, { value: '新疆', label: '新疆' }, { value: '台湾', label: '台湾' },
                { value: '香港', label: '香港' }, { value: '澳门', label: '澳门' }],
            affectpersonnum:0,
            ParaFormL:[7.1410, 1.6580, -2.3350, 57.48],
            ParaFormR:[4.0270, 1.2180, -1.3180, 8.88],
            tempintensityList:[],
            scopelongRadius:0,
            scopeshortRadius:0,
            datasource:[]
        }
    },
    computed:{
        economicLossnum(){
          return Math.pow(10,0.84444*this.selectearthquakeInfo.highIntensity-1.831)
        }
    },
    mounted() {
        this.getEarthquakeInfo()
        this.getearthquakeListbyprovince()
        this.refreshListInfo()
    },
    watch:{
        affectpersonnum(newVal){
            console.log(newVal)
        },
        selectyear(newVal){
            this.getEarthquakeInfo()
        },
        selectprovince(newVal){
            this.getearthquakeListbyprovince()
        },
        scopelongRadius(newVal){
            // console.log(this.scopelongRadius)
            // console.log(this.scopeshortRadius)
            let that = this
            let params = {
                lontitude:this.selectearthquakeInfo.longitude,
                latitude:this.selectearthquakeInfo.latitude,
                longRadius:this.scopelongRadius,
                shortRadius:this.scopeshortRadius
            }
            // let params =  {
            //     "lontitude":116.23,
            //     "latitude":39.54,
            //     "longRadius":67.17692145364093,
            //     "shortRadius":40.692466450766986
            // }
            getPoputationScope(params).then(res=>{
                that.affectpersonnum = res
                console.log("people",res)
                let ps = res
                that.datasource = [ps*2,ps,ps*0.2,ps*2,ps*0.5,ps*1.3,ps*1.2,ps*1.1,ps*0.25,ps*0.02]
                console.log(that.datasource)
            })
        }

    },
    methods:{
        backMain(){
            this.$refs.cesium.showside=true
            this.showDementity = false;
        },
        previewimgfun(){
            window.open(this.intentityimgurl, '_blank');
        },
            handleChange (value) {
                let tempstring = ""
                for (let i = 0;i<value.length;i++){
                    tempstring +=value[i]
                }
            },
            getEarthquakeInfo(){
                let that = this
                getEarthquakeInfoByyear(that.selectyear).then(res=>{
                    let i = 0;
                    Object.keys(that.statisticForm).forEach(key=>{
                        that.statisticForm[key] = res[i++]
                    })
                    })
            },
            getearthquakeListbyprovince(){
                let that = this
                let params = {}
                if (this.selectprovince === "全国"){
                    params['name'] = ''
                }else {
                    params['name'] = this.selectprovince
                }
                params['min'] = 0;params['max']=10;params['limit']=50; params['page'] = 1;
                params['start'] = dateFtt("yyyy-MM-dd hh:mm:ss",new Date(2000, 10, 10, 10, 10))
                params['end'] =  dateFtt("yyyy-MM-dd hh:mm:ss",new Date());

                getEarthquakeInfoByCondition(params).then(res=>{
                    that.earthquakeList = res
                    clearInterval(this.timer)
                    this.refreshListInfo()
                })
            },
        refreshListInfo(){
            this.timer = setInterval(() => {
                if (this.earthquakeList.length > 2) {
                    // 将数组前两条数据移动到数组尾部
                    let firstTwo = this.earthquakeList.splice(0, 2);
                    this.earthquakeList.push(...firstTwo);
                }
            }, 4000);
        },
        getselectearthqueakeInfo(newVal){
            this.selectearthquakeInfo = newVal
            console.log(newVal)
            this.getIntentityList(newVal.magnitude);

        },
        getIntentityList(Mpara){
            const that = this
            let Ia = this.ParaFormL[0] + this.ParaFormL[1]*parseFloat(Mpara)+this.ParaFormL[2]*Math.log(0+this.ParaFormL[3]);
            let Ib = this.ParaFormR[0] + this.ParaFormR[1]*parseFloat(Mpara)+this.ParaFormR[2]*Math.log(0+this.ParaFormR[3]);
            let paraList = {
                ia:Ia,
                ib:Ib,
                mpara:Mpara,
                paraForml:this.ParaFormL,
                paraFormr:this.ParaFormR,
            }
            computedR(paraList).then(res=>{
                that.rlist = res
                if (res[4] !==0&& res[5]!==0){
                    that.scopelongRadius = res[4]
                    that.scopeshortRadius = res[5]
                }else {
                    if (res[2] !==0&& res[3]!==0){
                        that.scopelongRadius = res[2]
                        that.scopeshortRadius = res[3]
                    }else {
                        that.scopelongRadius = res[0]
                        that.scopeshortRadius = res[1]
                    }
                }
            })
        },


    }
}
</script>

<style scoped>

/*header开始*/
*{
    margin: 0;
    padding: 0;
}
body{
    width: 100%;
    height: 100%;
    overflow: auto;
}

.header {
    position: absolute;
    width: 100%;
    height: 80px;
    z-index: 10;
    padding: 0 20px;

}

.bg_header {
    width: 100%;
    height: 80px;
    align-content: center;
    justify-content: center;
    background: url('../assets/img/title.png') no-repeat;
    background-size: 100% 100%;
}

.header .header_nav {
    color:#F5F5F5;
    display: flex;              /* 使用 flexbox 布局 */
    align-items: center;        /* 垂直居中 */
    justify-content: center;
    font-size: 2em;
    font-weight: bolder;

}
.public-bg{
    background: rgba(50, 50, 50, 0.7);
}


.public-title{
    width: calc(100% - 20px);
    height: 30px;
    position: relative;
    top: 0;
    left: 5px;
    color: white;
    padding-left: 16px;
    line-height: 30px;
    font-size: 13px;
    font-weight: bolder;
}
.public-title:before{
    width: 4px;
    height: 20px;
    top: 5px;
    position: absolute;
    content: "";
    background: #2997e4;
    border-radius: 2px;
    left: 5px;
}
.left-body{
    position: absolute;
    width: 400px;
    top: 4%;
    height: 94%;
    z-index: 10;
}

.left-body .left-top{
    width: 100%;
    height: 27%;
}
.left-body .left-top .top-body{
    width: 100%;
    height: calc(100% - 30px);
}
.left-body .left-top .top-body .top-left{
    float: left;
    width: 50%;
    height: 100%;
}
.top-left-title{
    width: 100%;
    height: 100%;
    padding: 30px;
    box-sizing: border-box;
}

.top-left-titleright{
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;
}

.top-left-titleright h6{
    color: #bad0e2;
}
.top-left-title h6{
    color: #bad0e2;
    font-weight: bolder;
    font-size: 1.3em;
}
.top-left-title span{
    margin-top: 10px;
    display: inline-block;
    color: #2aa4f1;
}
.top-left-titleright span{
    margin-top: 10px;
    display: inline-block;
    color: #2aa4f1;
}
.top-left-title span b{
    font-size: 30px;
    letter-spacing:5px;
}
.top-left-title img{
    width: 80%;
}
.left-body .left-top .top-body .top-right{
    float: left;
    width: 50%;
    height: 100%;
}
.left-body .left-con{
    width: 100%;
    height: 42%;
    margin-top: 1.6%;
}
.title-nav{
    width: 100%;
    height: calc(100% - 30px);
    margin-left: 6%;
}
.left-body .left-bottom{
    width: 100%;
    height: 38%;
    margin-top: 1.6%;
}

.right-body{
    position: absolute;
    width: 450px;
    top: 4%;
    height: 90%;
    margin:0 0.3%;
    right: 0;
    z-index: 10;
}

.right-body .right-top{
    width: 100%;
    height: 32%;
}
.title-nav .top5-ul{
    width: calc(100% - 20px);
    height: calc(100% - 30px);
    padding: 10px;
}
.title-nav .top5-ul ul{
    width: 100%;
    height: 100%;
}
.title-nav .top5-ul ul,li{
    list-style: none;
}
.title-nav .top5-ul ul>li{
    width: 100%;
    height: 20%;
    color: #ffffff;
    line-height: 68px;
    justify-content: center;
}
.title-nav .top5-ul ul li span{
    margin-left: 3%;
    font-size: 14px;
}
.title-nav .top5-ul ul li span:nth-child(1){
    color:  #EB6841;
    font-style: oblique;
    /*width: 10%;*/
    /*#20a8fe
            #EB6841
            #3FB8AF
            #FE4365
            #FC9D9A*/
    display: inline-block;
    text-align: center;
    font-size: 20px;
}

.title-nav .top5-ul ul li span:nth-child(2){
    width: 10%;
    display: inline-block;
    text-align: center;

    height: 30px;
    line-height: 30px;
    /*    height: 100%;*/
    /*line-height: 100%;*/
    vertical-align: center;
    border-radius: 5px;
    color: #ffffff;
}
.title-nav .top5-ul ul li:nth-child(1) span:nth-child(2),.title-nav .top5-ul ul li:nth-child(2) span:nth-child(2)
,.title-nav .top5-ul ul li:nth-child(3) span:nth-child(2){
    background: #d89346;
}
.title-nav .top5-ul ul li:nth-child(4) span:nth-child(2)
,.title-nav .top5-ul ul li:nth-child(5) span:nth-child(2){
    background: #1db5ea;
}
.title-nav .top5-ul ul li span:nth-child(3){
    /*width: 15%;*/
    display: inline-block;
    text-align: center;
}
.title-nav .top5-ul ul li span:nth-child(4){
    /*width: 15%;*/
    display: inline-block;
    text-align: center;
}
.title-nav .top5-ul ul li span:nth-child(5){
    /*width: 20%;*/
    display: inline-block;
    text-align: center;
}
.title-nav .top5-ul ul li span:nth-child(6){
    display: inline-block;
    text-align: center;
}
.right-body .right-con{
    width: 100%;
    height: 25%;
    margin-top: 2%;
}
.right-body .right-bottom{
    width: 100%;
    height: 55%;
    margin-top: 2%;
}
</style>