
<template>
  <vc-datasource-geojson
    :data="data"
    :show="show"
    :fill="options.fill"
    :stroke="options.stroke"
  />
</template>
<script>
export default {
  name: "CesiumGeojson",
  props:{
    data:{
      type:String,
      default: 'https://file.zouran.top/json/china.json'
    },
    show:{
      type:Boolean,
      default: false
    },
    options: {
      type:Object,
      default:()=>({
        fill:[0,0,0,0.1],
        stroke: 'yellow'
      })
    },
  },
}
</script>
<style scoped>

</style>