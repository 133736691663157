import {http} from "../http-config";
import {gcj2wgs, wgs2gcj} from "@/assets/js/cesium";

//获取全部地震位置信息
function getAllEarthquakePositionInfo() {
    return http.get('/earthquakeInfo/getAllEarthquakePositionInfo','').then(response=>{
      return response.data})
}
//初始化地图数据
function initEarthquakePositionInfo(params) {
  return http.post('/earthquakeInfo/getEarthquakePositionInfoByCondition',{'max':10,'min':params}).then(response=>{
    return response.data})
}
//地震id获取地震详细信息
function getEarthquakeInfoById(params) {
  return http.get('/earthquakeInfo/getEarthquakeInfoById',{'earthquakeId':params}).then(response=>{
    return response.data
  })
}
//地震id获取地震详细信息(详细框用)
function getDetailEarthquakeInfoById(params) {
    return http.get('/earthquakeInfo/getEarthquakeInfoById',{'earthquakeId':params}).then(response=>{
        const earthquakeInfo=response.data
        return [
            {
                key: '地震名称',
                value: earthquakeInfo.earthquakeName,
            },
            {
                key: '震级',
                value: earthquakeInfo.magnitude,
            },
            {
                key: '震源经纬度',
                value: earthquakeInfo.longitude + ',' + earthquakeInfo.latitude,
            },
            {
                key: '震中烈度',
                value: earthquakeInfo.highIntensity,
            },
            {
                key: '发生时间',
                value: earthquakeInfo.earthquakeTime,
            },]
    })
}
//地震Id获取附近医院位置
function getAllNearHospitalPositionByEarthquakeId(params) {
    return http.get('/getAllNearHospitalPositionByEarthquakeId',params)
}
//医院id查医院详细信息
function getHospitalByHospitalId(params) {
    return http.get('/getHospitalByHospitalId',params).then(response=>{
        let hospital=response.data
        return [
             {
                 key: '医院名称',
                 value: hospital.name,
             },
             {
                 key: '地址',
                 value: hospital.address,
             },
             {
                 key: '经纬度',
                 value: hospital.lon + ',' + hospital.lat,
             },
             {
                 key: '所在省市',
                 value: hospital.pname + hospital.cityname,
             },
             {
                 key: '类型',
                 value: hospital.type,
             }]
        })
}
//地震Id获取附近医院信息
function getAllNearHospitalByEarthquakeId(params) {
    return http.get('/getAllNearHospitalByEarthquakeId',params)
}
//地震Id获取附近消防站信息
function getAllNearFireCenterByEarthquakeId(params) {
    return http.get('/getAllNearFireCenterByEarthquakeId',params)
}
//地震Id获取附近消防站位置信息
function getAllNearFireCenterPositionByEarthquakeId(params) {
    return http.get('/getAllNearFireCenterPositionByEarthquakeId',params)
}
//物资分配
function getNearFireCenterWeightByEarthquakeId(id,count) {
    return http.get('/getNearFireCenterWeightByEarthquakeId',{'earthquakeId':id,'fireCenterCount':count}).then(response=>{
      return Promise.resolve(response)
    })
}
//路径规划
function getNearestHospitalByPosition(lon,lat) {
    return http.get('/getNearestHospitalByPosition', {'longitude': lon, 'latitude': lat}).then(response => {
        const location={
            longitude:response.data.endLon,
            latitude:response.data.endLat
        }
        return Promise.resolve(location)
    })
}
//高德地图路径规划,params为交通方式，起始位置
function searchRouteApi(start_wgs, end_wgs, way) {
    let start_gcj = wgs2gcj(start_wgs);
    let end_gcj = wgs2gcj(end_wgs);
    let roadInfo={
        clampToGround: true,
        material: 'red',
        width: 5,
    }
    const modelInfo={
        scale:10,
        uri:'https://file.zouran.top/assets/cesium/glb/motuo.glb'
    }
    let travelWay;
    if(way==='driving'){
        roadInfo.material=Cesium.Color.RED.withAlpha(0.9)
        modelInfo.scale=5
        modelInfo.uri="https://file.zouran.top/assets/cesium/glb/xiaofang.glb"
        travelWay="/v3/direction/driving";
    }
    if(way==='walking'){
        modelInfo.scale=250
        roadInfo.material=Cesium.Color.BLUE.withAlpha(1)
        modelInfo.uri="https://file.zouran.top/assets/cesium/glb/Astronaut.glb"
        travelWay="/v3/direction/walking"
    }
    if(way==='bicycling'){
        modelInfo.scale=50
        roadInfo.material=Cesium.Color.YELLOW.withAlpha(1)
        modelInfo.uri="https://file.zouran.top/assets/cesium/glb/motuo.glb"
        travelWay="/v4/direction/bicycling"
    }
     return http.axios({
        method:'get',
        url:"https://restapi.amap.com"+travelWay,
        params: {
          output: "json",
          extensions: "all",
          key: "4efbe48d87594cedcc6299602b8af1f0", // https://lbs.amap.com/api/webservice/guide/api/direction
          origin: start_gcj[0] + "," + start_gcj[1],
          destination: end_gcj[0] + "," + end_gcj[1],
          strategy: 11 || 10,
        },
      }).then(response => {
         let steps;
         if(way==='bicycling')
             steps = response.data.data.paths[0].steps;
         else if(way==='walking' ||way==='driving')
             steps = response.data.route.paths[0].steps;
         let arr = [];
         for (let i = 0; i < steps.length; i++) {
             let item = steps[i];
             let positionStr = item.polyline;
             let strArr = positionStr.split(";");
             for (let z = 0; z < strArr.length; z++) {
                 let item2 = strArr[z];
                 let strArr2 = item2.split(",");
                 let p = gcj2wgs(strArr2);
                 arr.push(p);
             }
         }
         const rescueRoadInfo={
             start:start_wgs,
             end:end_wgs,
             travelWay: way,
             roadInfo:roadInfo,
             modelInfo:modelInfo,
             position:arr
         }
         return Promise.resolve(rescueRoadInfo)})
}
export {
    getAllEarthquakePositionInfo,
    initEarthquakePositionInfo,
    getEarthquakeInfoById,
    getAllNearHospitalPositionByEarthquakeId,
    getHospitalByHospitalId,
    getAllNearHospitalByEarthquakeId,
    getAllNearFireCenterByEarthquakeId,
    getAllNearFireCenterPositionByEarthquakeId,
    getNearFireCenterWeightByEarthquakeId,
    getNearestHospitalByPosition,
    getDetailEarthquakeInfoById,
    searchRouteApi
}