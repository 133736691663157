<template>
  <transition name="slide-fade">
    <div
      v-if="showBox"
      class="detail-box"
    >
      <div style="display: flex;justify-content: space-between;align-items: center;padding: 3px">
        <h2 style="color: black;margin: 0">
          {{ title }}
        </h2>
        <div>
          <el-button
            v-if="imgButtonVisible"
            type="info1"
            size="small"
            style="margin: 5px"
            icon="el-icon-picture"
            @click="$refs.img.ImgDisplayVisible=true"
          >
            灾后图片
          </el-button>
          <el-button
            v-if="historyButtonVisible"
            type="info1"
            size="small"
            style="margin: 5px"
            icon="el-icon-s-order"
            @click="$refs.history.creatHistoryEarthquake()"
          >
            历史地震
          </el-button>
          <el-button
            v-if="showButton&&judgeSelected"
            type="primary"
            size="mini"
            @click="$emit('onClickButton')"
          >
            选择
          </el-button>
          <i
            class="el-icon-close"
            style="color: black;cursor: pointer"
            @click="$emit('onClose')"
          />
        </div>
      </div>
      <el-table
        :data="info"
        :stripe="true"
        :show-header="false"
        border
      >
        <el-table-column
          prop="key"
          label="key"
          width="100"
        />
        <el-table-column
          prop="value"
          label="value"
        />
      </el-table>
    </div>
  </transition>
  <ImgDisplay ref="img" />
  <HistoryEarthquake
    ref="history"
    :earthquake="info"
  />
</template>

<script>
import ImgDisplay from "@/components/image-display.vue";
import HistoryEarthquake from "@/components/history-earthquake.vue";
export default {
  name: "DetailBox",
  components: {
    ImgDisplay,
    HistoryEarthquake,
  },
    // eslint-disable-next-line vue/require-prop-types,vue/prop-name-casing
  props: ['info','title','showBox','showButton','imgButtonVisible','historyButtonVisible','judgeSelected'],
  emits: ['onClickButton','onClose'],
  data() {
    return {
    }
  },
  created(){
    // console.log(this.info);
  },
}
</script>

<style scoped>
.detail-box{
  padding: 9px 13px 14px 13px;
  position: absolute;
  bottom: 10%;
  right: 30px;
  color: #fff!important;
  background: rgba(255,255,255,100%);
  width: 450px;
  z-index: 999;

  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}

</style>
