<template>
    <el-dialog
        title="长-短轴/倍数"
        :modal="false"
        custom-class="custom-dialog"
        v-model="showModule">
<div>
    <span class="textspan">内层:</span>
    <div class="boxnumber">
        <span>Ra：</span>
        <el-input-number  controls-position="right" size="small" v-model="paraList1.longRadius" :step="valueStep" >
            <template #decrease-icon><el-icon><Minus /></el-icon></template>
            <template #increase-icon><el-icon><Plus /></el-icon></template>
        </el-input-number>
        <span style="margin-left: 1%">Rb：</span>
        <el-input-number  controls-position="right" size="small" v-model="paraList1.shortRadius" :step="valueStep" >
            <template #decrease-icon><el-icon><Minus /></el-icon></template>
            <template #increase-icon><el-icon><Plus /></el-icon></template>
        </el-input-number>
        <span style="margin-left: 1%">Angle：</span>
        <el-input-number controls-position="right" size="small" v-model="paraList1.angle" :step="valueStep" @mouseover="handleMouseOver">
            <template #decrease-icon><el-icon><Minus /></el-icon></template>
            <template #increase-icon><el-icon><Plus /></el-icon></template>
        </el-input-number>
    </div>
    <span class="textspan">中层:</span>
    <div class="boxnumber">
        <span>Ra：</span>
        <el-input-number  controls-position="right" size="small" v-model="paraList2.longRadius" :step="valueStep" >
            <template #decrease-icon><el-icon><Minus /></el-icon></template>
            <template #increase-icon><el-icon><Plus /></el-icon></template>
        </el-input-number>
        <span style="margin-left: 1%">Rb：</span>
        <el-input-number  controls-position="right" size="small" v-model="paraList2.shortRadius" :step="valueStep" >
            <template #decrease-icon><el-icon><Minus /></el-icon></template>
            <template #increase-icon><el-icon><Plus /></el-icon></template>
        </el-input-number>
        <span style="margin-left: 1%">Angle：</span>
        <el-input-number controls-position="right" size="small" v-model="paraList2.angle" :step="valueStep" @mouseover="handleMouseOver">
            <template #decrease-icon><el-icon><Minus /></el-icon></template>
            <template #increase-icon><el-icon><Plus /></el-icon></template>
        </el-input-number>
    </div>
    <span class="textspan">最外层:</span>
    <div class="boxnumber">
        <span>Ra：</span>
        <el-input-number  controls-position="right" size="small"  v-model="this.paraList3.longRadius" :step="valueStep" >
            <template #decrease-icon><el-icon><Minus /></el-icon></template>
            <template #increase-icon><el-icon><Plus /></el-icon></template>
        </el-input-number>
        <span style="margin-left: 1%">Rb：</span>
        <el-input-number  controls-position="right" size="small" v-model="paraList3.shortRadius" :step="valueStep" >
            <template #decrease-icon><el-icon><Minus /></el-icon></template>
            <template #increase-icon><el-icon><Plus /></el-icon></template>
        </el-input-number>
        <span style="margin-left: 1%">Angle：</span>
        <el-input-number controls-position="right" size="small"  v-model="paraList3.angle" :step="valueStep" @mouseover="handleMouseOver">
            <template #decrease-icon><el-icon><Minus /></el-icon></template>
            <template #increase-icon><el-icon><Plus /></el-icon></template>
        </el-input-number>
    </div>
    <div class="boxnumber">
        <span class="textspan">步长:</span>
        <el-select
            v-model="valueStep"
            clearable
            placeholder="Select"
            style="width: 100px; margin:5%;"
        >
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
        </el-select>
    </div>
    <span class="textspan">倍数:</span>
    <div class="boxnumber">
        <span>长轴：</span>
        <el-input size="small" style="width: 22%" placeholder="倍率" v-model="adjusteclipsepara.majoraxis"></el-input>
        <span style="margin-left: 1%">短轴：</span>
        <el-input size="small" style="width: 23%"  placeholder="倍率" v-model="adjusteclipsepara.minoraxis"></el-input>
        <span style="margin-left: 1%">角度偏移：</span>
        <el-input size="small" style="width: 15%;margin-bottom: 7%" placeholder="弧度值" v-model="adjusteclipsepara.moveangle"></el-input>
    </div>
    <el-button style=" position:absolute;right: 5%;bottom: 2%" icon="el-icon-check" type="primary" circle @click="submitadjust" ></el-button>
</div>
</el-dialog>
</template>

<script>
import {myMessage, myNotify} from "@/assets/js/utils";

export default {
    name: "adjust-intensityimg",
    props:{
        paraList1props:{
            type:Object,
            required:true
        },
        paraList2props:{
            type:Object,
            required:true
        },
        paraList3props:{
            type:Object,
            required:true
        },
    },
    components:{

    },
    data(){
      return{
          remindcount:3,
          timer:null,
          showModule:false,
          valueStep:0.001,
          options: [{value: 1, label: '1'}, {value: 0.1, label: '0.1'}, {value: 0.01, label: '0.01'}, {value: 0.001, label: '0.001'}, {value: 0.0001, label: '0,0001'}, {value: 0.00001, label: '0.00001'},],
          paraList1:{
              "longRadius": 0.0,
              "shortRadius": 0.0,
              "angle": 0.6,
              "intensity": 0,
              "earthquakeId": "1474"
          },
          paraList2:{
              "longRadius": 0.0,
              "shortRadius": 0.0,
              "angle": 0.6,
              "intensity": 0,
              "earthquakeId": "1474"
          },
          paraList3:{
              "longRadius": 0.0,
              "shortRadius": 0.0,
              "angle": 0.6,
              "intensity": 0,
              "earthquakeId": "1474"
          },
          adjusteclipsepara:{
            majoraxis:1,
            minoraxis:1,
            moveangle:0
          },
      }
    },
    watch:{
        remindcount(newVal){
            if(newVal == 0){
                this.startTimer()
            }
        },
        paraList1props(newVal){
            this.paraList1 = this.paraList1props
            this.paraList2 = this.paraList2props
            this.paraList3 = this.paraList3props
        }

    },
    methods:{
        handleMouseOver(){
            if(this.remindcount!=0){
                myNotify.elWarning('这里的角度是弧度制，1弧度与等于57.29')
                this.remindcount = this.remindcount -1
            }
        },
        startTimer(){
            //清除上一个定时器
            if(this.timer !== null){
                clearTimeout(this.timer)
            }
            this.timer = setTimeout(()=>{
                this.remindcount+=1;
                this.timer = null
            },6000) //6s执行一次
        },
        submitadjust(){
        this.$emit("adjustlistR",this.paraList1,this.paraList2,this.paraList3,this.adjusteclipsepara)
            myMessage.elSuccess("参数以及倍数调整已完成并修改！")
        },
    }
}
</script>

<style >
.custom-dialog {
    position: absolute;
    top: 5%; /* 离顶部的距离 */
    right: 2%; /* 离右边的距离 */
    margin-right: 2%; /* 重置默认的 margin */
    width: 34%;
}
.textspan{
    margin-bottom: 2%;
    font-size: 1em;
}
.boxnumber{
   margin-top: 2%;
    margin-bottom: 2%;
}
</style>