<template>
  <el-dialog
    v-model="predictResultVisible"
    title="灾区识别结果"
    width="68vw"
    center
    top="2.5vh"
  >
    <el-table
      :data="predictResultInfo"
      style="width: 100%"
    >
      <el-table-column
        prop="formatted_address"
        label="区域"
       
        align="center"
      />
      <el-table-column
        label="受损信息"
        align="center"
      >
        <el-table-column
          prop="total_analysis.intact"
          label="完整"
          
          align="center"
        />
        <el-table-column
          prop="total_analysis.slight"
          label="轻微"
          
          align="center"
        />
        <el-table-column
          prop="total_analysis.severe"
          label="严重"
          
          align="center"
        />
        <el-table-column
          prop="total_analysis.collapse"
          label="倒塌"
          
          align="center"
        />
      </el-table-column>
    </el-table>
  </el-dialog>
</template>
  
  <script>
  
  export default {
    name: "identification-resulttwo",
      // eslint-disable-next-line vue/require-prop-types
    props:['predictResultInfo'],
    data(){
      return {
        predictResultVisible:false,
        fit:'scale-down',
        tableData : [
        {
            "formatted_address": "云南省大理白族自治州漾濞彝族自治县苍山西镇",
            "total_analysis": {
                "collapse": 10,
                "intact": 28,
                "severe": 16,
                "slight": 28
            }
        },
        {
            "formatted_address": "上海市浦东新区南汇新城镇上海海洋大学(临港校区)",
            "total_analysis": {
                "collapse": 0,
                "intact": 5,
                "severe": 1,
                "slight": 2
            }
        }
    ],
      }
    },
    methods:{
      
      },
  }
  </script>
  
  <style scoped>
  .circle {
    border-radius: 50%;
    width: 63px;
    height: 63px;
    background: var(--color);
    /* 宽度和高度需要相等 */
  }
  .img{
    height: 300px;
    width: 400px;
    margin-left: 25%;
    margin-right: 25%;
  }
  </style>
  