<template>
    <div class="container">
        <div class="chart" id="chart"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";

export default {
    name: "need-goodstwo",
    props:{
        datasource:{
            type:Array,
            required:true
        }
    },
    data(){
        return{
            myChart:null,
            xData:['清洁饮水', '大米/面', '食用油', '蔬菜', '肉蛋类', '衣服', '鞋子','被子','帐篷','移动厕所'],
            yData:[200, 500, 400, 700, 680, 2001,100],
            MAX :[300, 777, 888, 999, 680, 2200,200],
        }
    },
    computed:{
        maxList(){
            let max = []
            for (let i = 0;i<10;i++){
                max[i] = this.datasource[i]*1.3
            }
            return max
        }
    },
    mounted() {
        this.createEcharts()
        console.log(this.datasource)
    },
    watch:{
        datasource(newVal){
            this.destoryEcharts()
            this.createEcharts()
        },
    },
    methods: {
        createEcharts() {
            let chartDom = document.getElementById('chart');
            this.myChart = echarts.init(chartDom);
            this.yData=this.datasource
            this.MAX =this.maxList
            this.renderChart(this.myChart)
        },
        destoryEcharts() {
            if (this.myChart) {
                this.myChart.dispose();
                this.myChart = null;
            }
        },
        renderChart(myChart){
            // 绘制左侧面
            const CubeLeft = echarts.graphic.extendShape({
                shape: {
                    x: 0,
                    y: 0,
                },
                buildPath: function (ctx, shape) {
                    const xAxisPoint = shape.xAxisPoint;
                    const c0 = [shape.x, shape.y];
                    const c1 = [shape.x - 5, shape.y - 5];
                    const c2 = [xAxisPoint[0] - 5, xAxisPoint[1] - 5];
                    const c3 = [xAxisPoint[0], xAxisPoint[1]];
                    ctx
                        .moveTo(c0[0], c0[1])
                        .lineTo(c1[0], c1[1])
                        .lineTo(c2[0], c2[1])
                        .lineTo(c3[0], c3[1])
                        .closePath();
                },
            });
            // 绘制右侧面
            const CubeRight = echarts.graphic.extendShape({
                shape: {
                    x: 0,
                    y: 0,
                },
                buildPath: function (ctx, shape) {
                    const xAxisPoint = shape.xAxisPoint;
                    const c1 = [shape.x, shape.y];
                    const c2 = [xAxisPoint[0], xAxisPoint[1]];
                    const c3 = [xAxisPoint[0] + 10, xAxisPoint[1] - 5];
                    const c4 = [shape.x + 10, shape.y - 5];
                    ctx
                        .moveTo(c1[0], c1[1])
                        .lineTo(c2[0], c2[1])
                        .lineTo(c3[0], c3[1])
                        .lineTo(c4[0], c4[1])
                        .closePath();
                },
            });
            // 绘制顶面
            const CubeTop = echarts.graphic.extendShape({
                shape: {
                    x: 0,
                    y: 0,
                },
                buildPath: function (ctx, shape) {
                    const c1 = [shape.x, shape.y];
                    const c2 = [shape.x + 10, shape.y - 5];
                    const c3 = [shape.x + 5, shape.y - 10];
                    const c4 = [shape.x - 5, shape.y - 5];
                    ctx
                        .moveTo(c1[0], c1[1])
                        .lineTo(c2[0], c2[1])
                        .lineTo(c3[0], c3[1])
                        .lineTo(c4[0], c4[1])
                        .closePath();
                },
            });
            // 注册三个面图形
            echarts.graphic.registerShape("CubeLeft", CubeLeft);
            echarts.graphic.registerShape("CubeRight", CubeRight);
            echarts.graphic.registerShape("CubeTop", CubeTop);
            const option = {
                backgroundColor: "transparent",
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "line", // 默认为直线，可选为：'line' | 'shadow'
                    },
                    formatter: function (params, ticket, callback) {
                        const item = params[1];
                        return item.name + " : " + item.value;
                    },
                },
                grid: {
                    left: "1%", //图表距边框的距离
                    right: "3%",
                    top: "11%",
                    bottom: "5%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    data: this.xData,
                    axisLine: {
                        show: true,
                        lineStyle: { color: "#636E7C" },
                    },
                    axisLabel: {
                        color: "rgba(255,255,255,.8)", //坐标的字体颜色
                        fontSize: 6,
                    },
                    axisTick: {
                        //坐标轴刻度颜色
                        show: false,
                        length: 9,
                        alignWithLabel: true,
                        lineStyle: {
                            color: "#7DFFFD",
                        },
                    },
                    offset: 5,
                },
                yAxis: {
                    type: "value",
                    axisLine: {
                        show: true,
                        lineStyle: { color: "#636E7C" },
                    },
                    axisLabel: {
                        color: "rgba(255,255,255,.8)", //坐标的字体颜色
                        fontSize: 12,
                    },
                    axisTick: {
                        //坐标轴刻度颜色
                        show: false,
                        length: 9,
                        alignWithLabel: true,
                        lineStyle: {
                            color: "#7DFFFD",
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "rgba(255,255,255,.25)",
                            type: "dashed",
                        },
                    },
                },
                series: [
                    // 透明层
                    {
                        type: "custom",
                        renderItem: function (params, api) {
                            const location = api.coord([api.value(0), api.value(1)]);
                            return {
                                type: "group",
                                children: [
                                    {
                                        type: "CubeLeft",
                                        shape: {
                                            api,
                                            x: location[0],
                                            y: location[1],
                                            xAxisPoint: api.coord([api.value(0), 0]),
                                        },
                                        style: {
                                            fill: "rgba(47,102,192,.27)",
                                        },
                                    },
                                    {
                                        type: "CubeRight",
                                        shape: {
                                            api,
                                            x: location[0],
                                            y: location[1],
                                            xAxisPoint: api.coord([api.value(0), 0]),
                                        },
                                        style: {
                                            fill: "rgba(59,128,226,.27)",
                                        },
                                    },
                                    {
                                        type: "CubeTop",
                                        shape: {
                                            api,
                                            x: location[0],
                                            y: location[1],
                                            xAxisPoint: api.coord([api.value(0), 0]),
                                        },
                                        style: {
                                            fill: "rgba(72,156,221,.27)",
                                        },
                                    },
                                ],
                            };
                        },
                        data: this.MAX,
                    },
                    //内容层
                    {
                        type: "custom",
                        renderItem: (params, api) => {
                            const location = api.coord([api.value(0), api.value(1)]);
                            var color =
                                api.value(1) > 2000
                                    ? "rgb(136, 26, 7)"
                                    : new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        {
                                            offset: 0,
                                            color: "rgb(142, 220, 190)",
                                        },
                                        {
                                            offset: 1,
                                            color: "rgb(85, 101, 95)",
                                        },
                                    ]);
                            return {
                                type: "group",
                                children: [
                                    {
                                        type: "CubeLeft",
                                        shape: {
                                            api,
                                            xValue: api.value(0),
                                            yValue: api.value(1),
                                            x: location[0],
                                            y: location[1],
                                            xAxisPoint: api.coord([api.value(0), 0]),
                                        },
                                        style: {
                                            fill: color,
                                        },
                                    },
                                    {
                                        type: "CubeRight",
                                        shape: {
                                            api,
                                            xValue: api.value(0),
                                            yValue: api.value(1),
                                            x: location[0],
                                            y: location[1],
                                            xAxisPoint: api.coord([api.value(0), 0]),
                                        },
                                        style: {
                                            fill: color,
                                        },
                                    },
                                    {
                                        type: "CubeTop",
                                        shape: {
                                            api,
                                            xValue: api.value(0),
                                            yValue: api.value(1),
                                            x: location[0],
                                            y: location[1],
                                            xAxisPoint: api.coord([api.value(0), 0]),
                                        },
                                        style: {
                                            fill: color,
                                        },
                                    },
                                ],
                            };
                        },
                        data: this.yData,
                    },
                    {
                        type: "bar",
                        label: {
                            normal: {
                                show: true,
                                position: "top",
                                fontSize: 12,
                                color: "skyblue",
                                offset: [2, -25],
                            },
                        },
                        itemStyle: {
                            color: "transparent",
                        },
                        tooltip: {},
                        data: this.MAX,
                    },
                ],
            };
            // 循环高亮展示
            let currentIndex = -1;
            let len = option.series[0].data.length;
            setInterval(() => {
                // 取消之前高亮的图形
                myChart.dispatchAction({
                    type: "downplay",
                    seriesIndex: 0,
                    dataIndex: currentIndex,
                });
                currentIndex = (currentIndex + 1) % len;
                // 高亮当前图形
                myChart.dispatchAction({
                    type: "highlight",
                    seriesIndex: 0,
                    dataIndex: currentIndex,
                });
                // 显示 tooltip
                myChart.dispatchAction({
                    type: "showTip",
                    seriesIndex: 0,
                    dataIndex: currentIndex,
                });
            }, 2000);

            if (option && typeof option === "object") {
                myChart.setOption(option);
            }
        }
    }
}
</script>

<style scoped>
body {
    background: #2d2f38;
}

.container {
    width: 420px;
    height: 300px;
    padding: 10px;
    border-radius: 20px;
    background: linear-gradient(225deg, rgb(13, 13, 14), #221c22);
    box-shadow: -25px 25px 45px #111213, 25px -25px 45px #221c22;
}

.chart {
    width: 100%;
    height: 100%;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    padding: 0;
    margin : 0;
}

body {
    font-size      : 16px;
    display        : flex;
    align-items    : center;
    justify-content: center;
    background     : #222;
    color          : #fff;
    overflow       : hidden;
    height         : 100vh;
}

/* 设置水平垂直居中 */

input {
    background    : none;
    border        : none;
    outline       : none;
    vertical-align: middle;
}

/* 防止未加载完成显示undefined */
[v-cloak] {
    display: none;
}

/* 去掉button默认样式 */
button {
    border    : none;
    background: transparent;
    outline   : none;
}

/* flex布局相关样式 */
.flex-column {
    display       : flex;
    flex-direction: column;
    align-items   : center;
}

.flex-row {
    display       : flex;
    flex-direction: row;
    align-items   : center;
}

.j_c {
    justify-content: center;
}

.j_b {
    justify-content: space-between;
}

.flex-wrap {
    display  : flex;
    flex-wrap: wrap;
}

/**滚动条的宽度*/
::-webkit-scrollbar {
    width : 4px;
    height: 4px;
}

/* 滚动条的滑块 */
::-webkit-scrollbar-thumb {
    background-color: #eaecf1;
    border-radius   : 3px;
}
</style>