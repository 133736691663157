<template>
    <el-divider></el-divider>
    <br>
    <span  class="spanText">伤亡人数估算模型:</span>
    <div style="display: flex;margin-bottom: 3%">
        <div style="width: 470px; margin-top: 8px">
            <el-input
                    placeholder="模型公式"
                    v-model="inputModel"
                    :disabled="true"
                    prefix-icon="el-icon-data-analysis"
                    style="font-weight: bold; color: black;">
            </el-input>
        </div>
        <div style="margin-top: 8px" class="selectBox" >
            <el-select v-model="selectValue" filterable placeholder="请选择评估模型">
                <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
            </el-select>
        </div>
    </div>
    <br>
    <div style="overflow: hidden;">
        <div style="width: 400px; height: 420px; float: left;">
            <deathPerson :deathpersonresult="deathpersonresult"></deathPerson>
        </div>
        <div class="contralParaModel" style="position: absolute;right: 10%">
            <el-form size="small" style="font-weight: bolder;" label-position="left" :model="buildModelForm"
                     :rules="rulerdeath"
            >
                <el-form-item label="地震震级" class="itemcss" prop="paraAm">
                    <el-input placeholder="震级" v-model="buildModelForm.mani">
                    </el-input>
                </el-form-item>
                <el-form-item label="地震烈度" class="itemcss" prop="paraAm">
                    <el-input placeholder="震级" v-model="buildModelForm.inten">
                    </el-input>
                </el-form-item>
                <el-form-item label="震发时间" class="itemcss" prop="paraAtime">
                    <el-select v-model="buildModelForm.paraAtime" filterable placeholder="请选择时间段" size="small">
                        <el-option
                                v-for="item in timeOption"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="人口密度" class="itemcss" prop="paraAstrength">
                    <el-input placeholder="人口密度" v-model="buildModelForm.paraAden"></el-input>
                </el-form-item>
                <el-form-item label="损坏比例" class="itemcss" prop="paraBdr">
                    <el-input placeholder="建筑物损坏比例" v-model="buildModelForm.paraBdr">
                    </el-input>
                </el-form-item>
                <el-form-item label="抗震水平" class="itemcss" prop="paraAall">
                    <el-input placeholder="区域整体抗震水平" v-model="buildModelForm.paraAall"></el-input>
                </el-form-item>
            </el-form>
            <span style="left: -18%;position: absolute;margin-top: 5%">当前评估地震信息：</span>
            <el-button type="primary" icon="el-icon-view" style="margin: 3%;margin-left: 32%" size="small" @click="notifynow">查看</el-button>
            <el-button type="primary" icon="el-icon-edit-outline" size="small" @click="changeselected">修改</el-button>
            <el-button circle icon="el-icon-cpu" style="position: absolute;background-color: papayawhip" @click="resuletfun"></el-button>
        </div>

    </div>
</template>

<script>
import {deathParams} from "@/assets/js/elform-verify/validate";
import {myMessage} from "@/assets/js/utils";
import deathPerson from "@/components/echartsgraph/death-person.vue";
import {changeselected} from "@/assets/js/reuse-function";

export default {
    name: "persondie-estimate",
    props:{
      earthquakeInfostring:{
          type:String,
          required:true
      },
     earthquakeMain:{
         type:Number,
         required: true,
     },
     earthquakeInten:{
       type:Number,
       required:true,
     },
     personDen:{
         type:Number,
         required:true
     }
    },
    components:{
        deathPerson
    },
    data(){
        return{
            inputModel: '',
            selectValue: '',
            options: [{
                value: 0,
                label: '建筑物指数损坏模型'
            }, ],
            modelDetailFrom:["建筑物损坏模型:其中N为伤亡人数，Bdr为建筑物损坏比例，α_m  α_den" +
            "  α_time  α_strength  α_all分别为地震震级和强度、人口密度、" +
            "地震发生时间和区域整体抗震水平的修正系数",
            ],
            modelForm:[
                "N=0.461⋅α_m⋅α_den⋅α_time⋅α_strength⋅α_all⋅e^(12.285⋅Bdr)",
            ],
            buildModelForm: {
                mani:"",
                inten:"",
                paraBdr:'',
                paraAm:'',
                paraAden:'',
                paraAtime:'',
                paraAstrength:'',
                paraAall:'',
                paraeBdr:""
            },
            deathpersonresult:0,
            earthquakeInfo:"这条地震所有信息都在这儿",
            rulerdeath:deathParams,
            timeOption:[{value:2, label:"01:00--06:00"}, {value:1, label:"06:00--09:00"}, {value:9/5, label:"09:00--20:00"}, {value:5/3, label:"20:00--01:00"}],
        }
    },
    computed:{

    },
    watch:{
        selectValue(newVal){
            let tempInputModel = this.modelForm[newVal]
            this.inputModel = tempInputModel
        },
    },
    methods:{
        changeselected,
        notifynow(){
            this.buildModelForm.mani = this.earthquakeMain
            this.buildModelForm.inten =this.earthquakeInten
            this.buildModelForm.paraAden = this.personDen
            console.log(this.earthquakeMain)
            this.$notify({
                title: '提示', message:this.earthquakeInfostring, duration: 0});
        },
        resuletfun(){
            myMessage.elSuccess("开始评估，请稍后~~")

            this.buildModelForm.paraAm = Math.abs((this.buildModelForm.mani-4.17)/(0.35*this.buildModelForm.inten - 0.97))
            this.buildModelForm.paraAstrength = 0.74+0.05*Math.log(this.buildModelForm.paraAden)
            this.buildModelForm.paraeBdr = Math.exp(12.285*parseFloat(this.buildModelForm.paraBdr))
            this.buildModelForm.paraAall = parseFloat(this.buildModelForm.paraAall)
            let result = 0.461;
            Object.keys(this.buildModelForm).forEach(key=>{
                if(key !== 'mani'&& key !== 'inten'&& key!=='paraBdr'&& key!=='paraAden'){
                    console.log(this.buildModelForm[key])
                    result *= this.buildModelForm[key];
                }
            })
            this.deathpersonresult = result
        },
    }
}
</script>

<style scoped>
.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}
.spanText{
    font-size: larger;
    font-weight: bold;
}
.selectBox{
    position: absolute;
    right: 10%;
}
.contralParaModel{
    height: 30%;
    width:25%;

}
.itemcss{
    margin-bottom: 10%;
}
</style>