<template>
  <vc-entity
    :position="[start.longitude, start.latitude]"
  >
    <vc-graphics-ellipse
      :semi-minor-axis="50.0"
      :semi-major-axis="50.0"
      :material="[255, 0, 0, 125]"
    />
    <vc-graphics-label
      text="出发点"
      font="20px sans-serif"
      :pixel-offset="[0, 20]"
      fill-color="red"
    />
  </vc-entity>
  <vc-entity
    :position="[end.longitude, end.latitude]"
  >
    <vc-graphics-ellipse
      :semi-minor-axis="50.0"
      :semi-major-axis="50.0"
      :material="[255, 0, 0, 125]"
    />
    <vc-graphics-label
      text="待救援点"
      font="20px sans-serif"
      :pixel-offset="[0, 20]"
      fill-color="red"
    />
  </vc-entity>
  <vc-entity>
    <vc-graphics-polyline
      :positions="position"
      :material="roadInfo.material"
      :width="roadInfo.width"
      :clamp-to-ground="roadInfo.clampToGround"
    />
  </vc-entity>
  <vc-entity
    ref="entity"
    :position="modelPosition"
    :orientation="orientation"
    description="Hello VueCesium"
  >
    <vc-graphics-model
      ref="model"
      :uri="modelInfo.uri"
      :minimum-pixel-size="128"
      :scale="modelInfo.scale"
    />
  </vc-entity>
</template>
<script>
import { lnglatArrToCartesianArr} from "@/assets/js/cesium";

export default {
  name: "CesiumPathPlaning",
  props:{
    time:{
      type:Object,
      default:()=>({
        start:new Date('2024-08-03T12:00:00Z'),
        end:new Date('2024-08-03T12:00:00Z')
      })
    },
    start:{
      type:Object,
      default:()=>({
        longitude:100.246377,
        latitude:25.596945
      })
    },
    end:{
      type:Object,
      default:()=>({
        longitude:100.245625,
        latitude:25.5973
      })
    },
    cesium:{
      type:Object,
      default:()=>({})
    },
    travelWay:{
      type:String,
      default:'driving'
    },
    roadInfo:{
      type:Object,
      default:()=>({
        clampToGround: true,
        material: 'red',
        width: 5,
      })
    },
    modelInfo:{
      type:Object,
      default:()=>({
        scale:10,
        uri:'https://file.zouran.top/assets/cesium/glb/motuo.glb'
      })
    },
    position:{
      type:Array,
      default:()=>[[100.246377, 25.596945], [100.246068,25.596844],[100.245557,25.597472]]
    }
  },
  computed:{
    modelPosition(){
      const Cesium=this.cesium
      const positions=lnglatArrToCartesianArr(this.position)
      if (!positions) return;
      let allDis = 0;
      for (let index = 0; index < positions.length - 1; index++) {
        let distance = Cesium.Cartesian3.distance(positions[index],positions[index + 1]);
        allDis += distance;
      }
      let playTime = Math.abs(this.time.end.getTime() - this.time.start.getTime())/1000;
      let v = allDis / playTime;
      let startTime =  Cesium.JulianDate.fromDate(this.time.start);
      let endTime = this.time.end;
      let property = new Cesium.SampledPositionProperty();
      let t = 0;
      for (let i = 1; i < positions.length; i++) {
        if (i === 1) {
          property.addSample(startTime, positions[0]);
        }
        let dis = Cesium.Cartesian3.distance(positions[i], positions[i - 1]);
        let time = dis / v + t;
        let julianDate = Cesium.JulianDate.addSeconds(
            startTime,
            time,
            new Cesium.JulianDate()
        );
        property.addSample(julianDate, positions[i]);
        t += dis / v;
      }
      return property

    },
    orientation(){
      return new Cesium.VelocityOrientationProperty(this.modelPosition)
    }
  }
}
</script>
<style scoped>

</style>