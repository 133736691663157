<template>
<el-dialog v-model="showDamagemodule" width="60%"
           title="震灾评估"
           :modal="false"
>
    <setparaEstimatePersondieestimate v-if="showmoduleForm.death"
                                      :earthquake-infostring="earquakeInfoAll"
                                      :earthquake-main="earquakeMani"
                                      :earthquake-inten="earquakeInten"
                                      :person-den="personDen"
                                      @openothermodule="openmodule"
    ></setparaEstimatePersondieestimate>
    <setparaEstimateSuppliesestimate v-if="showmoduleForm.source"
                                     :earthquake-infostring="earquakeInfoAll"
                                     :earthquake-main="earquakeMani"
                                     @openothermodule="openmodule"
    ></setparaEstimateSuppliesestimate>
    <setparaEstimateIndoorestimate v-if="showmoduleForm.inner"

    ></setparaEstimateIndoorestimate>
    <setparaEstimateEconimiclossestimate v-if="showmoduleForm.economic"></setparaEstimateEconimiclossestimate>

    <setparaEstimateBuriedestimate v-if="showmoduleForm.bury"  :earthquake-infostring="earquakeInfoAll"
                                                :earthquake-inten="earquakeInten"
                                                @openothermodule="openmodule"
    ></setparaEstimateBuriedestimate>

</el-dialog>
</template>

<script>
import setparaEstimatePersondieestimate from "@/components/setpara-estimate-persondieestimate.vue";
import setparaEstimateIndoorestimate from "@/components/setpara-estimate-indoorestimate.vue";
import setparaEstimateEconimiclossestimate from "@/components/setpara-estimate-econimiclossestimate.vue";
import setparaEstimateSuppliesestimate from "@/components/setpara-estimate-suppliesestimate.vue";
import setparaEstimateBuriedestimate from "@/components/setpara-estimate-buriedestimate.vue";
export default {
    name: "setpara-estimate",
    props:{
      controlindex:{
          type:Number,
          required:true
      },
      selectedearthquake:{
          type:Object,
          required: true
      }
    },
    components:{
        setparaEstimatePersondieestimate,
        setparaEstimateIndoorestimate,
        setparaEstimateEconimiclossestimate,
        setparaEstimateSuppliesestimate,
        setparaEstimateBuriedestimate
    },
    data(){
        return{
            showDamagemodule:false,
            earquakeMani:"",
            earquakeInten:"",
            personDen:71,
            showmoduleForm:{
                death:false,
                bury:false,
                source:false,
                inner:false,
                economic:false
            }

        }
    },
    computed:{
        earquakeInfoAll(){
            let stringtemp = '';
            Object.keys(this.selectedearthquake).forEach(key => {
                if (key !== 'intensityLineList') {  // 检查键而不是值
                    let value = this.selectedearthquake[key];
                    // 处理不同类型的数据，确保所有内容都能被转换为字符串
                    if (value !== null && value !== undefined) {
                        stringtemp += this.textearthquake(key) + String(value) + "  ";
                    }
                }
                if (key ==='magnitude'){
                    this.earquakeMani =  this.selectedearthquake[key];
                }
                if (key ==='highIntensity'){
                    this.earquakeInten = this.selectedearthquake[key];
                }
            });
            return stringtemp
        }
    },
    watch:{
        controlindex(newVal){
            this.closefun()
            Object.keys(this.showmoduleForm).forEach(key=>{
                if (newVal === key){
                    this.showmoduleForm[key] = true
                }
            })
            console.log(this.earquakeInfoAll)
        },
    },
    methods:{
        closefun(){
            Object.keys(this.showmoduleForm).forEach(key=>{
                    this.showmoduleForm[key] = false
            })
        },
        openmodule(module){
            this.$emit("openmodule",module)
        },
        textearthquake(type){
            if (type === 'earthquakeId'){
                return '地震编号：'
            }else if (type === 'earthquakeName'){
                return '地震名称：'
            }else if (type === 'magnitude'){
                return '地震震级：'
            }else if (type === 'highIntensity'){
                return '地震烈度：'
            }else if (type === 'longitude'){
                return '经度：'
            }else if (type === 'latitude'){
                return '纬度：'
            }else if (type === 'depth'){
                return '地震深度(m)：'
            }else if (type === 'earthquakeTime'){
                return '震发时间：'
            }else {
                return ''
            }
        },
    }
}
</script>

<style scoped>

</style>