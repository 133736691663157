<template>
  <!--      <vc-terrain-provider-tianditu token="fd7029d3dff756b437af91d68aadc6bf"></vc-terrain-provider-tianditu>-->
  <vc-layer-imagery
    :alpha="imageryConfig.alpha"
    :brightness="imageryConfig.brightness"
    :contrast="imageryConfig.contrast"
    :sort-order="10"
    :show="show"
  >
    <vc-imagery-provider-tianditu
      ref="provider"
      :map-style="mapStyle"
      token="de232c2bf878c7a7928afde78e339913"
    />
  </vc-layer-imagery>
</template>

<script>
export default {
  name: "CesiumImagery",
  props:{
    mapStyle:{
      type:String,
      default: 'img_w'
    },
    show:{
      type:Boolean,
      default: true
    },
  },
  data(){
    return{
      imageryConfig:{
        alpha:1,
        brightness:1,
        contrast:1
      }
    }
  }
}
</script>
<style scoped>

</style>