<template>
    <div ref="lottieContainer" :style="compustyle"></div>
    <div style="display: flex">
        <div class="sidemenu">
            <el-form label-position="left" label-width="80px" :model="processForm" style="font-weight: bolder" :rules="processRules">
                <el-form-item label="处理Ⅰ">
                    <span>灰度图增强对比度：</span>
                    <el-radio v-model="processForm.radiocompar" label="1">是</el-radio>
                    <el-radio v-model="processForm.radiocompar" label="2">否</el-radio>
                </el-form-item>
                <el-form-item label="处理Ⅱ">
                    <span>降噪：</span>
                    <el-select v-model="processForm.methodsblur" placeholder="请选择方式">
                        <el-option
                            v-for="item in optionblur"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <div v-if="showgs">
                        <span>高斯内核：</span>
                        <el-select v-model="processForm.gsPara.kernel" placeholder="请选择kernel">
                            <el-option
                                v-for="item in optionkerbel"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                        <span>X标准偏差：</span>
                        <el-form-item prop="gsPara.xsigma">
                            <el-input v-model="processForm.gsPara.xsigma" placeholder="请输入标准差"></el-input>
                        </el-form-item>
                    </div>
                    <div v-if="showmd">
                        <span>模糊内核：</span>
                        <el-select v-model="processForm.mdPara.kernel" placeholder="请选择kernel">
                            <el-option
                                v-for="item in optionkerbel"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <div v-if="showside">
                        <span>滤波器直径：</span>
                        <el-form-item prop="sidePara.dPara">
                            <el-input v-model="processForm.sidePara.dPara" placeholder="请输入直径"></el-input>
                        </el-form-item>
                        <span>滤波色差sigma：</span>
                        <el-form-item prop="sidePara.sigma">
                            <el-input v-model="processForm.sidePara.sigma" placeholder="请输入sigma"></el-input>
                        </el-form-item>
                        <span>滤波sigmaspace：</span>
                        <el-form-item prop="sidePara.sigmaspace">
                            <el-input v-model="processForm.sidePara.sigmaspace" placeholder="请输入sigmaspace"></el-input>
                        </el-form-item>
                    </div>
                </el-form-item>
                <el-form-item label="处理Ⅲ" prop="modifysize">
                    <span>裁剪/缩放：</span>
                    <el-radio v-model="processForm.radiochoice" label="1">裁剪</el-radio>
                    <el-radio v-model="processForm.radiochoice" label="2">缩放</el-radio>
                    <el-input :placeholder="showtext" v-model="processForm.modifysize" ></el-input>
                </el-form-item>
            </el-form>
            <el-button style="width: auto;height: auto;position: absolute;bottom: 4%;
            left: 108% ;background-color: brown;color: white" size="small" round
                       @click="setPara"
            >设置完成</el-button>
        </div>
        <div class="initimg">
            <el-upload
            class="upload-demo"
            drag
            action="https://www.xuuyy.icu/flask/processimg"
            :file-list="fileList"
            list-type="picture"
            :before-upload="beforeUpload"
            :on-exceed="handleExceed"
            >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
            <div class="text">
                <p>文件只支持上传处理图片，且不超过10MB</p>
            </div>
        </div>
        <div class="processimg">
            <el-image
                style="width: 100%; height: 100%;border-radius: 15px;"
                :src="processImgForm.grey"
                :key="imgKey"
                fit="fit"></el-image>
        </div>
        <el-button round size="small" class="downloadbutton" style="top: 15%" @click="downloadimg('grey')">下载</el-button>
        <div class="processimg1">
            <el-image
                style="width: 100%; height: 100%;border-radius: 15px"
                :src="processImgForm.denoise"
                :key="imgKey"
                fit="fit"></el-image>
        </div>
        <el-button round size="small" class="downloadbutton" style="top: 47%" @click="downloadimg('denoise')">下载</el-button>
        <div class="processimg2">
            <el-image
                style="width: 100%; height: 100%;border-radius: 15px"
                :src="processImgForm.modify"
                :key="imgKey"
                fit="contain"></el-image>
        </div>
        <el-button round size="small" class="downloadbutton" style="top: 78%" @click="downloadimg('modify')">下载</el-button>
        <el-button style="position: absolute;bottom: 10%;right: 2%;background-color: lightskyblue;color: white"
                   round @click="downloadimg('joint')">下载拼接图</el-button>
        <el-button circle icon="el-icon-refresh-left"
                   style="background-color: #bce689;position: absolute;right: 47%;bottom: 24%"
                   @click="refreshimg"
        ></el-button>
    </div>
</template>

<script>
import lottie from 'lottie-web';
import {testPara} from "../axios/api/others-api";
import {myMessage, myNotify} from "@/assets/js/utils";
import {processPara} from "@/assets/js/elform-verify/validate";
import {triggerDownload} from "@/assets/js/reuse-function";

export default {
    name: "ProcessImg",
    data() {
        return {
            animationPath: require('@/assets/json/iconimg.json'), // 正确的路径格式
            loop: true,
            autoplay: true,
            formLabelAlign:{},
            fileList:[],
            width: 200,
            height: 200,
            showgs:false,
            processImgForm:{
                grey:"https://www.xuuyy.icu/processimg/gray_img.jpg",
                modify:"https://www.xuuyy.icu/processimg/modify_img.jpg",
                denoise:"https://www.xuuyy.icu/processimg/denoise_img.jpg",
                joint:"https://www.xuuyy.icu/processimg/joint_img.jpg",
            },
            showtext:"请输入裁剪大小",
            showmd:false,
            showside:false,
            optionkerbel:[
                {value:3, label:3,}, {value:5, label:5,}, {value:7, label:7,},
                {value:9, label:9,}, {value:11, label:11,}, {value:13, label:13,},
                {value:15, label:15,},
            ],
            optionblur:[{value: 1, label: '高斯模糊'},{value: 2, label: '中值模糊'},{value: 3, label: '双边滤波'}],
            processForm:{
                radiocompar:"2",
                radiochoice:"1",
                modifysize:"",
                methodsblur:"",
                gsPara:{xsigma:"", kernel:""},
                mdPara:{kernel:""},
                sidePara:{dPara:"", sigma:"", sigmaspace:""}
            },
            processRules: processPara,
            imgKey:0
        };
    },
    computed:{
        compustyle(){
            return {
                width: this.width + 'px',
                height: this.height + 'px',
                margin: '2%'
            }
        }
    },
    mounted() {
        lottie.loadAnimation({
            container: this.$refs.lottieContainer,
            renderer: 'svg',
            loop: this.loop,
            autoplay: this.autoplay,
            animationData: this.animationPath
        });
    },
    watch:{
        'processForm.radiochoice'(newValue){
            if (newValue === '1'){
                this.showtext = "请输入裁剪大小"
            }else{
                this.showtext = "请输入缩放大小"
            }
        },
        'processForm.methodsblur'(newValue){
            this.clearall()
            if (newValue === 1){
                this.width = 140
                this.height = 140
                this.showgs = true
            }else if(newValue === 2){
                this.width = 200
                this.height = 200
                this.showmd = true
            }else {
                this.width = 60
                this.height = 60
                this.showside = true
            }
        }
    },
    methods:{
        refreshimg(){
            this.imgKey+=1
        },
        downloadimg(type) {
            this.opennotyfy()
            Object.keys(this.processImgForm).forEach(key => {
                if (type === key) {
                    const url = this.processImgForm[key];
                    triggerDownload(url, `${key}_image.jpg`) // 动态生成下载链接
                }
            });
        },
        beforeUpload(file) {
            // 检查文件类型
            const isImage = file.type.startsWith('image/');
            if (!isImage) {
                myMessage.elError('只能上传图片文件');
                return false;
            }
            // 检查文件大小
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isLt10M) {
                myMessage.elError('文件大小不能超过 10MB');
                return false;
            }
            return true; // 通过检查，允许上传
        },
        opennotyfy(){
            myNotify.elWarning('正在拉取文件，请稍等...')
        },
        // 文件数量超出限制时的处理
        handleExceed(files, fileList) {
            myMessage.elWarning(`当前限制选择 1 个文件`);
        },
        clearall(){
            this.showgs = false
            this.showmd = false
            this.showside = false
        },
        setPara(){
            testPara(this.processForm).then(res=>{
                console.log(res)
            })
            if (this.processForm.modifysize === ''){
                myMessage.elError('请检测参数是否补充完整！！！')
            }else {
                myMessage.elSuccess('设置成功，请上传你要处理的图片~')
            }
        },
    },
};
</script>
<style scoped>
.sidemenu{
    display: flex;
    width: 15%;
    position: absolute;
    left: 2%;
    bottom: 10%;
}
.initimg{
    position: absolute;
    left: 23%;
    width: 30%;
    height: 40%;
    top: 26%;
    color: #6FB0FC;
    background-color: #bce689;
    border: 2px solid #e0e0e0; /* 2px 宽度，实线，黑色 */
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

.initimg .text{
    color: #333333;
    font-weight: bolder;
    text-align: center;
    margin: 10%;

}
.processimg{
    position: absolute;
    top: 2%;
    right: 10%;
    width: 26%;
    height: 30%;
    background-color: #bce689;
    border: 2px solid #ddd; /* 设置边框 */
    border-radius: 15px; /* 圆角 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 阴影 */
}

.processimg1{
    position: absolute;
    top: 33%;
    right: 10%;
    width: 26%;
    height: 30%;
    background-color: #bce689;
    border: 2px solid #ddd; /* 设置边框 */
    border-radius: 15px; /* 圆角 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 阴影 */
}
.processimg2{
    position: absolute;
    top: 64%;
    right: 10%;
    width: 26%;
    height: 30%;
    background-color:#e0e0e0;
    border: 2px solid #ddd; /* 设置边框 */
    border-radius: 15px; /* 圆角 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 阴影 */
}

/deep/ .el-upload{
    width: 100%;
    height: 100%;
}
/deep/ .el-upload .el-upload-dragger{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.upload-demo{
    height: 100%;

}
.downloadbutton{
    background-color:rgba(255, 255, 153, 1);
    position: absolute;
    right: 37%;

}
.downloadbutton:active{
    background-color: #6FB0FC;
}
</style>
