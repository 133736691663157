<template>
  <el-dialog
    v-model="predictResultVisible"
    title="灾区识别结果"
    width="68vw"
    center
    top="2.5vh"
  >
    <el-descriptions
      :title="niha1"
      :column="2"
      border
    >
      <el-descriptions-item>
        <template #label>
          <i class="el-icon-user" />
          图像拍摄地址
        </template>
        {{ predictResultInfo.information.location }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <i class="el-icon-s-data" />
          经纬度
        </template>
        ({{ predictResultInfo.information.longitude }},{{ predictResultInfo.information.latitude }})
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <i class="el-icon-location-outline" />
          拍摄高度
        </template>
        {{ predictResultInfo.information.altitude }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <i class="el-icon-tickets" />
          拍摄时间
        </template>
        {{ predictResultInfo.information.time }}
      </el-descriptions-item>
    </el-descriptions>
    <br>
    <el-collapse>
      <el-collapse-item name="1">
        <template #title>
          具体识别结果
        </template>
        <div>
          <el-descriptions
            :title="niha1"
            :column="2"
            border
          >
            <el-descriptions-item>
              <template #label>
                <i class="el-icon-s-data" />
                完整建筑数量
              </template>
              {{ predictResultInfo.information.analysis.intact }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <i class="el-icon-s-data" />
                轻微受损数量
              </template>
              {{ predictResultInfo.information.analysis.slight }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <i class="el-icon-s-data" />
                严重受损数量
              </template>
              {{ predictResultInfo.information.analysis.severe }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template #label>
                <i class="el-icon-s-data" />
                倒塌数量
              </template>
              {{ predictResultInfo.information.analysis.collapse }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </el-collapse-item>
    </el-collapse>
    <br>
    <el-row :gutter="20">
      <el-col :span="8">
        <el-image
          style="width: 300px; height: 300px"
          :src="first_url"
          :fit="fit"
          :preview-src-list="imageList"
        />
      </el-col>
      <el-col :span="8">
        <el-image
          style="width: 300px; height: 300px"
          :src="second_url"
          :fit="fit"
          :preview-src-list="imageList"
        />
      </el-col>
      <el-col :span="8">
        <el-image
          style="width: 300px; height: 300px"
          :src="legend_url"
          :fit="fit"
          :preview-src-list="imageList"
        />
      </el-col>
    </el-row>
  </el-dialog>
</template>
  
  <script>
  
  export default {
    name: "identification-result",
      // eslint-disable-next-line vue/require-prop-types
    props:['predictResultInfo'],
    data(){
      return {
        predictResultVisible:false,
        fit:'scale-down',
        count:0,
        first_url:'https://file.zouran.top/imgs/fenxi/pic1.jpg',
        second_url:'https://file.zouran.top/imgs/fenxi/pic2.jpg',
        legend_url:'https://file.zouran.top/imgs/fenxi/legend.png',
      }
    },
    computed:{
      imageList(){
        const images=[
          this.first_url,
          this.second_url,
          this.legend_url
        ]
        return images
      }
    },
    watch: {
    // 每当 question 改变时，这个函数就会执行
    predictResultInfo(newQuestion, oldQuestion) {
      if(this.count==0){
        this.count+=1
        this.first_url=newQuestion.url[0]
      }
      else{
        this.first_url=this.second_url
      }
      this.second_url=newQuestion.url[0]
      console.log(newQuestion);
      console.log(oldQuestion);
    }
  },
    methods:{
      
      }
  }
  </script>
  
  <style scoped>
  .circle {
    border-radius: 50%;
    width: 63px;
    height: 63px;
    background: var(--color);
    /* 宽度和高度需要相等 */
  }
  .img{
    height: 300px;
    width: 400px;
    margin-left: 25%;
    margin-right: 25%;
  }
  </style>
  