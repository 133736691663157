<template>
  <!--  <el-button type="success" round @click="addEarthquakeVisible=true"  style="margin: 5px" icon="el-icon-plus">添加地震</el-button>-->
  <el-dialog
    v-model="addEarthquakeVisible"
    title="添加地震"
    width="50%"
    :modal="false"
    :lock-scroll="false"
  >
    <el-form
      ref="form"
      :model="form"
      label-width="120px"
    >
      <el-form-item label="地震名称">
        <el-input v-model="form.earthquakeName" />
      </el-form-item>
      <el-form-item label="震级">
        <el-input v-model="form.magnitude" />
      </el-form-item>
      <el-form-item label="震源经度">
        <el-input v-model="form.longitude" />
      </el-form-item>
      <el-form-item label="震源纬度">
        <el-input v-model="form.latitude" />
      </el-form-item>
      <el-form-item label="地震发生时间">
        <el-date-picker
          v-model="form.earthquakeTime"
          type="datetime"
          placeholder="选择地震发生时间"
          value-format="YYYY-MM-DD HH:mm:ss"
        />
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="onSubmit"
        >
          提交
        </el-button>
        <el-button @click="addEarthquakeVisible=false">
          取消
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import {myMessage} from "@/assets/js/utils";

export default {
  name: "AddEarthquake",
  data(){
    return {
      addEarthquakeVisible:false,
      form: {
        earthquakeName: '',
        magnitude: '',
        longitude: '',
        latitude: '',
        earthquakeTime: '',
      },
    }
  },
  methods:{
    onSubmit() {
      myMessage.elError("权限不足")
    },
  }
}
</script>

<style >
body {
    height: 100vh;
    background: linear-gradient(
            #9fc0cf,
            #e5edf1
    );
    font-family: 'Roboto', sans-serif;
}
.el-dialog{
    border-radius: 12px;
    backdrop-filter: blur(18px);
    background: rgba(230, 235, 240, 0.5); /* 增加透明度 */
    border: 2px solid rgba(230, 235, 240, 0.3); /* 同样调整边框颜色 */
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
    transition: 0.6s cubic-bezier(0.8, 0.5, 0.5, 0.8);
}
.el-dialog__title{
    color: brown;
    position: absolute;
    justify-content: center;
    font-weight: bolder;
    font-size: 1.5em;
}

.el-divider{
    background-color: skyblue;
}
.el-dialog__body{
    color: #0011ff;
    font-weight: bolder;
}


</style>
