import {ElMessage,ElNotification} from "element-plus";

export const myMessage =  {
      elSuccess(message){
      ElMessage({
        type:'success',
        message:message
      })
    },
    elError(message){
      ElMessage({
        type:'error',
        message:message
      })
    },
    elWarning(message){
        ElMessage({
            type:'warning',
            message:message
        })
    }
};
export const myNotify={
    elSuccess(message){
        ElNotification({
            type:'success',
            message:message
        })
    },
    elError(message){
        ElNotification({
            type:'error',
            message:message
        })
    },
    elWarning(message){
        ElNotification({
            type:'warning',
            message:message
        })
    }
}




// 字符串时间 2021:05:24 15:49:09 转Date
export const changeToDate=(string)=>{
const str = string;
const regex = /(\d+):(\d+):(\d+) (\d+):(\d+):(\d+)/;
let result = str.match(regex);
// console.log(result);
if (result) {
    return new Date(Number(result[1]), Number(result[2] - 1), Number(result[3]), Number(result[4]), Number(result[5]), Number(result[6]))
} else {
    console.log("无法匹配");
}
}
export const changeDateTo=(date)=> {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}:${month}:${day} ${hours}:${minutes}:${seconds}`;
}
//格式化时间
export const dateFtt=(fmt,date) =>{
    //author: meizz
    let o = {
        "M+" : date.getMonth()+1,                 //月份
        "d+" : date.getDate(),                    //日
        "h+" : date.getHours(),                   //小时
        "m+" : date.getMinutes(),                 //分
        "s+" : date.getSeconds(),                 //秒
        "q+" : Math.floor((date.getMonth()+3)/3), //季度
        "S"  : date.getMilliseconds()             //毫秒
    };
    if(/(y+)/.test(fmt))
        fmt=fmt.replace(RegExp.$1, (date.getFullYear()+"").substr(4 - RegExp.$1.length));
    for(let k in o)
        if(new RegExp("("+ k +")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length===1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
    return fmt;
}

