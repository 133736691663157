
const Cesium=()=>import('../views/cesium.vue')
import FlightPath from "../views/flight-path.vue";
import uploadData from "@/components/upload-data.vue";
import processImg from "@/components/process-img.vue";
import mainView from "@/views/main-view.vue";
import dataConvert from "@/components/data-convert.vue";

export const routes = [
    {
        path: '/',
        name: 'mainView',
        component: mainView
    },
    // {
    //     path: '/',
    //     name: 'Cesium',
    //     component: Cesium
    // },
    {
        path: '/flightPath',
        name: 'FlightPath',
        component: FlightPath
    },
    {
        path: '/uploadData',
        name: 'UploadData',
        component: uploadData
    },
    {
        path: '/processImg',
        name: 'ProcessImg',
        component: processImg
    },
    {
        path: '/revertData',
        name: 'RevertData',
        component: dataConvert
    },


]
