<template>
    <div v-if="showVisible" class="goodsbox" >
        <span>{{ pointGoods.name }}</span>
        <el-button circle icon="el-icon-circle-close" style="background-color: palegoldenrod;position: absolute;right: 2%;top: 1%"
                   @click="showVisible=false"
                   size="mini"></el-button>
        <br>
        <br>
        <el-descriptions :column="1" border>
            <el-descriptions-item>
                <template v-slot:label>
                    <i class="el-icon-burger"></i>
                    大米/面
                </template>
                {{pointGoods.item1}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template v-slot:label>
                    <i class="el-icon-hot-water"></i>
                    食用油
                </template>
                {{pointGoods.item2}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template v-slot:label>
                    <i class="el-icon-s-custom"></i>
                    衣服
                </template>
                {{pointGoods.item3}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template v-slot:label>
                    <i class="el-icon-toilet-paper"></i>
                    被子
                </template>
                {{pointGoods.item4}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template v-slot:label>
                    <i class="el-icon-reading"></i>
                    帐篷
                </template>
                {{pointGoods.item5}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template v-slot:label>
                    <i class="el-icon-c-scale-to-original"></i>
                    彩布条
                </template>
                {{pointGoods.item6}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template v-slot:label>
                    <i class="el-icon-refrigerator"></i>
                    折叠床
                </template>
                {{pointGoods.item7}}
            </el-descriptions-item>
            <el-descriptions-item>
                <template v-slot:label>
                    <i class="el-icon-orange"></i>
                    床上用品
                </template>
                {{pointGoods.item8}}
            </el-descriptions-item>
        </el-descriptions>
    </div>

</template>

<script>
export default {
    name: "goods-detailbox",
    props:{
        pointGoods:{
            type:Object,
            required:true
        }
    },
    data(){
        return{
            showVisible:false
        }
    }
}
</script>

<style scoped>
.goodsbox{
    position: absolute;
    width: 20%;
    height: 50%;
    left:15%;
    bottom: 3%;
    border-radius: 5px;
    z-index: 10;
    background-color:whitesmoke;
}

.goodsbox span{
    margin-bottom: 10%;
    position: absolute;
    top: 2%;
    left: 30%;
    font-size: medium;
    font-weight: bolder;
}
</style>