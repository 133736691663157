<template>
    <div style="height: 400px; width: 400px" id="main"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
    name: "source-item",
    props:{
        saveitem:{
            type: Object,
            required: true
        }
    },
    data() {
        return {
            sourcetemp:[],
            myChart:null,
            option: {
                tooltip: {
                    trigger: 'item', // 设置为 'item'，在鼠标悬停时触发
                    formatter: '{b}: {c}', // {b} 是类别名，{c} 是数值
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                dataset: [
                    {
                        dimensions: ['name', 'score'],
                        source: [
                            ['大米（面）', 0],
                            ['食用油', 0],
                            ['衣服', 0],
                            ['被子', 0],
                            ['帐篷', 0],
                            ['彩布条', 0],
                            ['折叠床', 0],
                            ['床上用品', 0],
                        ]
                    },
                    {
                        transform: {
                            type: 'sort',
                            config: { dimension: 'score', order: 'desc' }
                        }
                    }
                ],
                xAxis: {
                    type: 'category',
                    axisLabel: {
                        interval: 0,
                        rotate: 30,
                        color: '#0011ff', // X 轴标签字体颜色
                    }
                },
                yAxis: {
                    min: 0,
                    max: 10000,
                    axisLine: {
                        lineStyle: {
                            color: '#333', // 轴线颜色
                            width: 2       // 轴线宽度
                        }
                    },
                    axisLabel: {
                        color: '#FFA500',
                        fontSize: 14,
                        margin: 10
                    }
                },
                series: {
                    type: 'bar',
                    encode: { x: 'name', y: 'score' },
                    datasetIndex: 1,
                    itemStyle: {
                        color: '#FF7F50' // 设置柱状图的颜色为橙色
                    }
                },
                grid: {
                    left: '15%',  // 调整左侧边距，让 x 轴整体左移
                },
            },
        };
    },
    computed:{
        componw1(){return 6.206*1e-9*Math.exp(4.035*parseFloat(this.saveitem.parainten));},
        componw2(){return 4.642*1e-10*Math.exp(5.111*parseFloat(this.saveitem.parainten))  ;},
        componw3(){return 3.855*1e-8*Math.pow(parseFloat(this.saveitem.parapopu),2)-1.202*1e-2*parseFloat(this.saveitem.parapopu)+5541},
        componw4(){return -2.685*10000*Math.exp(1.117*1e-5*parseFloat(this.saveitem.paralost))+3.012*10000*Math.exp(1.123*1e-5*parseFloat(this.saveitem.paralost))},
        componw5(){return -1.204*10000*Math.exp(1.011*1e-5*parseFloat(this.saveitem.paralost))+1.492*10000*Math.exp(1.013*1e-5*parseFloat(this.saveitem.paralost))},
        componw6(){return 3.243*1e-2*Math.exp(2.374*parseFloat(this.saveitem.parainten))-3.04*1e-2*Math.exp(2.374*parseFloat(this.saveitem.parainten))},
        componw7(){return 5.86*1e-15*Math.pow(parseFloat(this.saveitem.parapopu),3)-1.208*1e-8*Math.pow(parseFloat(this.saveitem.parapopu),2)+1.309*1e-2*parseFloat(this.saveitem.parapopu)+1393},
        componw8(){return 3.347*1000*Math.pow(6.337*1e-6*this.saveitem.paraarea)+84.01*Math.pow(4.209*1e-4)}
    },
    watch:{
        saveitem: {
            handler(newVal) {
                this.sourcetemp = [
                    ['大米（面）', this.componw1], ['食用油', this.componw2/10], ['衣服', this.componw3], ['被子', this.componw4],
                    ['帐篷', this.componw5], ['彩布条', this.componw6], ['折叠床', this.componw7], ['床上用品', this.componw8],
                ];
                console.log(this.sourcetemp)
            },
            deep: true
        }

    },
    mounted() {
        this.createEcharts();
    },
    methods: {
        createEcharts() {
            let chartDom = document.getElementById('main');
            this.myChart = echarts.init(chartDom);
            this.myChart.setOption(this.option);
        },
        destoryEcharts() {
            if (this.myChart) {
                this.myChart.dispose();
                this.myChart = null;
            }
        },
        refreshfun(){
            this.option.dataset[0].source = this.sourcetemp;
            this.destoryEcharts()
            this.createEcharts()
        },
        refreshInnerfun(InnerdateList){
            console.log(InnerdateList)
            this.sourcetemp =  [['商场',InnerdateList[0]], ['影院',InnerdateList[1]], ['住宅', InnerdateList[2]], ['医院', InnerdateList[3]], ['学校', InnerdateList[4]], ['总计', InnerdateList[5]]];
            this.option.dataset[0].source = this.sourcetemp;
            this.option.yAxis.max = InnerdateList[5]*1.1
            this.destoryEcharts()
            this.createEcharts()
        }
    }
}
</script>

<style scoped>
/* 自定义样式 */
</style>
