<template>
    <div class="leftbox">
        <div ref="lottieulocation" style="width: 140px;height: 140px;position: absolute;z-index: 10;" ></div>
        <div ref="lottieudata" style="width: 100px;height: 100px;position: absolute;z-index: 10;top: 4%;left: 30%" ></div>
        <div class="titletext">
            <span>{{showtext}}</span>
            <el-button circle icon="el-icon-open" @click="changetext" @mouseenter="myNotify.elSuccess('点击切换救援展示模块！')"></el-button>
        </div>
        <div class="datashow" >
            <el-table
                v-if="showitemAndforce"
                :data="tableData"
                height="620"
                border
                style="width: 100%"
            >
                <el-table-column
                    prop="name"
                    label="单位名称"
                    width="350"
                />
                <el-table-column
                    prop="adress"
                    label="地址"
                    width="350"
                />
                <el-table-column
                    prop="location"
                    label="具体位置"
                />
            </el-table>
            <el-table
                v-else
                :data="tableData"
                height="620"
                border
                style="width: 100%"
            >
                <el-table-column
                    prop="name"
                    label="单位名称"
                    width="320"
                />
                <el-table-column
                    prop="location"
                    label="具体位置"
                    width="200"
                />
                <el-table-column
                    prop="item1"
                    label="米/面"
                    width="60"
                />
                <el-table-column
                    prop="item2"
                    label="食用油"
                    width="65"
                />
                <el-table-column
                    prop="item3"
                    label="衣服"
                    width="60"
                />
                <el-table-column
                    prop="item4"
                    label="被子"
                    width="60"
                />
                <el-table-column
                    prop="item5"
                    label="帐篷"
                    width="60"
                />
                <el-table-column
                    prop="item6"
                    label="彩条布"
                    width="65"
                />
                <el-table-column
                    prop="item7"
                    label="折叠床"
                    width="70"
                />
                <el-table-column
                    prop="item8"
                    label="床上用品"
                    width="80"
                />
            </el-table>
        </div>

    </div>
    <div class="rightbox">
        <div class="statistic">
        <sourceForce :datasouce="datasouce" :typedata="typedata"></sourceForce>
        </div>
        <div class="selectbox">
            <el-table
                :data="records"
                height="300"
                border
                style="width: 100%">
                <el-table-column
                    prop="earthquakeId"
                    label="地震编号"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="id"
                    label="文件编号"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="文件名称"
                    width="200">
                </el-table-column>
                <el-table-column
                    prop="description"
                    label="描述"
                    width="240">
                </el-table-column>
                <el-table-column
                    prop="time"
                    label="创建时间"
                    width="200">
                </el-table-column>
                <el-table-column
                    prop="size"
                    label="大小"
                    width="100">
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="100">
                    <template v-slot="scope">
                        <el-button @click="solutiondata(scope.row)" type="text" size="small">解析</el-button>
                        <el-button @click="handleDownload(scope.row)" type="text" size="small">下载</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style="position: absolute;right: 95%;bottom: 30%">
            <div>
                <el-radio v-model="radio" label="1">excel</el-radio>
            </div>
            <el-radio v-model="radio" label="2">shpfile</el-radio>
        </div>
        <div style="position: absolute;right: 6%;bottom: 38%">
            <span>地震编号：</span>
            <el-input size="mini" style="width: 60px;margin: 10px" v-model="searchEarthquakeId"></el-input>
            <el-button icon="el-icon-search" circle @click="refreshcosfile('xlsx')">
            </el-button>
        </div>
    </div>




</template>

<script>
import * as XLSX from 'xlsx';
import lottie from "lottie-web";
import sourceForce from "@/components/echartsgraph/source-force.vue";
import {getFileDownloadUrl, searchFileBypagesize, shpeFileconvertgeojson} from "@/axios/api/others-api";
import {myNotify} from "@/assets/js/utils";

export default {
    name: "data-convert",
    components:{
        sourceForce,
    },
    data() {
        return {
            showitemAndforce:true,
            tableData: [],
            searchEarthquakeId:1,
            showtext:"救援力量数据解析",
            flagtemp:false,
            animationdatapath:require('@/assets/json/data.json'),
            animationlocationpath:require('@/assets/json/location.json'),
            loop:true,
            autoplay:true,
            records:null,
            radio:"1",
            typedata:1,
            datasouce:null,
        };
    },
    computed:{
        myNotify() {
            return myNotify
        },
        datalistitem(){
            let array = this.tableData
            let item1 = 0;let item2 = 0;let item3 = 0;let item4 = 0;let item5 = 0;let item6 = 0;let item7 =0;let item8 = 0;
            for (let i = 0;i<array.length;i++){
                item1+=array[i].item1
                item2+=array[i].item2
                item3+=array[i].item3
                item4+=array[i].item4
                item5+=array[i].item5
                item6+=array[i].item6
                item7+=array[i].item7
                item8+=array[i].item8
            }

            return [item1,item2,item3,item4,item5,item6,item7,item8]

        },
        datalist(){
            let firecompany = 0;
            let firestation = 0;
            let savecompany = 0;
            let savestation = 0;
            let othersaveplace = 0;

            let array = this.tableData

            for (let i = 0;i<array.length;i++){
                let name = array[i].name;
                othersaveplace++
                if (name.includes("消防")){
                    firecompany++
                }
                if (name.includes("消防站")){
                    firestation++
                }
                if (name.includes("救援")){
                    savecompany++
                }
                if (name.includes("救援站")){
                    savestation++
                }
            }
            let data = []
            data[0] = firecompany-firestation-savecompany;
            data[1] = firestation;
            data[2] = savecompany-savestation
            data[3] = savestation
            data[4] =othersaveplace -data[0]-data[1] - data[2] - data[3]
            return data
        }
    },
    mounted() {
        this.loadXlsxFile('/消防大队yunnan.xlsx')
        lottie.loadAnimation({
            container: this.$refs.lottieudata,
            renderer: 'svg',
            loop: this.loop,
            autoplay: this.autoplay,
            animationData: this.animationdatapath
        });

        lottie.loadAnimation({
            container: this.$refs.lottieulocation,
            renderer: 'svg',
            loop: this.loop,
            autoplay: this.autoplay,
            animationData: this.animationlocationpath
        });
        this.refreshcosfile('xlsx')

    },
    watch:{
        radio(newVal){
            if (newVal === '1'){
                this.refreshcosfile('xlsx')
            }else if (newVal === '2'){
                this.refreshcosfile('zip')
            }
        }
    },
    methods: {
        solutiondata(row){
            myNotify.elWarning('正在解析~请稍后...')
            getFileDownloadUrl(row.url).then(res=>{
                if (this.radio === '2'){
                    this.$globalState.showGeoJson= true
                    this.loadShpfile(res)
                }else {
                    this.loadXlsxFile(res)
                }
                myNotify.elSuccess('解析成功！')
            })
        },
        handleDownload(row){
            myNotify.elWarning("正在拉取文件，请稍后....")
            getFileDownloadUrl(row.url).then(res=>{
                const link = document.createElement('a');
                link.href = res;
                link.click();
                myNotify.elSuccess("拉取成功！")
            })
        },
        changetext(){
            if (this.flagtemp){
                this.showtext = "救援力量数据解析"
                this.typedata = 1
            }else{
                this.showtext = "救援物资数据解析"
                this.typedata = 2
            }
            this.flagtemp = !this.flagtemp
            this.showitemAndforce=!this.showitemAndforce
        },
        async loadShpfile(url) {
            let that = this
            try {
                // 使用 fetch API 下载 .zip 文件
                const response = await fetch(url);
                // 获取 .zip 文件的 Blob 对象
                const blob = await response.blob();

                // 使用 Blob 对象创建一个 File 对象
                // 你可以根据需要修改 fileName 和 mimeType
                const fileName = 'solutionfile.zip'; // 指定文件名
                const mimeType = 'application/zip'; // 指定 MIME 类型
                const file = new File([blob], fileName, { type: mimeType });

                // 现在你可以使用 file 对象了
                console.log('File object created:', file);
                const formData = new FormData();
                formData.append('file', file);
                shpeFileconvertgeojson(formData).then(res=>{
                })

                this.$router.push({ path: '/' });

                return file;
            } catch (error) {
                console.error('Error loading SHP file:', error);
            }
        },
        async loadXlsxFile(url) {
            try {
                // 通过相对路径加载文件
                const response = await fetch(url);
                const data = await response.arrayBuffer();

                // 解析数据
                const workbook = XLSX.read(data, { type: 'array' });
                const firstSheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[firstSheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet);

                // 设置表格数据
                this.tableData = jsonData
                if (this.typedata === 1){
                    this.datasouce =this.datalist
                }else (
                    this.datasouce = this.datalistitem
                )
            } catch (error) {
                console.error("Error loading XLSX file:", error);
            }

        },
        refreshcosfile(type){
            const that = this
            let params = {
                "earthquakeId":parseInt(this.searchEarthquakeId),
                "suffix":type,
                "page":1,
                "size":4,
                "maxSize":1000000,
                "minSize":0
            }
            searchFileBypagesize(params).then(res=>{
                // console.log("search result:")
                // console.log(res.records)
                that.records = res.records
            })
        }

    }
}
</script>

<style scoped>



.leftbox {
    position: absolute;
    left: 0;

    height: 100%;
    width: 60%;
}

.rightbox {
    position: absolute;
    right: 0;

    height: 100%;
    width: 40%;
}

.leftbox .titletext{
    position: absolute;
    top: 8%;
    font-size: 1.8em;
    font-weight: bolder;
    left: 40%;
}
.leftbox .el-button{
    position: absolute;
    right: -170%;
}

.datashow {
    position: absolute;
    top: 14%;
    left: 4%;
    width: 90%;
    height: 80%;
    background-color: #fff; /* 背景色，确保阴影效果明显 */
    padding: 10px; /* 内边距 */
    border-radius: 10px; /* 圆角 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 阴影 */


}

.rightbox .statistic{
    background-color: white;
    border-radius: 6px; /* 圆角 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 阴影 */
    position: absolute;
    width: 80%;
    height: 50%;
    top: 6%;
    right: 12%;
}

.rightbox .selectbox{
    position: absolute;
    bottom: 2%;
    right: 12%;
    width: 80%;
    height: 36%;


}


</style>
