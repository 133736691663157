<template>
    <el-dialog v-model="showimgPara" title="快速评估烈度图参数配置"  >
        <div>
            <el-form label-position="left" label-width="80px" :model="imgParaForm" :rules="imgrules">
                <el-form-item label="缩放级别" prop="zoom" >
                    <el-input v-model="imgParaForm.zoom" style="width: 10vh"></el-input>
                </el-form-item>
                <el-form-item label="图片尺寸" prop="imgsize">
                    <el-input v-model="imgParaForm.imgsize" style="width: 15vh"></el-input>
                </el-form-item>
                <el-form-item label="高清图">
                    <el-radio v-model="imgParaForm.scale" label="2" border size="medium" @click="notifyfun" style="font-weight: bolder">open</el-radio>
                    <el-radio v-model="imgParaForm.scale" label="1" border size="medium" style="font-weight: bolder">close</el-radio>
                </el-form-item>
                <el-form-item label="实时交通">
                    <el-radio v-model="imgParaForm.traffic" label="1" border size="medium" style="font-weight: bolder">open</el-radio>
                    <el-radio v-model="imgParaForm.traffic" label="0" border size="medium" style="font-weight: bolder">close</el-radio>
                </el-form-item>
            </el-form>
        </div>
        <el-button round style="background-color: #d3dce6;color: #333333" @click="showimgPara = false">取消</el-button>
        <el-button round style="background-color: coral;color: #333333" @click="produceimg">生成</el-button>
    </el-dialog>
</template>

<script>
import {imgPara} from "@/assets/js/elform-verify/validate";
import {myMessage, myNotify} from "@/assets/js/utils";
export default {
    name: "quickpreview-intentityimg",
    computed: {
        myNotify() {
            return myNotify
        }
    },
    data(){
        return{
            showimgPara:false,
            imgParaForm:{
                zoom:"8",
                scale:"1",
                traffic:"0",
                imgsize:"720*560"
            },
            imgrules:imgPara
        }
    },
    methods:{
        produceimg(){
            myMessage.elSuccess("正在生成中..... 请稍等~~")
            this.$emit('submitimgparams',this.imgParaForm,true)
            // intentityImg(this.earthquakePara).then(res=>{
            // })
            this.showimgPara = false
        },
        notifyfun(){
            myNotify.elWarning('开启高清图会使缩放级别和图片尺寸大小自动增加一倍！')
        }
    }
}
</script>

<style scoped>

</style>