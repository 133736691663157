<template>
    <el-divider></el-divider>
    <span class="spanText" >室内人口模型评估：</span>
    <br>
    <div style="display: flex">
        <div class="leftbox">
            <sourceItem ref="sourceitem"></sourceItem>
        </div>
        <div class="rightbox">
            <span style="margin-left: 10%">时间：</span>
            <span>
                    <el-radio v-model="radio2" :label = 1 border size="small" class="timebutton">工作日</el-radio>
                    <el-radio v-model="radio2" :label = 2 border size="small" class="timebutton">节假日</el-radio>
            </span>
            <span style="margin-left: 3%" >时间段：</span>
            <span >
                        <el-select v-model="timeline" filterable placeholder="请选择时间段" size="small" style="width: 30%" >
                        <el-option
                            v-for="item in timeOption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                      </el-select>
            </span>
            <div class="linebox">
                <div>
                    <span style="margin-top: 1.5%;font-size: 1.2em;margin-right: 2%">商场</span>
                    <el-form :model="shopPara" :rules="rulers" class="custom-form" >
                        <el-form-item label="规模：" prop="area" class="form-item" >
                            <el-input size="small" v-model="shopPara.area" placeholder="km^2"></el-input>
                        </el-form-item>
                        <el-form-item label="数量：" prop="count" class="form-item" >
                            <el-input size="small" v-model="shopPara.count" placeholder="数量"></el-input>
                        </el-form-item>
                        <el-form-item label="人口密度：" prop="density" class="form-item" >
                            <el-input size="small" v-model="shopPara.density"></el-input>
                        </el-form-item>
                    </el-form>
                </div>

                <div>
                    <span style="margin-top: 1.5%;font-size: 1.2em; margin-right: 2%">影院</span>
                    <el-form :model="shopPara"  class="custom-form" :rules="rulers" >
                        <el-form-item label="规模："  class="form-item"  prop="area" >
                            <el-input size="small" v-model="moviePara.area" placeholder="km^2"></el-input>
                        </el-form-item>
                        <el-form-item label="数量："  class="form-item"   prop="count">
                            <el-input size="small" v-model="moviePara.count" placeholder="数量"></el-input>
                        </el-form-item>
                        <el-form-item label="人口密度："  class="form-item"   prop="density">
                            <el-input size="small" v-model="moviePara.density"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div>
                    <span style="margin-top: 1.5%;font-size: 1.2em; margin-right: 2%">住宅</span>
                    <el-form :model="shopPara"  class="custom-form" :rules="rulers" >
                        <el-form-item label="规模："  class="form-item" prop="area" style="flex: 1; margin-right: 10px;" >
                            <el-input size="small"  v-model="housePara.area" placeholder="km^2"></el-input>
                        </el-form-item>
                        <el-form-item label="数量：" class="form-item"  prop="count" style="flex: 1; margin-right: 10px;">
                            <el-input size="small"  v-model="housePara.count" placeholder="数量"></el-input>
                        </el-form-item>
                        <el-form-item label="人口密度：" class="form-item" prop="density" style="flex: 1">
                            <el-input size="small" v-model="housePara.density"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div>
                    <span style="margin-top: 1.5%;font-size: 1.2em;margin-right: 2%">医院</span>
                    <el-form :model="shopPara"  class="custom-form" :rules="rulers">
                        <el-form-item label="规模：" class="form-item"  prop="area" style="flex: 1; margin-right: 10px;">
                            <el-input size="small"  v-model="hospitalPara.area" placeholder="km^2"></el-input>
                        </el-form-item>
                        <el-form-item label="数量：" class="form-item" prop="count" style="flex: 1; margin-right: 10px;">
                            <el-input size="small"  v-model="hospitalPara.count" placeholder="数量"></el-input>
                        </el-form-item>
<!--                        <el-form-item label="级别：" prop="type" style="flex: 1; margin-right: 10px;">-->
<!--                            <el-select v-model="hospitalPara.type" clearable placeholder="医院级别" size="small">-->
<!--                                <el-option-->
<!--                                    v-for="item in hospitals"-->
<!--                                    :key="item.value"-->
<!--                                    :label="item.label"-->
<!--                                    :value="item.value">-->
<!--                                </el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
                        <el-form-item label="人口密度：" class="form-item"  prop="density" style="flex: 1; margin-right: 10px;">
                            <el-input size="small"  v-model="hospitalPara.density"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div>
                    <span style="margin-top: 1.5%;font-size: 1.2em;">学校</span>
                    <el-form :model="shopPara" class="custom-form" :rules="rulers">
                        <el-form-item label="规模：" class="form-item" prop="area">
                            <el-input size="small"  v-model="schoolPara.area" placeholder="km^2"></el-input>
                        </el-form-item>
                        <el-form-item label="数量：" class="form-item" prop="count">
                            <el-input size="small"  v-model="schoolPara.count" placeholder="数量"></el-input>
                        </el-form-item>
<!--                        <el-form-item label="类型：" style="width:71%" class="form-item" prop="type">-->
<!--                            <el-select v-model="schoolPara.type" clearable placeholder="学校类型" size="small">-->
<!--                                <el-option-->
<!--                                    v-for="item in schools"-->
<!--                                    :key="item.value"-->
<!--                                    :label="item.label"-->
<!--                                    :value="item.value">-->
<!--                                </el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
                        <el-form-item label="人口密度：" class="form-item" prop="density">
                            <el-input size="small" v-model="schoolPara.density"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div>
                <span style="margin-top: 1.5%;font-size: 1.2em;margin-left: 2%">医院级别：</span>
                <el-select v-model="hospitalPara.type" clearable placeholder="医院级别" size="small" style="width: 25%">
                    <el-option
                        v-for="item in hospitals"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                <span style="margin-top: 1.5%;font-size: 1.2em;margin-left: 2%">学校级别：</span>
                    <el-select v-model="schoolPara.type" clearable placeholder="学校类型" size="small" style="width: 25%" >
                        <el-option
                            v-for="item in schools"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                <el-button style="margin-top: 2%;margin-left: 60%;background-color: papayawhip;" round @click="computeden">评估人口密度</el-button>
                <el-button circle icon="el-icon-cpu" style="position: absolute; margin-top: 1%;background-color: papayawhip;" @click="resultfun"></el-button>
            </div>
        </div>
    </div>
</template>

<script>
import sourceItem from "@/components/echartsgraph/source-item.vue";
import {innerform} from "@/assets/js/elform-verify/validate";
export default {
    name: "indoor-estimate",
    components: {sourceItem},
    data(){
        return{
            radio2:1,
            timeline:"",
            timeOption:[{value:0, label:"23:00--06:00"}, {value:1, label:"06:00--08:00"}, {value:2, label:"08:00--10:00"}, {value:3, label:"10:00--12:00"}, {value:4, label:"12:00--14:00"}, {value:5, label:"14:00--16:00"}, {value:6, label:"16:00--18:00"}, {
                    value:7, label:"18:00--20:00"}, {value:8, label:"20:00--23:00"},],
            shopPara:{area:"", density:"", count:"",},
            moviePara:{area:"", density:"", count:"",},
            housePara:{area:"", density:"", count:"",},
            hospitalPara:{area:"", density:"", count:"", type:"",},
            schoolPara:{area:"", density:"", count:"", type:"",},
            hospitals:[{value:2, label:'二级甲等综合'}, {value:3, label:'三级甲等综合'},],
            schools:[{value:1, label:'中学'}, {value:2, label:'小学'},],
            rulers:innerform,
            marketPara1:0.0760,
            marketPara2:0.0360,
            resultInnerPerson:[]
        }
    },
    computed:{
        denschool1(){if(this.timeline === 2 || this.timeline === 3 || this.timeline === 5||this.timeline === 6){
            return 0.253} else {return 0.00}
        },
        denschool2(){if(this.timeline === 2 || this.timeline === 3 || this.timeline === 5||this.timeline === 6){return 0.206}else if(this.timeline === 1 || this.timeline === 7){return 0.206*0.5}
        else {return 0.00}
        },

        denhospital(){
            if (this.timeline === 0){return 0.00}else if(this.timeline === 1){if(this.radio2 === 1){return 0.00}
            else if(this.radio2 === 2){return 0.00}}
            else if(this.timeline === 2){if(this.radio2 === 1){return 0.159}
            else if(this.radio2 === 2){return 0.179}}
            else if(this.timeline === 3){if(this.radio2 === 1){return 0.159}
            else if(this.radio2 === 2){return 0.179}}
            else if(this.timeline === 4){if(this.radio2 === 1){return 0.159*0.5}
            else if(this.radio2 === 2){return 0.179*0.5}}
            else if(this.timeline === 5){if(this.radio2 === 1){return 0.159}
            else if(this.radio2 === 2){return 0.179}}
            else if(this.timeline === 6){if(this.radio2 === 1){return 0.179*0.8*0.888}
            else if(this.radio2 === 2){return 0.179*0.8}}
            else if(this.timeline === 7){if(this.radio2 === 1){return 0.179*0.4*0.888}
            else if(this.radio2 === 2){return 0.179*0.4}}
            else if(this.timeline === 8){if(this.radio2 === 1){return 0.179*0.2*0.888}
            else if(this.radio2 === 2){return 0.179*0.2}}
            return 0
        },
        denhouse(){
            if(this.timeline === 4 || this.timeline === 5){return 0.045}else if(this.timeline === 2 ){return 0.018
            }else {return 0.032}
        },
        denMovie(){
            if (this.timeline === 0){
                return 0.00
            }else if(this.timeline === 1){
                if(this.radio2 === 1){
                    return 0.00
                }
                else if(this.radio2 === 2){
                    return 0.00
                }
            }
            else if(this.timeline === 2){
                if(this.radio2 === 1){
                    return 0.02719*0.1*0.0578
                }
                else if(this.radio2 === 2){
                    return 0.02719*0.1
                }
            }
            else if(this.timeline === 3){
                if(this.radio2 === 1){
                    return 0.02719*0.2*0.0578
                }
                else if(this.radio2 === 2){
                    return 0.02719*0.2
                }
            }
            else if(this.timeline === 4){
                if(this.radio2 === 1){
                    return 0.02719*0.5*0.0578
                }
                else if(this.radio2 === 2){
                    return 0.02719*0.5
                }
            }
            else if(this.timeline === 5){
                if(this.radio2 === 1){
                    return 0.00157
                }
                else if(this.radio2 === 2){
                    return 0.02719
                }
            }
            else if(this.timeline === 6){
                if(this.radio2 === 1){
                    return 0.02719*0.9*0.0578
                }
                else if(this.radio2 === 2){
                    return 0.02719*0.9
                }
            }
            else if(this.timeline === 7){
                if(this.radio2 === 1){
                    return 0.02719*0.95*0.0578
                }
                else if(this.radio2 === 2){
                    return 0.02719*0.95
                }
            }
            else if(this.timeline === 8){
                if(this.radio2 === 1){
                    return 0.02719*0.9*0.0578
                }
                else if(this.radio2 === 2){
                    return 0.02719*0.9
                }
            }
            return 0
        },
    },
    methods:{
        resultfun(){
            // console.log(this.ParaAll)
            let resultdatalist = [];
            let paraAll = [this.shopPara,this.moviePara,this.housePara,this.hospitalPara,this.schoolPara];
            let sum = 0
            for (let i = 0;i < 5;i++){
                resultdatalist[i] =Math.ceil( parseFloat(paraAll[i].area)*parseFloat(paraAll[i].count)*paraAll[i].density)
                sum+=resultdatalist[i]
            }
            resultdatalist[5] = sum
            this.resultInnerPerson = resultdatalist
            this.$refs.sourceitem.refreshInnerfun(this.resultInnerPerson)
        },
        computeden(){
            this.shopPara.density = this.retDensty(this.marketPara1,this.marketPara2)
            this.moviePara.density = this.denMovie
            this.housePara.density = this.denhouse
            if(this.hospitalPara.type === 2){
                this.hospitalPara.density = this.denhospital
            }else if(this.hospitalPara.type === 3){
                this.hospitalPara.density = this.denhospital*1.1
            }
            if(this.schoolPara.type === 1){
                this.schoolPara.density = this.denschool2
            }else if(this.schoolPara.type === 2){
                this.schoolPara.density = this.denschool1
            }
        },
        retDensty(para1,para2){
            if (this.timeline === 0){
                return 0.00
            }else if(this.timeline === 1){
                if(this.radio2 === 1){
                    return para1*0.1*0.4808
                } else if(this.radio2 === 2){
                    return para1*0.1
                }
            }
            else if(this.timeline === 2){
                if(this.radio2 === 1){
                    return para1*0.2*0.4808
                }
                else if(this.radio2 === 2){
                    return para1*0.2
                }
            }
            else if(this.timeline === 3){
                if(this.radio2 === 1){
                    return para1*0.4*0.4808
                }
                else if(this.radio2 === 2){
                    return para1*0.4
                }
            }
            else if(this.timeline === 4){
                if(this.radio2 === 1){
                    return para1*0.8*0.4808
                }
                else if(this.radio2 === 2){
                    return para1*0.8
                }
            }
            else if(this.timeline === 5){
                if(this.radio2 === 1){
                    return para2
                }
                else if(this.radio2 === 2){
                    return para1
                }
            }
            else if(this.timeline === 6){
                if(this.radio2 === 1){
                    return para2
                }
                else if(this.radio2 === 2){
                    return para1
                }
            }
            else if(this.timeline === 7){
                if(this.radio2 === 1){
                    return para1*0.8*0.4808
                }
                else if(this.radio2 === 2){
                    return para1*0.8
                }
            }
            else if(this.timeline === 8){
                if(this.radio2 === 1){
                    return para1*0.4*0.4808
                }
                else if(this.radio2 === 2){
                    return para1*0.4
                }
            }
            return 0
        },
    },
}
</script>

<style scoped>
.spanText{
    font-size: 1.5em;
    font-weight: bold;
}

.leftbox{
    height: 50vh;
    width: 50vh;
    float: left;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    /*border: 2px solid lightblue;*/
    margin-top: 4%;
    margin-left: 3%;
}
.rightbox{
    width: 100%;
}
.timebutton{
   font-weight: bolder;
    background-color:lightyellow
}
.linebox{
    margin-left: 4%;
    margin-top: 2%;

}

.custom-form {
    /* 可选：定义整个表单的 margin 和 padding */
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
}

.custom-form .el-form-item {
    /* 去除 form-item 默认的 margin 和 padding */
    margin: 0;
    margin-bottom: 2%;
    padding: 0;
    flex: 1;
    margin-right: 10px;
}

.custom-form .el-form-item__content {
    /* 去除 form-item 内容区域的 margin 和 padding */
    margin: 0;
    padding: 0;

}

.custom-form .el-form-item__label {
    /* 去除 label 的 margin 和 padding */
    margin: 0;
    padding: 0;
}
.form-itemden{
    position: relative;
    left: -18%;
}

.form-itemden .el-input{
    width: 135%;
}
</style>