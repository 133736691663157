

//自定义校验规则封装


//自定义校验浮点数
let validateFloat = (rule, value, callback)=>{
    if (value === '') {
        callback(new Error('请输入1到9的整数或浮点数'));
    } else if (!/^[1-9](\.\d+)?$/.test(value)) {
        callback(new Error('请输入合法的数值（1到9的整数或浮点数）'));
    } else {
        callback();
    }
};
//自定义校验震源深度
let validatedepth = (rule,value,callback)=>{
    if(!/^(100(\.0+)?|[1-9]?\d(\.\d+)?)$/.test(value)){
        callback(new Error('请输入合理的震源深度,且为为浮点数'));
    }else {
        callback();
    }
};
let validlongitude = (rule, value, callback) => {
    // 检查是否为数字，并且在 -180 到 180 之间
    if (!/^(-?([1][0-7][0-9]|[1-9]?[0-9])(\.[0-9]+)?|180(\.0+)?)$/.test(value)) {
        callback(new Error('请输入合理的经度，范围在 -180 到 180 之间'));
    } else {
        callback();
    }
};
let validlatitude=(rule,value,callback)=>{
    if(!/^(-90(\.0+)?|-(8[0-9]|[0-7][0-9])(\.\d+)?|0(\.\d+)?|([0-8][0-9]|90)(\.\d+)?)$/
        .test(value)){
        callback(new Error('请输入合理的纬度,且为为浮点数'));
    }else {
        callback();
    }
};

//校验imgsize的值
let valiimgsize = (rule, value, callback)=>{
    if (value === ''){
        callback(new Error('请输入图片尺寸（格式如：宽度*高度）'))
    }else if(!/^([1-9][0-9]{0,3}|1024)\*([1-9][0-9]{0,3}|1024)$/.test(value)){
        callback(new Error('请输入有效的图片尺寸，宽度和高度范围在0到1024之间'))
    }else {
        const [width, height] = value.split('*').map(Number);
        if (width < 0 || width > 1024 || height < 0 || height > 1024) {
            callback(new Error('宽度和高度都必须在0到1024之间'));
        } else {
            callback();
        }
    }
};
//校验zoom的值
let valizoom = (rule, value, callback)=>{
    if (value === ''){
        callback(new Error('请输入一个缩放级别4~9以内整数'))
    }else if(!/^(?:[4-9])$/.test(value)){
        callback(new Error('请输入4~9以内整数!'))
    }else {
        callback();
    }
};

let validateIntid = (rule, value, callback)=> {
    if (value === '') {
        callback(new Error('请输入1到9999之间的整数编号'));
    } else if (!/^(?:[1-9][0-9]{0,3})$/.test(value)) {
        callback(new Error('请输入合法的1到9999之间的整数编号'));
    } else {
        callback();
    }
};

//自定义校验输入文件最大值是多少 最大不超过30M
let validateSize = (rule, value, callback) => {
    if (value === '') {
        callback(new Error('请输入介于1和31,457,280之间的数字'));
    } else if (!/^([1-9]|[1-9][0-9]{1,6}|[1-2][0-9]{7}|3[0-1][0-3][0-9]{5}|3145[0-6][0-9]{3}|31457[0-1][0-9]{2}|314572[0-7][0-9]|31457280)$/.test(value)) {
        callback(new Error('请输入合法的1到31,457,280之间的整数'));
    } else {
        callback();
    }
};

let valimodifysize = (rule, value, callback) =>{
    if (value === '') {
        callback(new Error('请输入图片尺寸（格式如：宽度*高度）'))
    } else if (!/^([1-9][0-9]{0,3}|1024)\*([1-9][0-9]{0,3}|1024)$/.test(value)) {
        callback(new Error('请输入有效的图片尺寸，宽度和高度范围在0到1024之间'))
    } else {
        const [width, height] = value.split('*').map(Number);
        if (width < 0 || width > 1024 || height < 0 || height > 1024) {
            callback(new Error('宽度和高度都必须在0到1024之间'));
        } else {
            callback();
        }
    }
};
let validatexsigma = (rule, value, callback)=>{
    if (value === ''){
        callback(new Error("请输入xsigma值，在0到30之间"))
    }else if (value<0||value>30){
        callback(new Error("输入的值太大或太小了"))
    }else if (isNaN(value)) {
        callback(new Error('请输入数字！'));
    }else {
        callback();
    }
};

let validatedsigma=(rule, value, callback)=>{
    if (value === ''){
        callback(new Error("请输入xsigma值，在10到150之间"))
    }else if (value<10||value>150){
        callback(new Error("输入的值太大或太小了"))
    }else if (isNaN(value)) {
        callback(new Error('请输入数字！'));
    }else {
        callback();
    }
};

let validatepercentage=(rule, value, callback)=>{
    if (value === '') {
        callback(new Error("请输入0~1之间的百分比率"));
    } else if (isNaN(value)) {
        callback(new Error('请输入数字！'));
    } else if (value < 0 || value > 1) {
        callback(new Error("请输入0~1之间的小数"));
    } else {
        callback();
    }
};

let validatepersonNum=(rule, value, callback)=>{
    if (value === '') {
        callback(new Error("请输入人数，注意是整数！"));
    } else if (!Number.isInteger(Number(value))) { // 检查是否为整数
        callback(new Error('请输入整数！'));
    } else if (value < 0) { // 根据需要检查负值情况
        callback(new Error("人数不能为负数！"));
    } else {
        callback();
    }
};
let validateNumber=(rule, value, callback)=>{
    if (value === '') {
        callback(new Error("请输入数量，注意是整数！"));
    } else if (!Number.isInteger(Number(value))) { // 检查是否为整数
        callback(new Error('请输入整数！'));
    } else if (value < 0) { // 根据需要检查负值情况
        callback(new Error("数量不能为负数！"));
    } else {
        callback();
    }
};


let validatefloat = (rule, value, callback) => {
        if (value === '') {
            callback(new Error("请输入整数或浮点数"));
        }
        else if (isNaN(Number(value))) {
            callback(new Error('请输入整数或浮点数'));
        }
        else if (Number(value) < 0) {
            callback(new Error("请输入整数或浮点数（非负数）！"));
        }
        else {
            callback();
        }
};



export {
    validateFloat,
    validatedepth,
    validlongitude,
    validlatitude,
    valiimgsize,
    valizoom,
    validateIntid,
    validateSize,
    valimodifysize,
    validatexsigma,
    validatedsigma,
    validatepercentage,
    validatepersonNum,
    validateNumber,
    validatefloat
}