<template>
<div id="main" style="width: 100%;height: 100%"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
    name: "source-force",
    props:{
      datasouce:{
          type:Array,
          required:true
      },
        typedata:{
            type:Number,
            required: true
        }
    },
    data(){
        return{
            myChart:null,
            option:{
                title: {
                    text: '救援力量统计'
                },
                tooltip: {
                    trigger: 'item', // 设置为 'item'，在鼠标悬停时触发
                    formatter: '{b}: {c}', // {b} 是类别，{c} 是具体值
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    data: ['消防队', '消防站', '救援队', '救援站', '救助类服务站']
                },
                series: [
                    {
                        name: '救援力量统计',
                        type: 'bar',
                        data: [18203, 23489, 29034, 104970, 131744]
                    }
                ]
            },
        }
    },
    watch:{
        datasouce(newVal){
          if (this.typedata === 2){
              this.option.yAxis.data=['米/面','食用油','衣服','被子','帐篷','彩布条','折叠床','床上用品']
              this.option.series[0].name = "救援物资统计"
          }
            this.option.series[0].data = newVal
            this.destoryEcharts()
            this.createEcharts()
      }
    },
    mounted() {
        this.createEcharts()
    },
    methods:{
        createEcharts() {
            let chartDom = document.getElementById('main');
            this.myChart = echarts.init(chartDom);
            this.myChart.setOption(this.option);
        },
        destoryEcharts() {
            if (this.myChart) {
                this.myChart.dispose();
                this.myChart = null;
            }
        },
    }
}
</script>

<style scoped>

</style>