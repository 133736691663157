<template>
    <div class="container">
        <div class="chart2" id="chart2"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";

export default {
    name: "inner-persontwo",
    props:{
        affectpersonnum:{
            type:Number,
            default:0
        }
    },
    data(){
      return{
          myChart:null,
          xData :["0:00", "2:00", "4:00", "6:00", "8:00",
              "12:00", "16:00", "20:00", "24:00",],
          yData :[111, 600, 255, 344, 333, 555, 111, 999, 0],

      }
    },
    computed:{
      innerPersonList(){
          let num = this.affectpersonnum
          return [num*0.95,num,num*0.98,num*0.8,num*0.3,num*0.5,num*0.7,num*0.85,num*0.9]
      }
    },
    mounted() {
      this.createEcharts()
    },
    watch:{
        affectpersonnum(newVal){
            this.destoryEcharts()
            this.createEcharts()
        }
    },
    methods:{
        createEcharts() {
            let chartDom = document.getElementById('chart2');
            this.myChart = echarts.init(chartDom);
            this.yData = this.innerPersonList
            this.renderChart(this.myChart)

        },
        destoryEcharts() {
            if (this.myChart) {
                this.myChart.dispose();
                this.myChart = null;
            }
        },
        renderChart(myChart){
            let datacoords = [
                {
                    coords: [],
                },
            ];
            for (let i = 0; i < this.xData.length; i++) {
                datacoords[0].coords.push([this.xData[i], this.yData[i]]);
            }
            const options = {
                animationDuration: 3000,
                color: ["#ec5d5f", "#f2cb58", "#64a0c8"],
                tooltip: {
                    trigger: "axis",
                    backgroundColor: "rgba(0,0,0,.5)",
                    axisPointer: {
                        type: "cross",
                        label: {
                            backgroundColor: "rgba(0,0,0,.5)",
                        },
                    },
                    textStyle: {
                        color: "#fff",
                        fontSize: 14,
                    },
                },
                grid: {
                    left: "0%", //图表距边框的距离
                    right: "5%",
                    top: "13%",
                    bottom: "2%",
                    containLabel: true,
                },
                xAxis: [
                    {
                        nameGap: 3, //坐标轴名称与轴线之间的距离。
                        nameTextStyle: {
                            color: "rgba(255,255,255,.8)",
                            fontSize: 12,
                        },
                        type: "category",
                        data: this.xData,
                        boundaryGap: false, //从0开始
                        axisLine: {
                            //坐标轴线颜色
                            rotate: 30, //坐标轴内容过长旋转
                            interval: 0,
                            lineStyle: {
                                color: "#636E7C",
                            },
                        },
                        axisLabel: {
                            showMaxLabel: true,
                            fontSize: 12,
                            color: "rgba(255,255,255,.8)", //坐标的字体颜色
                        },
                        axisTick: {
                            //是否显示坐标轴刻度  x和y不交叉
                            show: false,
                        },
                    },
                ],
                yAxis: [
                    {
                        name: "室内人口",
                        type: "value",
                        nameGap: 20,
                        min: 0,
                        max: function (value) {
                            return Math.ceil(value.max / 5) * 5;
                        },
                        splitNumber: 5,
                        nameTextStyle: {
                            //坐标轴字体
                            color: "rgba(255,255,255,.89)",
                            fontSize: 12,
                        },
                        splitLine: {
                            //网格线颜色
                            show: true,
                            lineStyle: {
                                color: "rgba(255,255,255,.25)",
                                type: "dashed",
                            },
                        },
                        axisTick: {
                            //是否显示坐标轴刻度
                            show: false,
                        },
                        axisLine: {
                            //坐标轴线颜色
                            show: true,
                            lineStyle: {
                                color: "#636E7C",
                            },
                        },
                        axisLabel: {
                            color: "rgba(255,255,255,.8)", //坐标的字体颜色
                            fontSize: 12,
                        },
                    },
                ],
                series: [
                    {
                        name: "",
                        type: "line",
                        smooth: 0, //可设置弧度，0-1
                        lineStyle: {
                            width: 1.5,
                            type: "solid",
                            shadowOffsetX: 0, // 折线的X偏移
                            shadowOffsetY: 3, // 折线的Y偏移
                            shadowBlur: 4, // 折线模糊
                            shadowColor: "rgba(220,120,40,0.95)", //阴影色
                            color: "#DC7828", //折线颜色
                        },
                        showSymbol: true,
                        symbol: "circle",
                        symbolSize: 8,
                        itemStyle: {
                            color: "#2f334d",
                            borderWidth: 1,
                            borderColor: "#DC7828",
                        },
                        areaStyle: {
                            // 颜色渐变函数 前四个参数分别表示四个位置依次为---右下左上
                            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                {
                                    offset: 1,
                                    color: "rgba(220,120,40,0.3)",
                                },
                                {
                                    offset: 0.74,
                                    color: "rgba(220,120,40,0.26)",
                                },
                                {
                                    offset: 0,
                                    color: "rgba(220,120,40,0)",
                                },
                            ]),
                        },
                        emphasis: {
                            focus: "series",
                        },
                        data: this.yData,
                    },
                    // 以下代码为流光效果-配置项代码
                    {
                        polyline: true,
                        showSymbol: false,
                        name: "流动光线",
                        type: "lines",
                        coordinateSystem: "cartesian2d",
                        effect: {
                            trailLength: 0.3,
                            show: true,
                            period: 6,
                            symbolSize: 4,
                            loop: true,
                        },
                        lineStyle: {
                            color: "#fff",
                            width: 0,
                            opacity: 0,
                            curveness: 0,
                            type: "dashed",
                        },
                        data: datacoords,
                    },
                ],
            };
            if (options && typeof options === "object") {
                myChart.setOption(options);
            }
        },
    }
}
</script>

<style scoped>
body {
    background: #343956;
}

.container {
    width: 370px;
    height: 290px;
    padding: 0px;
    border-radius: 10px;
    background: linear-gradient(225deg, #2f334d, #383d5c);
    box-shadow: -25px 25px 45px #282c42, 25px -25px 45px #40466a;
}

.chart2 {
    width: 100%;
    height: 100%;
}
*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    padding: 0;
    margin: 0;
}

body {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #222;
    color: #fff;
    overflow: hidden;
    height: 100vh;
}

/* 设置水平垂直居中 */

input {
    background: none;
    border: none;
    outline: none;
    vertical-align: middle;
}

/* 防止未加载完成显示undefined */
[v-cloak] {
    display: none;
}

/* 去掉button默认样式 */
button {
    border: none;
    background: transparent;
    outline: none;
}

/* flex布局相关样式 */
.flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.j_c {
    justify-content: center;
}

.j_b {
    justify-content: space-between;
}

.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

/**滚动条的宽度*/
::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

/* 滚动条的滑块 */
::-webkit-scrollbar-thumb {
    background-color: #eaecf1;
    border-radius: 3px;
}
</style>