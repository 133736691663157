<template>
    <div class="container-vue">
        <div ref="lottieupload" style="width: 200px;height: 200px;" ></div>
        <div class="upload-box">
            <div style="margin: 2%">
                <span style="font-weight: bolder">震灾数据</span>
                <el-divider></el-divider>
            </div>
            <div class="menuside" @click="uploadicon1">
                <span style="position: absolute;left: 1%" :style="{ display: isiconHidden ? 'none' : 'inline' }">
                    <svg t="1722655457818" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1018" width="20" height="20"><path d="M426.19 329.64l-9.61 253.88c-0.38 12.1-13.3 19.59-24 13.94C365.34 583.08 328.01 549 291.01 575c0 0-44 29 3 91l117.28 122.99S460.01 840 537.01 847c0 0 64 6 127.24-7.36 0 0 66.76-21.64 81.76-97.64 0 0 23.79-147.04 16.07-191.63 0 0-14.29-70.97-102.52-78.73l-88.95-10.88s-22.43 1.08-29.39-21.27l-10.54-116.1s-4-41.19-53.19-45.51c0 0-48.17 1.05-51.29 51.76z" fill="#9EA4AD" p-id="1019"></path><path d="M610 360.5c-13.81 0-25-11.19-25-25 0-59.28-48.22-107.5-107.5-107.5S370 276.22 370 335.5c0 13.81-11.19 25-25 25s-25-11.19-25-25c0-86.85 70.65-157.5 157.5-157.5S635 248.65 635 335.5c0 13.81-11.19 25-25 25z" fill="#575C66" p-id="1020"></path></svg>
                </span>
                <span>导入</span>
            </div>
            <div class="menuside" @click="uploadicon2">
                <span style="position: absolute;left: 1%" :style="{ display: !isiconHidden ? 'none' : 'inline' }">
                    <svg t="1722655457818" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1018" width="20" height="20"><path d="M426.19 329.64l-9.61 253.88c-0.38 12.1-13.3 19.59-24 13.94C365.34 583.08 328.01 549 291.01 575c0 0-44 29 3 91l117.28 122.99S460.01 840 537.01 847c0 0 64 6 127.24-7.36 0 0 66.76-21.64 81.76-97.64 0 0 23.79-147.04 16.07-191.63 0 0-14.29-70.97-102.52-78.73l-88.95-10.88s-22.43 1.08-29.39-21.27l-10.54-116.1s-4-41.19-53.19-45.51c0 0-48.17 1.05-51.29 51.76z" fill="#9EA4AD" p-id="1019"></path><path d="M610 360.5c-13.81 0-25-11.19-25-25 0-59.28-48.22-107.5-107.5-107.5S370 276.22 370 335.5c0 13.81-11.19 25-25 25s-25-11.19-25-25c0-86.85 70.65-157.5 157.5-157.5S635 248.65 635 335.5c0 13.81-11.19 25-25 25z" fill="#575C66" p-id="1020"></path></svg>
                </span>
                <span>库中已导入</span>
            </div>
            <transition name="fademodule">
                <div v-if="!isiconHidden" class="initupload" >
                    <div class="choicemenu">
                        <el-form label-position="top" label-width="40px" :model="uploadfileForm" style="font-weight: bolder" :rules="rulers">
                            <el-form-item label="文件类型" prop="type">
                                <el-select v-model="uploadfileForm.type" placeholder="请选择导入文件类型">
                                    <el-option
                                        v-for="item in filetypeopt"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="文件名称">
                                <el-input v-model="uploadfileForm.name"></el-input>
                            </el-form-item>
                            <el-form-item label="地震编号" prop="id">
                                <el-input v-model="uploadfileForm.id" placeholder="请输入地震编号"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="submitbox">
                        <el-input placeholder="请输入描述文件信息" style="width:300px" v-model="uploadfileForm.description"></el-input>
                        <el-button @click="checkupload" style="right: -6%;position: absolute;background-color:salmon;color: white;font-weight: bolder" round >确认上传</el-button>
                    </div>
                    <el-upload
                        class="upload-demo"
                        drag
                        action="https://earthquake.zouran.top/imgApi/upload"
                        :file-list="fileList"
                        list-type="picture"
                        :before-upload="beforeUpload"
                        :on-exceed="handleExceed"
                    >
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                    </el-upload>
                    <div class="text">
                        <p>文件上传大小均不可超过10MB</p>
                    </div>
                </div>
            </transition>
            <transition name="fademodule">
                <div v-if="isiconHidden" class="initupload" >
                    <div class="choicemenu">
                        <el-form label-position="top" label-width="40px" :model="searchForm" style="font-weight: bolder" :rules="rulers">
                            <span style="font-size: small;position: absolute;top: -5%;margin-bottom: 2%">筛选搜查</span>
                            <el-form-item label="文件类型" prop="type">
                                <el-select v-model="searchForm.type" placeholder="请选择导入文件类型">
                                    <el-option
                                        v-for="item in filetypeopt"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="地震编号" prop="id">
                                <el-input v-model="searchForm.id" placeholder="请输入地震编号"></el-input>
                            </el-form-item>
                            <el-form-item label="文件最大值" prop="maxSize">
                                <el-input v-model="searchForm.maxSize" placeholder="请输入文件最大值单位B"></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div style="position: absolute;bottom: -34%;right: 2%">
                        <el-button round style="background-color: blanchedalmond;font-weight: bolder" @click="setcheck">搜索</el-button>
                    </div>
                    <el-table
                        :data="records"
                        border
                        style="width: 100%"
                        height="360"
                    >
                        <el-table-column
                            prop="earthquakeId"
                            label="地震编号"
                            width="80">
                        </el-table-column>
                        <el-table-column
                            prop="id"
                            label="文件编号"
                            width="80">
                        </el-table-column>
                        <el-table-column
                            prop="name"
                            label="文件名称"
                            width="200">
                        </el-table-column>
                        <el-table-column
                            prop="description"
                            label="描述"
                            width="240">
                        </el-table-column>
                        <el-table-column
                            prop="time"
                            label="创建时间"
                            width="200">
                        </el-table-column>
                        <el-table-column
                            prop="size"
                            label="大小"
                            width="100">
                        </el-table-column>
                        <el-table-column
                            fixed="right"
                            label="操作"
                            width="100">
                            <template v-slot="scope">
                                <el-button @click="handleClick(scope.row)" type="text" size="small">预览</el-button>
                                <el-button @click="handleDownload(scope.row)" type="text" size="small">下载</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
<!--                    <el-pagination-->
<!--                        @size-change="handleSizeChange"-->
<!--                        @current-change="handleCurrentChange"-->
<!--                        :current-page="currentPage4"-->
<!--                        style="position: absolute;bottom: -15%;right: 0%"-->
<!--                        :page-sizes="[2, 3, 4]"-->
<!--                        :page-size="currentSize4"-->
<!--                        layout="total, sizes, prev, pager, next, jumper"-->
<!--                        :total="100">-->
<!--                    </el-pagination>-->
                </div>
            </transition>
        </div>
        <div ref="lottieneaten" style="width: 20%;height: 40%;position: absolute;right: 2%;bottom: 3%"></div>
    </div>
</template>
<script>
import lottie from "lottie-web";
import {getFileUploadUrl, getUploadFileStatus,searchFileBypagesize,getFileDownloadUrl} from "@/axios/api/others-api";
import {myMessage, myNotify} from "@/assets/js/utils";
import {fileuploadPara} from "@/assets/js/elform-verify/validate";

export default {
    name: "Uploaddata",
    components:{

    },
    data(){
      return{
          loop:true,
          autoplay:true,
          currentPage4:1,
          currentSize4:4,
          animationuploadpath:require('@/assets/json/upload.json'),
          animationneatenpath:require('@/assets/json/zhenli.json'),
          isiconHidden:false,
          fileList:[],
          uploadurl:"",
          uploadfile:null,
          fileId:"",
          uploadfileForm:{
              type:"",
              name:"****",
              id:"",
              description:""
          },
          searchForm:{
              type:"",
              name:"****",
              id:"",
              maxSize:"",
              minSize:0
          },
          records:null,
          suffixbox:['xlsx','jpg','png','zip','mp4','zip'],
          rulers:fileuploadPara,
          filetypeopt:[{value:1, label:"xlsx"}, {value:2, label:"jpeg/jpg"}, {value:3, label:"png"}, {value:4, label:"shpfilezip"}, {value:5, label:"mp4"},{value:6, label:"zip"},]
        }
    },
    mounted() {
        this.setlottieall()
    },
    methods:{
        handleClick(row) {
            getFileDownloadUrl(row.url).then(res=>{
                // window.open(res," '_blank'")
                const newWindow = window.open();
                // 在新窗口中写入HTML内容
                newWindow.document.write(`
        <html>
            <head>
                <title>图片预览</title>
            </head>
            <body>
                <img src="${res}" alt="预览">
            </body>
        </html>
    `);
                // 关闭文档的写入流，完成页面加载
                newWindow.document.close();

            })
        },
        handleDownload(row) {
            getFileDownloadUrl(row.url).then(res=>{
                const link = document.createElement('a');
                link.href = res;
                // 触发点击事件，开始下载
                link.click();
            })
            // 在这里添加下载的逻辑
        },
        setcheck(){
            if (this.searchForm.maxSize === '' || this.searchForm.id ==='' || this.searchForm.type === ''){
                myMessage.elError('请检查参数是否全部设置完毕！')
                return false
            }else {
                myMessage.elSuccess('设置成功！')

            }
            this.searchFilelist()
        },
        // handleCurrentChange(newPage) {
        //     // newPage 是用户选择的新页码
        //     this.currentPage4 = newPage
        //     //请求数据
        //     this.searchFilelist()
        // },
        // handleSizeChange(newSize) {
        //     // newSize 是用户选择的新每页条数
        //     this.currentSize4 = newSize
        //     // 请求数据
        //     this.searchFilelist()
        // },
        searchFilelist(){
            const that = this
            let params = {
                "earthquakeId": parseInt(this.searchForm.id),
                "suffix":this.suffixbox[this.searchForm.type-1],
                "page":1,
                "size":1,
                "maxSize":parseInt(this.searchForm.maxSize),
                "minSize":0
            }
            searchFileBypagesize(params).then(res=>{
                that.records = res.records
            })
        },
        checkupload(){
            const that = this
            if (this.uploadfile == null){
                myMessage.elError('你没有上传文件！！！')
                return
            }
            getFileUploadUrl(this.uploadfileForm.name,parseInt(this.uploadfileForm.id),this.uploadfileForm.description).then(res=>{
                that.uploadurl = res.url;
                that.fileId = res.fileId;
                this.uploadTempfile(this.uploadfile)
            })
        },
        beforeUpload(file) {
            const fileType = file.type; // 获取文件类型
            console.log(fileType)
            this.uploadfile = file

            this.uploadfileForm.name = file.name;
            let uploadtype = this.matchfun(this.uploadfileForm.type);
            if(uploadtype !== fileType) {
                myMessage.elError('上传文件类型与选择文件类型不符！');
                return false;
            }
            const isLt10M = file.size / 1024 / 1024 < 10;
            if (!isLt10M) {
                myMessage.elError('文件大小不能超过 10MB!');
                return false;// 加上这个 return 防止继续执行上传
            }
            myNotify.elSuccess('上传文件已经缓存！')
            return false;// 加上这个 return 防止继续执行上传
        },
        isSucessupload(fileId){
            getUploadFileStatus(fileId).then(res=>{
                if (res.data){
                    myMessage.elError('文件上传失败！');
                }else {
                    myMessage.elSuccess('文件上传成功！！！');
                }
            })
        },
        uploadTempfile(file){
            const that = this;
            let axios = require('axios');
            let reader = new FileReader();
            reader.onload = function(event) {
                let arrayBuffer = event.target.result;
                let config = {
                    method: 'put',
                    url: that.uploadurl,
                    data: arrayBuffer,  // 使用 ArrayBuffer 作为请求体
                    headers: {
                        'Content-Type': 'application/octet-stream'  // 设置为二进制流
                    }
                };
                axios(config)
                    .then(function(response) {
                        that.isSucessupload(that.fileId);
                        console.log(JSON.stringify(response.data));
                    })
                    .catch(function(error) {
                        console.log(error);
                    });
            };
            reader.readAsArrayBuffer(file);  // 读取文件为 ArrayBuffer
        },
        handleExceed(files, fileList) {
            myMessage.elWarning(`文件数量超出限制，最多上传 ${fileList.length} 个文件。`);
        },
        matchfun(type){
            switch (type){
                case 1:
                    return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                case 2:
                    return "image/jpeg"
                case 3:
                    return "image/png"
                case 4:
                    return "application/x-zip-compressed"
                case 5:
                    return "video/mp4"
                case 6:
                    return "application/x-zip-compressed"
                default:
                    return "application/x-zip-compressed"
            }
        },
        setlottieall(){
            lottie.loadAnimation({
                container: this.$refs.lottieupload,
                renderer: 'svg',
                loop: this.loop,
                autoplay: this.autoplay,
                animationData: this.animationuploadpath
            });
            lottie.loadAnimation({
                container: this.$refs.lottieneaten,
                renderer: 'svg',
                loop: this.loop,
                autoplay: this.autoplay,
                animationData: this.animationneatenpath
            });
        },
        uploadicon1(){
            if (this.isiconHidden !== false){
                this.isiconHidden = !this.isiconHidden
            }

        },
        uploadicon2(){
            if (this.isiconHidden === false){
                this.isiconHidden = !this.isiconHidden
            }
        },
    },

}
</script>

<style scoped>

.container-vue {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* 使第一个 div 保持在原位 */
    height: 100vh; /* 设置父容器高度以居中对齐 */
}
.upload-box {
    position: absolute;
    width: 60%;
    height: 72%;
    background-color: #f0f0f0; /* 设置背景颜色 */
    align-self: center; /* 使第二个 div 水平居中 */
    top: 12%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); /* 设置阴影 */
}
.menuside {
    background-color:skyblue;
    width: 15%;
    height: 10%;
    margin-top: 1%;
    text-align: center;
    display: flex; /* 添加Flexbox布局 */
    align-items: center; /* 垂直居中 */
    justify-content: center; /* 水平居中 */
    font-size: 0.8em;
    font-weight: bolder;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    border-top-right-radius: 10px; /* 设置右上角圆角 */
    border-bottom-right-radius: 10px; /* 设置右下角圆角 */
    transition: background-color 0.3s, color 0.3s, width 0.3s, height 0.3s, transform 0.3s; /* 添加过渡动画 */
}

.el-table .warning-row {
    background: oldlace;
}

.el-table .success-row {
    background: #f0f9eb;
}

.menuside:hover{
    background-color: #6FB0FC;
    color: white;
    width: 17%;
    height: 12%;
    transform: translateY(-5px); /* 字体上移 */
    cursor: pointer;
}

.initupload{
    position: absolute;
    width: 70%;
    height:60%;
    top: 18%;
    right: 4%;
    border: 2px solid #e0e0e0; /* 2px 宽度，实线，黑色 */
    border-radius: 5px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}

/deep/ .el-upload{
    width: 100%;
    height: 100%;
}
/deep/ .el-upload .el-upload-dragger{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.upload-demo{
    height: 100%;
}
.text{
    font-weight: bolder;
    justify-content: center;
    position: absolute;
    left: 30%;
}

.fademodule-enter-active, .fademodule-leave-active {
    transition: opacity 0.5s;
}
.fademodule-enter, .fademodule-leave-to /* .fademodule-leave-active in <2.1.8 */ {
    opacity: 0;
}

.choicemenu{
    width: 200px;
    height: 200px;
    position: absolute;
    left: -35%;
    bottom: 0%;
}
.submitbox{
    width: 500px;
    height: auto;
    position: absolute;
    bottom: -34%;
    right: 10%;

}
</style>