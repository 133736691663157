<template>
    <el-divider></el-divider>
    <span class="spanText" >埋压人数评估模块:</span>
    <br>
    <div style="display: flex;">
            <el-input
                placeholder="模型公式"
                v-model="buryPersonModel"
                :disabled="true"
                prefix-icon="el-icon-data-analysis"
                style="font-weight: bold; color: black;position: absolute;width: 40%;left: 8%;top: 22%">
            </el-input>
        <div class="leftbox">
        <deathPerson :deathpersonresult="resultBurynum" :fix-num="1"></deathPerson>
        </div>
        <div class="rightbox">
            <el-select v-model="selectValueOfBury" filterable placeholder="请选择评估模型" style="position: absolute;right: 14%;top: 22%">
                <el-option
                    v-for="item in optionsOfBuryModel"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            <div class="paramsdiv">
                <el-form :model="buryModelParaForm"  label-width="120px" class="demo-ruleForm" :rules="rules">
                    <el-form-item label="震发地区:" prop="Quakearea">
                        <el-select v-model="buryModelParaForm.Quakearea" placeholder="震发地区" size="small">
                            <el-option label="密集城市" :value=0></el-option>
                            <el-option label="偏远山区" :value=1></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="震发时间:" prop="time">
                            <el-select v-model="buryModelParaForm.time" filterable placeholder="选择时间段" size="small">
                                <el-option
                                    v-for="item in timeOption"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                    </el-form-item>
                        <el-form-item label="地震烈度:" prop="magnValue">
                        <el-input v-model="buryModelParaForm.magnValue" placeholder="请输入地震烈度" size="small" ></el-input>
                        </el-form-item>
                        <el-form-item label="人口死亡率:" prop="paraRij">
                            <el-input v-model="buryModelParaForm.paraRij" placeholder="请输入人口死亡率" size="small"></el-input>
                        </el-form-item>
                    <el-form-item label="搜救影响系数:" prop="paraLMD" >
                        <el-input v-model="buryModelParaForm.paraLMD" size="small" placeholder="请输入搜救影响系数"></el-input>
                    </el-form-item>
                    <el-form-item label="伤亡人数:" prop="paraNi">
                        <div style="display: flex">
                            <el-input v-model="buryModelParaForm.paraNi" placeholder="导入伤亡人数" size="small" style="width: 45%"></el-input>
                            <el-button size="small" type="success" style="position: relative;right: -4%" round>去评估</el-button>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <div class="inputdiv">
                <span >当前评估地震信息：</span>
                <el-button type="primary" icon="el-icon-view"  size="small" @click="notifynow">查看</el-button>
                <el-button type="primary" icon="el-icon-edit-outline" size="small" @click="changeselected">修改</el-button>
                <el-button circle icon="el-icon-cpu" style="background-color: papayawhip" @click="resuletfun"></el-button>
            </div>
        </div>
    </div>

</template>

<script>
import {changeselected} from "@/assets/js/reuse-function";
import deathPerson from "@/components/echartsgraph/death-person.vue";
import {rulerbury} from "@/assets/js/elform-verify/validate";
export default {
    name: "buried-estimate",
    props:{
        earthquakeInfostring:{
            type:String,
            required:true
        },
        earthquakeInten:{
            type:Number,
            required: true
        }
    },
    components:{
        deathPerson
    },
    data(){
        return{
            buryendRate:0,
            resultBurynum:0,
            rules:rulerbury,
            buryModelParaForm: {
                paraLMD:"1.1",
                paraFx:"",
                paraRij:"",
                paraNi:"",
                Quakearea:null,
                magnValue:null,
                time:''
            },
            timeOption:[
                {
                    value:1,
                    label:"06:00--19:00"
                },
                {
                    value:2,
                    label:"19:00--次日06:00"
                },

            ],
            magnGradeFrom: [{value: '1.0-2.0', label: '烈度Ⅴ'}, {value: '2.0-3.0', label: '烈度Ⅵ'}, {value: '3.0-4.0', label: '烈度Ⅶ'
            }, {value: '4.0-5.0', label: '烈度Ⅷ'}, {value: '5.0-6.0', label: '烈度Ⅸ'}, {value: '6.0-7.0', label: '烈度Ⅹ'}],
            buryPersonModel:'',
            buryPersonModelForm:[
                'NB = λsr * fx * R_ij * N_i',
            ],
            selectValueOfBury:'',
            optionsOfBuryModel:[{
                value: 0,
                label: '普通评估模型'
            },],
        }
    },
    computed:{
        deathDate() {
            let resultlist = [];
            let index;
            let num = this.buryModelParaForm.magnValue
            if (this.buryModelParaForm.Quakearea === 0){
                resultlist = [0,0.000014,0.00014,0.00106,0.00607,0.02880,0.11716]
            }else if (this.buryModelParaForm.Quakearea === 1){
                resultlist = [0,0.000008,0.00005,0.00033,0.00162,0.006666,0.02415]
            }
            if (num<1){
                index = 0
            } else if (num>=1 &&num < 3){
                index = 1
            }else if(num<=8){
                index = Math.ceil(num) - 2
            }else {
                index = 6
            }
            return resultlist[index]
        },
        computedfx(){
            if (this.buryModelParaForm.time === 1){
                return 1
            }
            let fxlist = [0,1.3,1.5,2,4,8,17]
            let num = this.buryModelParaForm.magnValue
            let index;
            if (num<1){
                index = 0
            } else if (num>=1 &&num < 3){
                index = 1
            }else if(num<=8){
                index = Math.ceil(num) - 2
            }else {
                index = 6
            }
            return fxlist[index]
        }
    },
    watch:{
        selectValueOfBury(newVal){
            this.buryPersonModel = this.buryPersonModelForm[newVal]
        },
        'buryModelParaForm.Quakearea'(newVal){
                this.getdeaterate()
        },
        'buryModelParaForm.time'(newVal){
            this.getdeaterate()
        },
        'buryModelParaForm.magnValue'(newVal){
            this.getdeaterate()
        }
    },
    methods:{
        changeselected,
        notifynow(){
            this.buryModelParaForm.magnValue = this.earthquakeInten
            this.$notify({title: '提示', message:this.earthquakeInfostring, duration: 0});
        },
        resuletfun(){
            this.buryModelParaForm.paraFx = this.computedfx
            console.log(this.buryModelParaForm)
            this.resultBurynum = this.buryModelParaForm.paraFx*parseFloat(this.buryModelParaForm.paraLMD)*this.buryModelParaForm.paraNi*this.buryModelParaForm.paraRij

        },
        getdeaterate(){
            if (this.buryModelParaForm.Quakearea !==null && this.buryModelParaForm.time!== '' && this.buryModelParaForm.magnValue != null){
                this.buryModelParaForm.paraRij = this.deathDate
            }
        },
        testYm(){
            if(this.buryModelParaForm.Quakearea == "0"){
                this.buryModelParaForm.paraFx = this.computeFx*0.75
                this.buryModelParaForm.paraNi = this.deathDatecity
            }else {
                this.buryModelParaForm.paraFx = this.computeFx
                this.buryModelParaForm.paraNi = this.deathDatecountry
            }
            this.buryModelParaForm.endRate = this.buryModelParaForm.paraFx * parseFloat(this.buryModelParaForm.paraLMD)*this.buryModelParaForm.paraNi
            this.buryendRate = this.buryModelParaForm.endRate
            this.concluShowButton = true
        },
    },


}
</script>

<style scoped>
.spanText{
    font-size: 1.5em;
    font-weight: bold;
}
.leftbox{

    width: 400px;
    height: 480px;
    float: left;
    margin-top: 4%;
    /*border: 2px solid lightblue;*/

}
.rightbox{
    width: 100%;
}
.inputdiv{
    position: absolute;
    right: 15%;
    bottom: 6%;
}

.paramsdiv{
    position: absolute;
    right: 10%;
    top: 32%;
}
.paramsdiv .el-input{
    width: 76%;
}
</style>