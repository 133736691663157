<template>
<div style="height: 400px;width: 550px;position: absolute;top: 30%;" id="main"></div>
</template>

<script>
import * as echarts from 'echarts';

export default {
    name: "death-person",
    props:{
        deathpersonresult:{
            type:Number,
            required:true
        },
        fixNum:{
            type:Number,
            required: true
        }
    },
    data(){
        return{
            myChart:null,
            option:{
                series: [
                    {
                        type: 'gauge',
                        startAngle: 180,
                        endAngle: 0,
                        center: ['50%', '75%'],
                        radius: '90%',
                        min: 0,
                        max: 1,
                        splitNumber: 8,
                        axisLine: {
                            lineStyle: {
                                width: 6,
                                color: [
                                    [0.25, '#FDDD60'],
                                    [0.5, '#FFFF00'],
                                    [0.75, '#FF6E76'],
                                    [1, '#FF4500']
                                ]
                            }
                        },
                        pointer: {
                            icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                            length: '12%',
                            width: 20,
                            offsetCenter: [0, '-60%'],
                            itemStyle: {
                                color: 'auto'
                            }
                        },
                        axisTick: {
                            length: 12,
                            lineStyle: {
                                color: 'auto',
                                width: 2
                            }
                        },
                        splitLine: {
                            length: 20,
                            lineStyle: {
                                color: 'auto',
                                width: 5
                            }
                        },
                        axisLabel: {
                            color: '#464646',
                            fontSize: 20,
                            distance: -60,
                            rotate: 'tangential',
                            formatter: function (value) {
                                if (value === 0.875) {
                                    return '10000-100000';
                                } else if (value === 0.625) {
                                    return '1000-10000';
                                } else if (value === 0.375) {
                                    return '100-1000';
                                } else if (value === 0.125) {
                                    return '1-100';
                                }
                                return '';
                            }
                        },
                        title: {
                            offsetCenter: [0, '-10%'],
                            fontSize: 20
                        },
                        detail: {
                            fontSize: 30,
                            offsetCenter: [0, '-35%'],
                            valueAnimation: true,
                            formatter: function (value) {
                                return Math.round(value * 100) + '';
                            },
                            color: 'inherit'
                        },
                        data: [
                            {
                                value: 10,
                                name: '估计伤亡人数:'
                            }
                        ]
                    }
                ]
            },
        }
    },
    computed:{
      computedrate(){
          // console.log(this.deathpersonresult)
          let num = Math.ceil(this.deathpersonresult)
          if (num <= 100){
              return 0.25*num/100
          }else if (num<1000){
              return 0.25*(num-100)/900+0.25
          }else if(num<10000){
              return 0.25*(num-1000)/9000+0.5
          }else {
              return 0.25*(num-10000)/90000+0.75
          }
      } ,
    },
    mounted() {
        this.createEcharts()
    },
    watch:{
        deathpersonresult(newVal){

            this.destoryEcharts()
            this.createEcharts()
        },
    },
    methods:{
      createEcharts(){
          let chartDom = document.getElementById('main');
          this.myChart = echarts.init(chartDom);
          // console.log('testnum')
          // console.log(this.option.series[0].data[0].value)
          this.option.series[0].data[0].value = this.computedrate
          if (this.fixNum === 1){
              this.option.series[0].data[0].name = '估计埋压人数:'+Math.ceil(this.deathpersonresult)
          }else {
              this.option.series[0].data[0].name = '估计伤亡人数:'+Math.ceil(this.deathpersonresult)
          }

          this.myChart.setOption(this.option); // 将配置应用到图表
      },
      destoryEcharts(){
          this.myChart.dispose(); // 销毁图表实例
          this.myChart = null; // 清空图表实例引用
      }
    }
}
</script>

<style scoped>

</style>