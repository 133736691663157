<template>
    <el-divider></el-divider>
    <span class="spanText">经济损失模块:</span>
    <el-button class="titlebutton" icon="el-icon-d-arrow-right" size="small" circle
               @mouseenter="myNotify.elSuccess('点击查看经济损失模型公式详细')"
               @click="drawershow=true"
    ></el-button>
    <br>
    <div style="display: flex">
        <div class="leftbox">
            <economicLoss :econimicloss-all="economiclossAll"></economicLoss>
            <div style="position: absolute;left: 12%;bottom: 10%;color: brown;font-size: large">
                <span>经济损失总计值：</span><span>{{(economiclossAll.paraL / 10000).toFixed(2)}}</span><span> 万元</span>
            </div>
        </div>
        <div class="rightbox">
            <div>
                <span class="textspan">震发城市:</span>
                <el-select v-model="economiclossPara.city" clearable placeholder="震发城市" style="margin-left: auto;width: 24%">
                    <el-option
                        v-for="item in cityoptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                <el-button round style="background-color: brown;color: white;margin-left: 1%"
                           @click="selecthousebase('house')">建筑损毁</el-button>
                <el-button round style="background-color: brown;color: white;"
                           @click="selecthousebase('base')">基础设施损毁</el-button>
            </div>
            <div class="linediv" style="margin-left: 7.5%">
                <span @mouseenter="remindnotify(0);">调整系数a:</span>
                <el-input v-model="economiclossPara.ParaA"></el-input>
                <span @mouseenter="remindnotify(1)" >修正系数r1:</span>
                <el-input  v-model="economiclossPara.Parar1"></el-input>
            </div>
            <div class="linediv">
                <span @mouseenter="remindnotify(2);">修正系数r2:</span>
                <el-input v-model="economiclossPara.Parar2"></el-input>
                <span @mouseenter="remindnotify(4)" >修正系数r4:</span>
                <el-input v-model="economiclossPara.Parar4"></el-input>
            </div>
            <div class="linediv">
                <span @mouseenter="remindnotify(3);">修正系数r3:</span>
                <el-button icon="el-icon-upload2" type="primary" size="small" @click="showparar3 = true">调整</el-button>
                <span @mouseenter="remindnotify(5);">修正系数n2:</span>
                <el-button icon="el-icon-upload2" type="primary" size="small" @click="showparan2 = true">调整</el-button>
            </div>
            <el-button circle icon="el-icon-cpu" style="position: absolute;background-color: papayawhip;right: 8%" @click="resultfun"></el-button>
        </div>
    </div>
<el-drawer
    title="经济损失模型公式："
    v-model="drawershow"
    direction="ltr"
    size="34%"
:with-header="true"
>
<div>
    <div style="display: flex; justify-content: center; align-items: center;">
        <div style="width: 300px; height: 150px;">
            <img src="@/assets/img/economicloss.jpeg" style="width: 100%; height: 100%; object-fit: contain;">
        </div>
    </div>
    <div style="text-align: center; font-size: 12px;">
        <p>L ：为地震作用下城市地震灾害直接经济损失的总和；</p>
        <p>a ：为城市地震灾害直接经济损失调整系数，一般取 1.1~1.5；</p>
        <p>Ls：为地震作用下建筑物的主体结构的直接经济损失；</p>
        <p>Ld：为地震作用下建筑装修的直接经济损失；</p>
        <p>Lc：为地震作用下建筑室内财产的直接经济损失；</p>
        <p>Lf：为地震作用下生命线工程破坏造成的直接经济损失；</p>
        <p>Pj：为建筑物和生命线工程发生破坏状态 j 时的破坏概率；</p>
        <p>Qi：为建筑物 i 主体结构的重置单价；</p>
        <p>A：为建筑面积；</p>
        <p>Mf：为生命线工程的重置成本；</p>
        <p>D(j,s)：为建筑物发生破坏状态 j 时主体结构的损失比；</p>
        <p>D(j,d)：为建筑物发生破坏状态 j 时建筑装修的损失比；</p>
        <p>D(j,c)：为建筑物发生破坏状态 j 时室内财产的损失比；</p>
        <p>D(j,f)：为建筑物发生破坏状态 j 时生命线工程的损失比；</p>
        <p>γ1：为建筑价值折减系数修正系数；</p>
        <p>γ2：为考虑经济发展水平差异的装修损失修正系数；</p>
        <p>γ3：为考虑不同用途的装修损失修正系数；</p>
        <p>γ4：为考虑不同装修等级的装修损失修正系数；</p>
        <p>η1：为建筑物装修费用与主体结构造价的比值；</p>
        <p>η2：为建筑物室内财产价值与主体结构造价的比值。</p>
    </div>
</div>
</el-drawer>
<el-dialog v-model="showparar3">
    <div>
        <span>不同用途的装修损失修正系数设置：</span>
        <el-divider></el-divider>
        <el-form label-position="right" label-width="80px" :model="adjustr3">
            <el-form-item label="住宅">
                <el-input v-model="adjustr3.house" style="width: 20%"></el-input>
            </el-form-item>
            <el-form-item label="商业">
                <el-input v-model="adjustr3.shopserve" style="width: 20%"></el-input>
            </el-form-item>
            <el-form-item label="办公">
                <el-input v-model="adjustr3.work" style="width: 20%"></el-input>
            </el-form-item>
            <el-form-item label="科研教育">
                <el-input v-model="adjustr3.ttmthree" style="width: 20%"></el-input>
            </el-form-item>
            <el-form-item label="文化体育">
                <el-input v-model="adjustr3.recreation" style="width: 20%"></el-input>
            </el-form-item>
            <el-form-item label="厂房">
                <el-input v-model="adjustr3.facility" style="width: 20%"></el-input>
            </el-form-item>
            <el-form-item label="仓库">
                <el-input v-model="adjustr3.storage" style="width: 20%"></el-input>
            </el-form-item>
            <el-form-item label="其他">
                <el-input v-model="adjustr3.other" style="width: 20%"></el-input>
            </el-form-item>
        </el-form>
        <el-button type="primary" style="position: absolute;right: 4%;bottom: 7.5%">调整完成</el-button>
    </div>
</el-dialog>
<el-dialog v-model="showparan2">
    <div>
        <span>建筑物室内财产价值与主体结构造价的比值设置：</span>
        <el-divider></el-divider>
        <el-form label-position="right" label-width="80px" :model="adjustn2">
            <el-form-item label="住宅">
                <el-input v-model="adjustn2.house" style="width: 20%"></el-input>
            </el-form-item>
            <el-form-item label="商业">
                <el-input v-model="adjustn2.shopserve" style="width: 20%"></el-input>
            </el-form-item>
            <el-form-item label="办公">
                <el-input v-model="adjustn2.work" style="width: 20%"></el-input>
            </el-form-item>
            <el-form-item label="科研教育">
                <el-input v-model="adjustn2.ttmthree" style="width: 20%"></el-input>
            </el-form-item>
            <el-form-item label="文化体育">
                <el-input v-model="adjustn2.recreation" style="width: 20%"></el-input>
            </el-form-item>
            <el-form-item label="厂房">
                <el-input v-model="adjustn2.facility" style="width: 20%"></el-input>
            </el-form-item>
            <el-form-item label="仓库">
                <el-input v-model="adjustn2.storage" style="width: 20%"></el-input>
            </el-form-item>
            <el-form-item label="其他">
                <el-input v-model="adjustn2.other" style="width: 20%"></el-input>
            </el-form-item>
        </el-form>
        <el-button type="primary" style="position: absolute;right: 4%;bottom: 7.5%">调整完成</el-button>
    </div>
</el-dialog>
    <el-dialog v-model= "configmodule" width="80%">
        <setparaEstimateHouseconfigure v-if="showHousemodule"
                                       @updatedate="updatechilddata"
        ></setparaEstimateHouseconfigure>
        <setparaEstimateInfrastructureconfigure v-if="showBasemodule"
                                                @updatedate="updatechilddata"
        ></setparaEstimateInfrastructureconfigure>
    </el-dialog>
</template>

<script>
import {cityStructureprice, getMainStruceurePara} from "@/axios/api/others-api";
import economicLoss from "@/components/echartsgraph/economic-loss.vue";
import {myNotify} from "@/assets/js/utils";
import setparaEstimateHouseconfigure from "@/components/setpara-estimate-houseconfigure.vue";
import setparaEstimateInfrastructureconfigure from "@/components/setpara-estimate-infrastructureconfigure.vue";
export default {
    name: "econimicloss-estimate",
    components:{
        economicLoss,
        setparaEstimateHouseconfigure,
        setparaEstimateInfrastructureconfigure,
    },
    data(){
        return{
            drawershow:false,
            showparan2:false,
            showparar3:false,
            configmodule:false,
            showBasemodule:false,
            showHousemodule:false,
            structureList:[],
            baseList:[],
            PjPara:{},
            PjParabase:{},
            economiclossPara:{
                ParaA:"1.3",
                Parar1:"1",
                Parar2:"1.15",
                Parar4:"1",
                city:""
            },
            cityoptions:[{value: '北京', label: '北京'}, {value: '天津', label: '天津'}, {value: '河北', label: '河北'}, {value: '山西', label: '山西'}, {value: '内蒙古', label: '内蒙古'}, {value: '辽宁', label: '辽宁'}, {value: '吉林', label: '吉林'}, {value: '黑龙江', label: '黑龙江'}, {value: '上海',
                label: '上海'}, {value: '江苏', label: '江苏'}, {value: '浙江', label: '浙江'}, {value: '安徽', label: '安徽'}, {value: '福建', label: '福建'}, {value: '江西', label: '江西'}, {value: '山东', label: '山东'
                }, {value: '河南', label: '河南'}, {value: '湖北', label: '湖北'}, {value: '湖南', label: '湖南'}, {value: '广东', label: '广东'}, {value: '广西', label: '广西'
                }, {value: '海南', label: '海南'}, {value: '重庆', label: '重庆'}, {value: '四川', label: '四川'}, {value: '贵州', label: '贵州'}, {value: '云南', label: '云南'
                }, {value: '西藏', label: '西藏'}, {value: '陕西', label: '陕西'}, {value: '甘肃', label: '甘肃'}, {value: '青海', label: '青海'}, {value: '宁夏', label: '宁夏'
                }, {value: '新疆', label: '新疆'}, {value: '平均', label: '平均'}
            ],
            adjustn2:{
                house:0.25,
                shopserve:1.1,
                work:0.9,
                ttmthree:1.2,
                recreation:1.2,
                facility:1.5,
                storage:1.5,
                other:0.5
            },
            adjustr3:{
                house:1.0,
                shopserve:1.1,
                work:0.85,
                ttmthree:0.95,
                recreation:1.1,
                facility:0.6,
                storage:0.5,
                other:0.9
            },
            cityhouseproportion:{
                house:0.4,
                shopserve:0.2,
                work:0.1,
                ttmthree:0.1,
                recreation:0.05,
                facility:0.05,
                storage:0.05,
                other:0.05
            },
            economiclossAll:{
                paraL:0,
                paraLs:0,
                paraLd:0,
                paraLc:0,
                paraLf:0
            },
            cityPrice:{},
            structrequestlist:{},
            structrequestlistdjd:{},
            structrequestlistdjc:{},
            paran1:{}
        }
    },
    computed:{
        myNotify() {
            return myNotify
        },
        MPDjf() {
            let MPDjf = 0;
            for (let i = 0; i < this.baseList.length; i++) {
                let PDjf = this.baseList[i].level1 * this.PjParabase.level1 +
                    this.baseList[i].level2 * this.PjParabase.level2 +
                    this.baseList[i].level3 * this.PjParabase.level3 +
                    this.baseList[i].level4 * this.PjParabase.level4 +
                    this.baseList[i].level5 * this.PjParabase.level5;
                MPDjf += parseFloat(this.baseList[i].price) * PDjf
            }
            return MPDjf
        },
        APDjc() {
            let APDjc = 0;
            for (let i = 0; i < this.structureList.length; i++) {
                let label = this.structureList[i].label;
                if (this.structrequestlistdjc[label]) {
                    let PDjs = this.structrequestlistdjc[label].level1 * this.PjPara.level1 +
                        this.structrequestlistdjc[label].level2 * this.PjPara.level2 +
                        this.structrequestlistdjc[label].level3 * this.PjPara.level3 +
                        this.structrequestlistdjc[label].level4 * this.PjPara.level4 +
                        this.structrequestlistdjc[label].level5 * this.PjPara.level5;

                    APDjc += this.structureList[i].area * PDjs;
                } else {
                    console.warn(`Missing data for label: ${label}`);
                }
            }
            return APDjc
        },
        APDjd() {
            let APDjd = 0;
            for (let i = 0; i < this.structureList.length; i++) {
                let label = this.structureList[i].label;
                if (this.structrequestlistdjd[label]) {
                    let PDjs = this.structrequestlistdjd[label].level1 * this.PjPara.level1 +
                        this.structrequestlistdjd[label].level2 * this.PjPara.level2 +
                        this.structrequestlistdjd[label].level3 * this.PjPara.level3 +
                        this.structrequestlistdjd[label].level4 * this.PjPara.level4 +
                        this.structrequestlistdjd[label].level5 * this.PjPara.level5;
                    APDjd += this.structureList[i].area * PDjs * this.paran1[label];
                } else {
                    console.warn(`Missing data for label: ${label}`);
                }
            }
            return APDjd
        },
        APDjs() {
            let APDjs = 0;
            for (let i = 0; i < this.structureList.length; i++) {
                let label = this.structureList[i].label;
                if (this.structrequestlist[label]) {
                    let PDjs = this.structrequestlist[label].level1 * this.PjPara.level1 +
                        this.structrequestlist[label].level2 * this.PjPara.level2 +
                        this.structrequestlist[label].level3 * this.PjPara.level3 +
                        this.structrequestlist[label].level4 * this.PjPara.level4 +
                        this.structrequestlist[label].level5 * this.PjPara.level5;
                    APDjs += this.structureList[i].area * PDjs;
                } else {
                    console.warn(`Missing data for label: ${label}`);
                }
            }
            return APDjs
        },
        computednQi() {
            let result = 1;
            let adjustpara = {}
            if (this.indexadjust === 1) {
                adjustpara = this.adjustn2
            } else if (this.indexadjust === 2) {
                adjustpara = this.adjustr3
            } else {
                return 0
            }
            result = parseFloat(this.cityPrice.work) * this.cityhouseproportion.work * adjustpara.work +
                this.cityhouseproportion.facility * parseFloat(this.cityPrice.facility) * adjustpara.facility +
                this.cityhouseproportion.house * parseFloat(this.cityPrice.house) * adjustpara.house +
                this.cityhouseproportion.storage * parseFloat(this.cityPrice.storage) * adjustpara.storage +
                this.cityhouseproportion.shopserve * parseFloat(this.cityPrice.shopserve) * adjustpara.shopserve +
                this.cityhouseproportion.other * parseFloat(this.cityPrice.other) * adjustpara.other +
                this.cityhouseproportion.recreation * parseFloat(this.cityPrice.recreation) * adjustpara.recreation +
                this.cityhouseproportion.ttmthree * parseFloat(this.cityPrice.ttmthree) * adjustpara.ttmthree
            return result
        },
        computedQi() {
            let result = parseFloat(this.cityPrice.work) * this.cityhouseproportion.work +
                this.cityhouseproportion.facility * parseFloat(this.cityPrice.facility) +
                this.cityhouseproportion.house * parseFloat(this.cityPrice.house) +
                this.cityhouseproportion.storage * parseFloat(this.cityPrice.storage) +
                this.cityhouseproportion.shopserve * parseFloat(this.cityPrice.shopserve) +
                this.cityhouseproportion.other * parseFloat(this.cityPrice.other) +
                this.cityhouseproportion.recreation * parseFloat(this.cityPrice.recreation) +
                this.cityhouseproportion.ttmthree * parseFloat(this.cityPrice.ttmthree)
            return result
        },
    },
    watch:{
        'economiclossPara.city'(newVal){
            const that = this
            //获得城市房屋单价
            cityStructureprice(newVal).then(res=>{
                that.cityPrice = res
            })
        }
    },
    methods:{
        updatechilddata(list,paraForm,choice){
            if (choice === 'house'){
                this.structureList = list
                this.PjPara = paraForm
                const that = this
                let labellist = this.structureList.map(item => item.label);

                //获取整个参数列表
                for (let i = 0;i < 4;i++){
                    getMainStruceurePara(labellist,i).then(res=>{
                        if (i == 0){
                            that.paran1 = res
                        }else if(i == 1){
                            that.structrequestlist = res
                        }else if(i == 2){
                            that.structrequestlistdjd = res
                        }else if(i == 3){
                            that.structrequestlistdjc = res
                        }
                    })
                }
                this.showHousemodule = false
            }else if (choice === 'base'){
                this.baseList = list
                this.PjParabase = paraForm
                this.showBasemodule = false
            }
            this.configmodule = false
        },
        selecthousebase(choice){
            if(choice === 'house'){
                this.configmodule = true
                this.showBasemodule = false
                this.showHousemodule = true
            }else if(choice === 'base'){
                this.configmodule = true
                this.showBasemodule = true
                this.showHousemodule = false
            }
        },
        remindnotify(index){
            let introducePara = [
                "城市地震灾害直接经济损失调整系数",
                "γ1表示建筑的折减系数。折减系数通常指的是根据建筑物的使用年限、磨损程度和技术陈旧性等因素计算出的价值折减比例。系数取值：使用时间：" +
                "0~5年 取 1 5~10年 取 0.95 10~15年 取 0.90 15~20年 取 0.85 20~25年 取0.80 25~30年 取0.7 大于30年 取0.6",
                "γ2表示考虑经济发展水平差异的装修损失修正系数。根据“灾害直接损失评估" +
                "标准”将城市发展水平划分为三个等级，并给出了相应的系数 发达 1.3 较发达 1.15 一般 1.0",
                "γ3指的是虑不同用途的装修损失修正系数。住宅1.0 商业1.1 办公0.85 科研0.9 教育0.95 医疗1.0 文化1.1 " +
                "体育1.05 娱乐1.2 厂房0.6 仓库0.5 其他0.9",
                "γ4指的是考虑不同装修等级的装修损失修正系数；装修等级 一般 0.8 中档 1.0 高档 1.2 豪华1.5",
                "η2指的是建筑物室内财产价值与主体结构造价的比值。住宅0.25 商业1.1 办公0.90 科研1.20 教育0.90 医疗1.40 文化1.35 " +
                "体育1.20 娱乐1.10 厂房1.50 仓库1.50 其他0.5"
            ]
            myNotify.elSuccess(introducePara[index])
        },
        resultfun(){
            //计算Qi 用的是每个不同的用途的房子占比乘以重置单价 求出平均
            let Qi = this.computedQi
            let APDjs = this.APDjs
            let result = APDjs*Qi*this.economiclossPara.Parar1
            let APDjd = this.APDjd;
            let APDjc = this.APDjc;
            let MPDjf = this.MPDjf;
            this.indexadjust = 1
            let n2Qi = this.computednQi;
            this.indexadjust = 2
            let r3Qi = this.computednQi;
            let resultLc =n2Qi*APDjc
            let resultLd = r3Qi*APDjd*this.economiclossPara.Parar2*this.economiclossPara.Parar4
            let resultLf = MPDjf
            this.economiclossAll.paraLf = resultLf
            this.economiclossAll.paraLs = result;
            this.economiclossAll.paraLd = resultLd
            this.economiclossAll.paraLc = resultLc;
            this.economiclossAll.paraL = this.economiclossPara.ParaA*(resultLf+result+resultLc+resultLd)
        },
    },

}
</script>

<style scoped>
.spanText{
    font-size: 1.5em;
    font-weight: bold;
}

.leftbox{
    height: 58vh;
    width: 60vh;
    float: left;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    /*border: 2px solid lightblue;*/
    margin-top: 4%;
    margin-left: 3%;
}
.rightbox{
    width: 100%;
}
.textspan{
    font-weight: bold;
    font-size: 1.3em;
    margin: 2%;
}
.titlebutton{
    background-color: papayawhip;
    margin-left: 2%;
}
.linediv{
    margin: 6%;
    margin-top: 10%;
    margin-left: 8%;
}

.linediv span{
    font-weight: bold;
    font-size: medium;
    color:#333333;
}
.linediv .el-input{
    width: 20%;

    margin: 2% ;
}
.linediv .el-button{
    width: 20%;

    margin: 2% ;
}
</style>