<template>
  <!--  地震震源-->
  <vc-datasource-custom
    name="epicenter"
    :show="show"
  >
    <vc-entity
      v-for="(item,index) in earthquakePositionInfoList"
      :id="'epicenter_'+item.earthquakeId.toString()+'_'+uuid()"
      :key="index"
      :position="[item.longitude,item.latitude, 0]"
      description="epicenter"
    >
      <vc-graphics-point
        :color="pointcolor(item)"
        :pixel-size="1.5*item.magnitude"
      />
    </vc-entity>
  </vc-datasource-custom>
</template>

<script>
export default {
  name: "CesiumEarthquakePoint",
  props:{
    show:{
      type:Boolean,
      default: false
    },
    earthquakePositionInfoList: {
      type:Array,
      default:()=>[{
        earthquakeId:1,
        longitude:99.87,
        latitude:25.67,
        magnitude:6.4
      }]
    },
  },
  methods:{
    uuid(){
      const s = [];
      const hexDigits = '0123456789abcdef';
      for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.charAt(Math.floor(Math.random() * 0x10));
      }
      s[14] = '4';
      s[19] = hexDigits.charAt((parseInt(s[19], 16) & 0x3) | 0x8);
      s[8] = s[13] = s[18] = s[23] = '-';
      return s.join('');
    },
    pointcolor(item){
      // console.log(item.magnitude)
      if(4>item.magnitude) return '#efe8e8'
      else if(5>item.magnitude) return '#b4f19a'
      else if(6>item.magnitude) return '#f3a201'
      else return '#ff263c'
    }
  }
}
</script>
<style scoped>

</style>