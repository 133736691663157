<template>
  <!-- 医院位置 -->
  <vc-datasource-custom
    :show="show"
    name="hospitalPoint"
    :entities="hospitalBillboards"
    @cluster-event="onHospitalClusterEvent"
    @ready="onHospitalReady"
  />
</template>

<script>
export default {
  name: "CesiumHospitalPoint",
  props:{
    hospitalList:{
      type:Array,
      default: ()=>[{
        "id": 61570,
        "lon": 114.455128,
        "lat": 23.927171
      }]
    },
    show:{
      type:Boolean,
      default: false
    },
  },
  data(){
    return{
    }
  },
  computed:{
    hospitalBillboards(){
      const resultList=[]
      const list=this.hospitalList
      for (let i = 0; i < list.length; i++) {
        let hospitalBillboard = {};
        hospitalBillboard.position = {
          lng: list[i].lon,
          lat: list[i].lat,
        };
        hospitalBillboard.billboard = {
          id: 'hospital_' + i,
          image: "https://file.zouran.top/assets/cesium/img/Hospital.png",
          scale: 0.1
        }
        hospitalBillboard.id='hospital_' + list[i].id
        resultList.push(hospitalBillboard);
      }
      return resultList
    }
  },
  methods:{
    onHospitalClusterEvent(clusteredEntities, cluster){
      cluster.billboard.show = !0
      cluster.label.show = !1
      cluster.billboard.id = cluster.label.id
      cluster.billboard.verticalOrigin = Cesium.VerticalOrigin.CENTER
      clusteredEntities.length >= 300
          ? (cluster.billboard.image = 'https://file.zouran.top/assets/cesium/img/clusters/300+.png')
          : clusteredEntities.length >= 150
              ? (cluster.billboard.image = 'https://file.zouran.top/assets/cesium/img/clusters/150+.png')
              : clusteredEntities.length >= 90
                  ? (cluster.billboard.image = 'https://file.zouran.top/assets/cesium/img/clusters/90+.png')
                  : clusteredEntities.length >= 30
                      ? (cluster.billboard.image = 'https://file.zouran.top/assets/cesium/img/clusters/30+.png')
                      : clusteredEntities.length >= 10
                          ? (cluster.billboard.image = 'https://file.zouran.top/assets/cesium/img/clusters/10+.png')
                          : (cluster.billboard.image = 'https://file.zouran.top/assets/cesium/img/clusters/' + clusteredEntities.length + '.png')
    },
    onHospitalReady ({ cesiumObject }) {
      window.cesiumObject = cesiumObject

      //开启聚合功能
      cesiumObject.clustering.enabled = true
      cesiumObject.clustering.pixelRange = 30
      cesiumObject.clustering.minimumClusterSize = 3
    },
  }
}
</script>
<style scoped>

</style>