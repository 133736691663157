<template>
    <span class="spanText" >房屋倒塌配置：</span>
    <el-button round style="background-color: brown;color: white;" @click="addhousedatafun">添加</el-button>
    <el-button round style="background-color: brown;color: white;" @click="showdestory = true">破坏概率</el-button>
    <el-button round style="background-color: skyblue;color: aliceblue" @click="housesubmit">配置完成</el-button>
    <div style="height: 500px;margin-top: 2%">
        <div style="overflow-y: auto; max-height: 500px;">
            <el-descriptions v-for="(item, index) in structureList" :key="index" :column="3" border style="margin-bottom: 10px">
                <el-descriptions-item label="建筑材料" >
                    {{ item.materiel }}
                </el-descriptions-item>
                <el-descriptions-item label="结构体系" >
                    {{ item.systeme }}
                </el-descriptions-item>
                <el-descriptions-item label="建筑面积" >
                    <el-input size="mini" style="width: 80px" v-model="item.area" placeholder="km^2"></el-input>
                </el-descriptions-item>
                <el-descriptions-item label="精细化分类" >
                    {{ item.classe }}
                </el-descriptions-item>
                <el-descriptions-item label="标签" >
                    {{ item.label }}
                </el-descriptions-item>
                <el-descriptions-item label="操作" >
                    <el-button round type="warning" size="small">删除</el-button>
                    <el-button round type="success" size="small">编辑</el-button>
                </el-descriptions-item>
            </el-descriptions>
        </div>
    </div>
    <el-dialog v-model="showdestory" :modal="false">
        <div>
            <span>建筑物破坏概率设置：</span>
            <el-divider></el-divider>
            <el-form label-position="left" label-width="80px" :model="PjPara" :rules="rulers">
                <el-form-item label="基本完好" prop="level1">
                    <el-input v-model="PjPara.level1" style="width: 20vh"></el-input>
                </el-form-item>
                <el-form-item label="轻微破坏" prop="level2" >
                    <el-input v-model="PjPara.level2" style="width: 20vh"></el-input>
                </el-form-item>
                <el-form-item label="中等破坏" prop="level3">
                    <el-input v-model="PjPara.level3" style="width: 20vh"></el-input>
                </el-form-item>
                <el-form-item label="严重破坏" prop="level4">
                    <el-input v-model="PjPara.level4" style="width: 20vh"></el-input>
                </el-form-item>
                <el-form-item label="倒塌" prop="level5">
                    <el-input v-model="PjPara.level5" style="width: 20vh"></el-input>
                </el-form-item>
            </el-form>
            <el-button @click="setcreate" round type="primary">设置完成</el-button>
        </div>
    </el-dialog>
    <el-dialog v-model="Addhousedata" width="80%" :modal="false">
            <div style="height:400px;overflow-y: auto; ">
                <el-table
                    :data="structuretableData"
                    style="width: 100%">
                    <el-table-column
                        prop="materiel"
                        label="建筑材料"
                        width="100">
                    </el-table-column>
                    <el-table-column
                        prop="systeme"
                        label="结构体系"
                        width="120">
                    </el-table-column>
                    <el-table-column
                        prop="classe"
                        label="精细化分类"
                        show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                        prop="label"
                        label="label"
                        show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column
                        label="操作"
                        width="100">
                        <template v-slot="scope">
                            <el-button type="primary" icon="el-icon-edit" circle @click="addhouse(scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
    </el-dialog>
</template>

<script>
import {houseStructureAll} from "@/axios/api/others-api";
import {myMessage, myNotify} from "@/assets/js/utils";
import {rulerlevel} from "@/assets/js/elform-verify/validate";
export default {
    name: "house-configure",
    data(){
        return{
            structureList: [
                { materiel: 'Material 1', systeme: 'System 1', area: 1000, classe: 'Category 1',label:"GD1" },
                { materiel: 'Material 2', systeme: 'System 2', area: 2000, classe: 'Category 2',label:"GD2" },
                { materiel: 'Material 3', systeme: 'System 3', area: 3000, classe: 'Category 3',label:"GD3" },
                { materiel: 'Material 2', systeme: 'System 2', area: 2000, classe: 'Category 2',label:"GD4" },
                { materiel: 'Material 3', systeme: 'System 3', area: 3000, classe: 'Category 3',label:"GD5" },

            ],
            showdestory:false,
            PjPara:{
                level1:0.5,
                level2:0.3,
                level3:0.1,
                level4:0.08,
                level5:0.02
            },
            rulers:rulerlevel,
            Addhousedata:false,
            structuretableData:[],
            addflag:false,
            testList:[],
        }
    },
    computed:{
        isarea(){
            for (let i=0;i<this.structureList.length;i++){
                if (this.structureList[i].area === ""){
                    return false
                }
            }
                return true
        },
    },
    methods:{
        setcreate(){
            let sum = 0;
            console.log(this.PjPara)
            Object.keys(this.PjPara).forEach(key=>{
                let value = parseFloat(this.PjPara[key])
                sum +=value

            })
            if (sum === 1){
                myMessage.elSuccess('设置成功')
                this.showdestory = false
            }else{
                myMessage.elError('请检查设置的比例，加和期望为1')
            }
        },
        housesubmit(){
            if (!this.isarea){
                myMessage.elWarning('请检查是否设置完成建筑材料面积配置')
            }else {
                myMessage.elSuccess('建筑物倒塌损毁模块配置完成！')
                this.$emit('updatedate',this.structureList,this.PjPara,'house')

            }

        },
        addhouse(row){
            Object.assign(row, { area: '' });
            this.structureList.push(row);
            myNotify.elSuccess('添加成功！')
        },
        addhousedatafun(){
            const that = this
            if(!this.addflag){
                //先将structurelist置空
                this.structureList = []
                this.addflag = true
            }
            houseStructureAll().then(res=>{
                that.testList= res
                that.structuretableData =that.testList.data
            })
            this.Addhousedata = true
        },
    }
}
</script>

<style scoped>
.spanText{
    font-size: larger;
    font-weight: bold;
}

</style>