<template>
  <el-row
    ref="viewerContainer"
  >
    <vc-viewer
      ref="flightVcViewer"
      style="height: 100vh;width: 100vw"
      animation
      should-animate
      timeline
      @ready="onViewerReady"
    >
      <vc-entity
        ref="entity"
        :availability="availability"
        :orientation="orientation"
        :position="trackPositions"
        description="Hello VueCesium"
      >
        <vc-graphics-path
          ref="path"
          :material="{fabric: {type: 'PolylineGlow', uniforms: {glowPower: 0.1, color: 'yellow'}}}"
          :resolution="1"
          :width="10"
          @ready="ready"
          @destroyed="des"
          @definition-changed="cha"
          @before-load="beforeLoad"
        />
        <vc-graphics-model
          ref="model"
          :minimum-pixel-size="0"
          uri="https://file.zouran.top/assets/cesium/glb/plane.glb"
        />
      </vc-entity>
      <vc-entity
        v-for="(item) of photoPositions"
        :key="'entity' + item.filename"
        :position="[item.longitude,item.latitude, item.altitude]"
      >
        <vc-graphics-point
          :outline-width="3"
          :pixel-size="8"
          color="TRANSPARENT"
          outline-color="RED"
        />
      </vc-entity>
      <vc-layer-imagery>
        <vc-imagery-provider-arcgis />
      </vc-layer-imagery>
    </vc-viewer>
    <div class="image-container">
      <el-image
        :src="composeImage"
        :max-scale="7"
        :min-scale="0.2"
        :preview-src-list="srcList"
        :initial-index="4"
        draggable="true"
      />
    </div>
    <el-row class="toolbar">
      <el-button
        round
        @click="viewTopDown"
      >
        俯视
      </el-button>
      <el-button
        round
        @click="viewSide"
      >
        侧视
      </el-button>
      <el-button
        round
        @click="viewAircraft"
      >
        跟随
      </el-button>
      <el-button
        round
        @click="uploadVisible=!uploadVisible"
      >
        上传文件
      </el-button>
      <el-button
        round
        @click="$router.push('/')"
      >
        返回
      </el-button>
    </el-row>
  </el-row>
  <el-dialog
    v-model="uploadVisible"
    title="文件上传"
    width="50%"
  >
    <el-upload
      v-loading="loading"
      drag
      :action="uploadUrl"
      multiple
      method="post"
      :element-loading-text="loadingText"
      :file-list="fileList"
      :on-change="onChange"
      :on-progress="onProgress"
      :on-success="onSuccess"
      :before-upload="beforeUpload"
    >
      <el-icon class="el-icon--upload">
        <upload-filled />
      </el-icon>
      <div class="el-upload__text">
        Drop file here or <em>click to upload</em>
      </div>
    
      <template #tip>
        <div
          class="el-upload__tip"
          style="font-size: initial;text-align: center"
        >
          jpg/png files with a size less than {{ maxSize }} MB
        </div>
      </template>
    </el-upload>
  </el-dialog>
</template>

<script setup>
import {ref, onMounted,watch} from 'vue'
import {UploadFilled} from "@element-plus/icons";
import {img_predict, uploadZipUrl} from "@/axios/api/others-api";
import {changeDateTo, changeToDate, myMessage} from "@/assets/js/utils";
import {getTimeList, labelName} from "@/assets/js/flight-path";
import {baseURLs, http} from "@/axios/http-config";


// data
const predict_preview=baseURLs.imgApi+'/predict_preview/'
const predict=baseURLs.imgApi+'/predict/'
const uploadUrl=ref(uploadZipUrl)
const timeOut=ref(1000)
const composeImage=ref('https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg')
const timeList=ref([])
const composeList=ref([])
const trackPositions = ref({})
const photoPositions = ref([
        {
            "altitude": 2136.987,
            "fileName": "1711192821_100_0046_0008.JPG",
            "latitude": 25.695977,
            "longitude": 99.87274441666666,
            "time": "2021:05:25 17:37:52"
        },
        {
            "altitude": 2137.078,
            "fileName": "1711192821_100_0046_0009.JPG",
            "latitude": 25.696138027777778,
            "longitude": 99.87266358333333,
            "time": "2021:05:25 17:37:54"
        },
        {
            "altitude": 2137.111,
            "fileName": "1711192821_100_0046_0010.JPG",
            "latitude": 25.69619561111111,
            "longitude": 99.87262344444444,
            "time": "2021:05:25 17:37:57"
        },
        {
            "altitude": 2137.068,
            "fileName": "1711192821_100_0046_0011.JPG",
            "latitude": 25.696166722222223,
            "longitude": 99.87249986111111,
            "time": "2021:05:25 17:37:59"
        },
        {
            "altitude": 2137.094,
            "fileName": "1711192821_100_0046_0012.JPG",
            "latitude": 25.696120944444445,
            "longitude": 99.87229913888889,
            "time": "2021:05:25 17:38:02"
        }
    ])
const model = ref(null)
const availability = ref(null)
const orientation = ref(null)
const entity = ref(null)
let _viewer = undefined
const uploadVisible = ref(false)
const fileList = ref([])
const maxSize = ref(1024)
const loading = ref(false)
const loadingText = ref('')
const flightVcViewer=ref(null)
const start=ref(null)
const stop=ref(null)
const srcL=[]
const srcList=ref([
  'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
])
//watches
// watch(photoPositions, async (newQuestion, oldQuestion) => {
//
// })
// methods
const onEntityEvt = e => {
  console.log(e)
}
const ready=(e)=>{
  // console.log(e)
}
const des=(e)=>{
  // console.log(e)
}
const cha=(e)=>{
  // console.log(e)
}
const beforeLoad = (e) => {
  // console.log(e)
}
watch(composeList,async(newList,oldList)=>{
    // console.log(newList.length)
    if (composeList.value.length>0) {
        //发送请求
        const current=composeList.value[0]
        const params={
            'image':current[0],
            'compose':current[1]
        }
        console.log(params)
        myMessage.elSuccess('检测到图像输入,正在识别....')
        var filename = current[0];

        img_predict(predict+'compose/'+filename).then(response=>{
            myMessage.elSuccess('识别成功')
            composeImage.value=predict_preview+response.data.name
            console.log(composeImage.value)
            srcL.push(predict_preview+response.data.name)
            srcList.value=srcL
            composeList.value.splice(0, 1)
        // setTimeout(function() {window.open(that.predict_preview+response.data.name)},1000)
      })
        // imageComposeApi(params).then(
        //     response=>{
        //         //更新图片
        //         composeImage.value=response
        //         srcList.value=[response]
        //         composeList.value.splice(0, 1)
        //     }
        // )

    }
},
{
deep: true,
},
)
const onViewerReady = ({viewer}) => {
  _viewer = viewer
  //Enable lighting based on sun/moon positions
  viewer.scene.globe.enableLighting = true
  //Enable depth testing so things behind the terrain disappear.
  viewer.scene.globe.depthTestAgainstTerrain = true
  updateTrack()
  // console.log(pathToLine(photoPositions.value))
}
const updateTrack = () => {
  const viewer=_viewer
  let property = new Cesium.SampledPositionProperty()
    //设置后续名字
  photoPositions.value=labelName(photoPositions.value)
    // console.log(photoPositions.value)
  // 绘制路径
  for (let i = 0; i < photoPositions.value.length; i++) {
    let time = Cesium.JulianDate.fromDate(changeToDate(photoPositions.value[i].time))
    let position = Cesium.Cartesian3.fromDegrees(
        photoPositions.value[i].longitude,
        photoPositions.value[i].latitude,
        photoPositions.value[i].altitude
    )
    property.addSample(time, position)
    // photoPositions.value=photoPositions.value
  }
  //更新时间列表
  timeList.value=getTimeList(photoPositions.value)
  //设置开始时间
  start.value=Cesium.JulianDate.fromDate(changeToDate(photoPositions.value[0].time))
  stop.value=Cesium.JulianDate.fromDate(changeToDate(photoPositions.value[photoPositions.value.length-1].time))
  trackPositions.value = property
  viewer.clock.startTime = start.value.clone()
  viewer.clock.stopTime = stop.value.clone()
  viewer.clock.currentTime = start.value.clone()
  viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP //Loop at the end
    //时间加快的倍数
  viewer.clock.multiplier = 0.1
  viewer.timeline.zoomTo(start.value, stop.value)
  availability.value = new Cesium.TimeIntervalCollection([
    new Cesium.TimeInterval({
      start: start.value,
      stop: stop.value
    })
  ])
  //监听当前时间轴到达的时间
  viewer.clock.onTick.addEventListener(function (clock) {
    // console.log(clock)
    timeOut.value=1000/(clock.multiplier+0)
      // console.log(timeOut.value)
    let time=clock.currentTime
    time = new Date(time.toString())
    time = changeDateTo(time)
    if(timeList.value.indexOf(time)!==-1) {
      let i
      for ( i = 0; i < photoPositions.value.length; i++) {
        if (photoPositions.value[i].time===time)
        {
            //获取新名字后赋值
            //改变其后名字
            composeList.value.push([photoPositions.value[i].fileName,photoPositions.value[i]['composeName']])
            console.log(time)
            break
        }
      }
      timeList.value.splice(timeList.value.indexOf(time), 1)
      if (i === photoPositions.value.length-2) {
          setTimeout(()=>//重新开始
        {
            timeList.value = getTimeList(photoPositions.value)},timeOut.value)
      }

    }
  })
  orientation.value = new Cesium.VelocityOrientationProperty(trackPositions.value)
}
const viewTopDown = () => {
  _viewer.trackedEntity = undefined
  _viewer.zoomTo(_viewer.entities, new Cesium.HeadingPitchRange(0, Cesium.Math.toRadians(-90)))
}
const viewSide = () => {
  _viewer.trackedEntity = undefined
  _viewer.zoomTo(_viewer.entities, new Cesium.HeadingPitchRange(Cesium.Math.toRadians(-90), Cesium.Math.toRadians(-15)))
}
const viewAircraft = () => {
  _viewer.trackedEntity = entity.value.getCesiumObject()
}

// life cycle
onMounted(() => {
  model.value.creatingPromise.then(({Cesium, viewer}) => {
    viewer.zoomTo(viewer.entities)
  })
})
const onChange = (file, list) => {
  if (file.status === "success") {
    myMessage.elSuccess('上传成功！')
  }
  if (list.length > 1 && file.status !== "fail") {
    list.splice(0, 1);
  } else if (file.status === "fail") {
    myMessage.elError('上传失败，请重新上传！')
    list.splice(0, 1);
  }
}
const beforeUpload = (file) => {
  return true
  let sizeIsValid = false, formatIsValid = false
  if (file.size / 1024 / 1024 < 1024)
    sizeIsValid = true
  else myMessage.elError('上传文件大小不能超过1024MB')
  const suffix = file.name.split('.')[1]
  if (suffix === 'zip' ) {
    formatIsValid = true
  } else {
    myMessage.elError('上传文件格式错误')
  }
  return sizeIsValid && formatIsValid
}
const onProgress = (evt) => {
  console.log(evt)
}
const onSuccess = (response) => {
  if(response.code!==200) {
    myMessage.elError(response.message)
    return
  }
  myMessage.elSuccess(response.message)
  // loading.value = true
  photoPositions.value=response.data
  updateTrack()
  viewAircraft()
  uploadVisible.value=false
}
</script>

<style scoped>
.toolbar {
  position: absolute;
  left: 1%;
  top: 1%;
  /*min-width: 185px;*/
  z-index: 100;
  color: #fff !important;
  background-color: rgba(0, 0, 0, .2);
}
.composeImage{
    border: 10px solid white;;
    border-radius: 10px;
//padding: 10px;
  position: absolute;
  right: 0;
  bottom: 3%;
  max-height: 40vh;
  max-width: 30vw;
}
/deep/ .el-upload{
  width: 100%;
}
/deep/ .el-upload .el-upload-dragger{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.image-container {
  border: 10px solid white;;
    border-radius: 10px;
//padding: 10px;
  position: absolute;
  right: 0;
  bottom: 3%;
  max-height: 40vh;
  max-width: 30vw;
  overflow: auto; /* 当图片的宽度超过容器时，会出现滚动条 */
}

</style>
