<template>
  <!--hyc:消防队位置-->
  <vc-datasource-custom
    :show="show"
    name="fireCenterList"
    :entities="fireCenterBillboards"
    @cluster-event="onFireCenterClusterEvent"
    @ready="onFireCenterReady"
    @mouseout="onMouseout"
    @mouseover="onMouseover"
  />
</template>
<script>
export default {
  name: "CesiumDistributionPoint",
  props:{
    fireCenterWeightedList:{
      type:Array,
      default: ()=>[{
        "fireId": 11627,
        "fireLon": 114.703487,
        "fireLat": 23.760898,
        "fireName": "河源市消防指挥中心",
        "fireAddress": "东华路永福西路",
        "fireCenterWeight": 0.20407603568016142
      }]
    },
    show:{
      type:Boolean,
      default: false
    },
    distributionSum:{
      type:Number,
      default:100
    }
  },
  data(){
    return{
    }
  },
  computed:{
    fireCenterBillboards(){
      const resultList=[]
      const list=this.fireCenterWeightedList
      let sum=0;
      for(let i=0; i<list.length; i++){
        let billboard={};
        billboard.position={
          lng:list[i].fireLon,
          lat:list[i].fireLat,
        }
        billboard.billboard={
          image: "https://file.zouran.top/assets/cesium/img/fireCenter.png",
          scale: 0.1,
          weight: 1/(1-list[i].fireCenterWeight),
          pixelOffset:{x: 0, y: -45}
        }
        billboard.id=
            sum+=billboard.billboard.weight;
        resultList.push(billboard);
      }
      for(let i=0;i<resultList.length;i++){
        let fireWeight={};
        fireWeight.position=resultList.position;
        resultList[i].label={
          text:"所要分配的物资数量为:"+Math.floor((resultList[i].billboard.weight/sum)*this.distributionSum).toString()+"个"
        }
        resultList[i].id="fireCenter_"+Math.floor((resultList[i].billboard.weight/sum)*this.distributionSum).toString()+"_"+i;
        // fireWeight1.text="所要分配的物资数量为:"+Math.floor((that.fireCenterBillboards[i].billboard.weight/sum)*this.DistributionSum).toString()+"个";
        // that.fireWeight.push(fireWeight1);
      }
      return resultList
    }
  },
  methods:{
    onFireCenterReady ({ cesiumObject }) {
      window.cesiumObject = cesiumObject

      //开启聚合功能
      cesiumObject.clustering.enabled = true
      cesiumObject.clustering.pixelRange = 30
      cesiumObject.clustering.minimumClusterSize = 2
    },
    onFireCenterClusterEvent(clusteredEntities, cluster){
      cluster.billboard.show = !0
      cluster.label.show = !1
      cluster.billboard.id = cluster.label.id
      cluster.billboard.verticalOrigin = Cesium.VerticalOrigin.CENTER
      clusteredEntities.length >= 300
          ? (cluster.billboard.image = 'https://file.zouran.top/assets/cesium/img/clusters/300+.png')
          : clusteredEntities.length >= 150
              ? (cluster.billboard.image = 'https://file.zouran.top/assets/cesium/img/clusters/150+.png')
              : clusteredEntities.length >= 90
                  ? (cluster.billboard.image = 'https://file.zouran.top/assets/cesium/img/clusters/90+.png')
                  : clusteredEntities.length >= 30
                      ? (cluster.billboard.image = 'https://file.zouran.top/assets/cesium/img/clusters/30+.png')
                      : clusteredEntities.length > 10
                          ? (cluster.billboard.image = 'https://file.zouran.top/assets/cesium/img/clusters/10+.png')
                          : (cluster.billboard.image = 'https://file.zouran.top/assets/cesium/img/clusters/' + clusteredEntities.length + '.png')
    },
    //物资分配特效
    onMouseover(e){
      if (e.cesiumObject instanceof Cesium.Label) {
        this.scale = 1.5 // or e.cesiumObject.scale = 1.5
        e.pickedFeature.primitive.scale = 1.5
      } else if (e.cesiumObject instanceof Cesium.LabelCollection) {
        e.pickedFeature.primitive.scale = 1.5
      }
    },
    onMouseout(e){
      if (e.cesiumObject instanceof Cesium.Label) {
        this.scale = 1 // or e.cesiumObject.scale = 1
      } else if (e.cesiumObject instanceof Cesium.LabelCollection) {
        e.pickedFeature.primitive.scale = 1
      }
    },
  }
}
</script>
<style scoped>

</style>