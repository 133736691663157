<template>
  <!--        救援力量位置-->
    <vc-datasource-custom
            :show="showSauverForce"
            name="sauverforcelist"
            :entities="forcePositions"
            @cluster-event="onSauverCluterEnvent"
            @ready="onSauverReady"
            @click="handleClick"
    >
    </vc-datasource-custom>
</template>

<script>


import {getPointGoodsByid} from "@/axios/api/others-api";

export default {
    name: "cesium-force-point",
    props:{
        showSauverForce:{
            type:Boolean,
            default: false
        },
        forcePosition:{
            type:Array,
            default: ()=>[{
                "id": 1,
                "lontitude": 114.455128,
                "latitude": 23.927171
            }]
        }
    },
    data(){
        return{

        }
    },
    computed:{
        forcePositions(){
            const resultList=[]
            const list=this.forcePosition
            for (let i = 0; i < list.length; i++) {
                let tempforceposition = {};
                tempforceposition.position = {
                    lng: list[i].lontitude,
                    lat: list[i].latitude,
                };
                tempforceposition.billboard = {
                    id: 'force_' + i,
                    image: "https://www.xuuyy.icu/processimg/boy-7.png",
                    scale: 0.06
                }
                tempforceposition.id='force_' + list[i].id
                resultList.push(tempforceposition);
            }
            return resultList
        }
    },
    methods:{
        onSauverCluterEnvent(){

        },
        onSauverReady(){

        },
        handleClick(entity) {
            // 打印当前点击点的所有信息
            console.log("Clicked entity:", entity.surfacePosition);
            const cartesian = entity.surfacePosition;
            // 检查cartesian是否存在
            if (cartesian) {
                const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
                const longitude = Cesium.Math.toDegrees(cartographic.longitude);
                const latitude = Cesium.Math.toDegrees(cartographic.latitude);
                console.log(this.forcePosition)
                for (let i = 0;i<this.forcePosition.length;i++){
                    if (longitude.toFixed(2) === this.forcePosition[i].lontitude.toFixed(2)
                        || latitude.toFixed(2) === this.forcePosition[i].latitude.toFixed(2)
                    ){
                        this.showGoodsbox(this.forcePosition[i].id)
                    }
                }
            } else {
                console.log("Cartesian position not available for this entity.");
            }
        },
        showGoodsbox(id){
            console.log(id)
            let that = this
            getPointGoodsByid(id).then(res=>{
                that.$emit("opendetailbox",res)
            })

        }
    }
}
</script>

<style scoped>

</style>