<template>
    <el-dialog
            title="烈度评估"
            width="35%"
            v-model="showintensity"
            append-to-body>
        <el-divider style="margin-top: 0"></el-divider>
        <div style="width: 100%; height: 50vh">
            <div style="display: flex; align-items: center;">
                <el-tooltip :content="textIntro" effect="dark" >
                    <el-input
                            disabled
                            style="width: 350px; margin-right: 20px;font-size: small;font-weight: bolder"
                            placeholder="模型公式："
                            v-model="inputmodule"
                            prefix-icon="el-icon-data-analysis"
                    >
                    </el-input>
                </el-tooltip>
                <el-select
                        v-model="selectValue"
                        clearable
                        placeholder="Select"
                        style="width: 150px;position: absolute;right: 20px"
                >
                    <el-option
                            v-for="item in options2"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                    />
                </el-select>
            </div>
            <el-row >
                <el-col :span = "12">
                    <div class="ParaModule">
                            <span style="font-weight: bolder;font-size: 1.1em">
                                沿长轴方向：
                            </span>
                        <span class="spanPara">L1:
                                 <el-space>
                                <el-input-number v-model="ParaFormL[0]" controls-position="right" size="small" :precision="4"
                                                 :step="valueStep"
                                />
                                <template #decrease-icon>
                                    <el-icon>
                                        <Minus />
                                    </el-icon>
                                </template>
                                 </el-space>
                            </span>
                        <span class="spanPara">L2:
                            <el-space>
                                <el-input-number v-model="ParaFormL[1]" controls-position="right" size="small" :precision="4"
                                                 :step="valueStep"
                                />
                                <template #decrease-icon>
                                    <el-icon>
                                        <Minus />
                                    </el-icon>
                                </template>
                                 </el-space></span>
                        <span class="spanPara">L3:
                            <el-space>
                                <el-input-number v-model="ParaFormL[2]" controls-position="right" size="small" :precision="4"
                                                 :step="valueStep"
                                />
                                <template #decrease-icon>
                                    <el-icon>
                                        <Minus />
                                    </el-icon>
                                </template>
                                 </el-space></span>
                        <span class="spanPara">L4:
                            <el-space>
                                <el-input-number v-model="ParaFormL[3]" controls-position="right" size="small" :precision="4"
                                                 :step="valueStep"
                                />
                                <template #decrease-icon>
                                    <el-icon>
                                        <Minus />
                                    </el-icon>
                                </template>
                                 </el-space></span>
                    </div>
                </el-col>
                <el-col :span = "12">
                    <div class="ParaModule">
                             <span style="font-weight: bolder;font-size: 1.1em">
                                沿短轴方向：
                            </span>
                        <span class="spanPara">R1:
                                <el-space>
                                 <el-input-number v-model="ParaFormR[0]" controls-position="right"  size="small" :precision="4"
                                                  :step="valueStep">
                                <template #decrease-icon>
                                    <el-icon>
                                    <Minus />
                                    </el-icon>
                                </template>
                                <template #increase-icon>
                                <el-icon>
                                    <Plus />
                                    </el-icon>
                                </template>
                                </el-input-number>
                                    </el-space>
                            </span>
                        <span class="spanPara">R2:
                                <el-space>
                                 <el-input-number v-model="ParaFormR[1]" controls-position="right"  size="small" :precision="4"
                                                  :step="valueStep">
                                <template #decrease-icon>
                                    <el-icon>
                                    <Minus />
                                    </el-icon>
                                </template>
                                <template #increase-icon>
                                <el-icon>
                                    <Plus />
                                    </el-icon>
                                </template>
                                </el-input-number>
                                    </el-space>
                            </span>
                        <span class="spanPara">R3:
                                <el-space>
                                 <el-input-number v-model="ParaFormR[2]" controls-position="right"  size="small" :precision="4"
                                                  :step="valueStep">
                                <template #decrease-icon>
                                    <el-icon>
                                    <Minus />
                                    </el-icon>
                                </template>
                                <template #increase-icon>
                                <el-icon>
                                    <Plus />
                                    </el-icon>
                                </template>
                                </el-input-number>
                                    </el-space>
                            </span>
                        <span class="spanPara">R4:
                                <el-space>
                                 <el-input-number v-model="ParaFormR[3]" controls-position="right"  size="small" :precision="4"
                                                  :step="valueStep">
                                <template #decrease-icon>
                                    <el-icon>
                                    <Minus />
                                    </el-icon>
                                </template>
                                <template #increase-icon>
                                <el-icon>
                                    <Plus />
                                    </el-icon>
                                </template>
                                </el-input-number>
                                    </el-space>
                            </span>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 10vh;display: flex">
                <el-col :span = 4>
                        <span class="spanPara1" style="">
                            步长：
                        </span>
                </el-col>
                <el-select
                        v-model="valueStep"
                        clearable
                        placeholder="Select"
                        style="width: 100px;position: relative;top: 40px;left: 10px"
                >
                    <el-option
                            v-for="item in options3"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                    />
                </el-select>
                <el-col :span = 5 >
                        <span class="spanPara1" style=" ">
                            震中烈度：
                        </span>
                </el-col>
                <el-select
                        v-model="valueInitenty"
                        clearable
                        placeholder="Select"
                        style="width: 100px;position: relative;top: 40px;left: 10px"
                >
                    <el-option
                            v-for="item in options4"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                    />
                </el-select>
            </div>
        </div>
        <span  class="dialog-footer">
    <el-button @click="showintensity = false" round>取 消</el-button>
    <el-button type="primary" @click="intensityend()" round>确 定</el-button>
    </span>
    </el-dialog>
</template>
<script>
import {computedR} from "@/axios/api/others-api";
export default {
    name: "intentity-estimate",
    props:{
       mPara:{
           type:String,
           required:true
       }
    },
    data(){
        return{
            showintensity:false,
            textIntro:"",
            valueStep:0.001,
            inputmodule:"",
            selectValue:"",
            valueInitenty:1,
            resultintentity:0.0,
            options3: [{value: 1, label: '1'}, {value: 0.1, label: '0.1'}, {value: 0.01, label: '0.01'}, {value: 0.001, label: '0.001'}, {value: 0.0001, label: '0,0001'}, {value: 0.00001, label: '0.00001'},],
            options2: [{value: 0, label: '椭圆衰减模型快速评估烈度公式'}],
            initentyForm:[
                "I = C1 + C2*M + C3*ln(R + C4)",
            ],
            inTrodecuForm:[
                "C1、C2、C3、C4均为参数，M为里氏震级，R为距离震中的距离",
            ],
            ParaFormL:[7.1410, 1.6580, -2.3350, 57.48],
            ParaFormR:[4.0270, 1.2180, -1.3180, 8.88],
            options4: [{value: 1, label: 'Avg'}, {value: 2, label: 'Max'}, {value: 3, label: 'Min'},
            ],
            tempintensityList:[],
            paraList1:{
                "longRadius": 0.0,
                "shortRadius": 0.0,
                "angle": 0.6,
                "intensity": 0,
                "earthquakeId": "1474"
            },
            paraList2:{
                "longRadius": 0.0,
                "shortRadius": 0.0,
                "angle": 0.6,
                "intensity": 0,
                "earthquakeId": "1474"
            },
            paraList3:{
                "longRadius": 0.0,
                "shortRadius": 0.0,
                "angle": 0.6,
                "intensity": 0,
                "earthquakeId": "1474"
            },

        }
    },
    watch:{
        selectValue(newVal){
            this.inputmodule = this.initentyForm[newVal];
            this.textIntro = this.inTrodecuForm[newVal];
        },
    },
    methods:{
        intensityend(){
            const that = this
            let Ia = this.ParaFormL[0] + this.ParaFormL[1]*parseFloat(this.mPara)+this.ParaFormL[2]*Math.log(0+this.ParaFormL[3]);
            let Ib = this.ParaFormR[0] + this.ParaFormR[1]*parseFloat(this.mPara)+this.ParaFormR[2]*Math.log(0+this.ParaFormR[3]);
            let paraList = {
                ia:Ia,
                ib:Ib,
                mpara: parseFloat(this.mPara),
                paraForml:this.ParaFormL,
                paraFormr:this.ParaFormR,
            }
            if (this.valueInitenty === 1){
                this.resultintentity = (Ia+Ib)/2;
            }else if(this.valueInitenty === 2){
                this.resultintentity = Math.max(Ia,Ib);
            }else if(this.valueInitenty === 3){
                this.resultintentity =Math.min(Ia,Ib);
            }
            //返回烈度值
            this.$emit("updatenuminten",this.resultintentity)
            //计算出内外椭圆半径
            computedR(paraList).then(res=>{
                that.rlist = res
                for (let i = 0;i<3;i++){
                    that.tempintensityList[i] = that.paralistfun(this.rlist[2*i],that.rlist[2*i+1],that.resultintentity-i)
                }
                that.paraList1 = that.tempintensityList[0]
                that.paraList2 = that.tempintensityList[1]
                that.paraList3 = that.tempintensityList[2]
                this.$emit("updateList",that.paraList1,that.paraList2,that.paraList3,'')
            })
            this.showintensity = false
        },
        paralistfun(ra,rb,resultintentitytemp){
            if (resultintentitytemp>0){
                return {
                    "longRadius": ra,
                    "shortRadius": rb,
                    "angle": 0.6,
                    "intensity": resultintentitytemp,
                    "earthquakeId": "1474"
                }
            }else {
                return {
                    "longRadius": 0.0,
                    "shortRadius": 0.0,
                    "angle": 0.6,
                    "intensity": 0,
                    "earthquakeId": "1474"
                }
            }
        },
    },

}
</script>

<style scoped>
.ParaModule{
    margin-top: 20px;
    height: 25vh;
    display: flex;
    flex-direction: column;

}
.spanPara1{
    font-size: 1.2em;
    font-weight: bolder;
    position: relative;
    top: 50px;

    margin-left: 30px;
}
.spanPara{
    font-size: 1em;
    font-weight: bolder;
    margin-left: 30px;
    margin-top: 20px;
}
</style>