<template>
  <el-dialog
    v-model="predictResultVisible"
    title="道路裂缝识别结果"
    width="68vw"
    center
    top="2.5vh"
  >
    <el-descriptions
      :title="niha1"
      :column="2"
      border
    >
      <el-descriptions-item>
        <template #label>
          <i class="el-icon-user" />
          图像拍摄地址
        </template>
        {{ predictResultInfo.information.location }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <i class="el-icon-s-data" />
          经纬度
        </template>
        ({{ predictResultInfo.information.longitude }},{{ predictResultInfo.information.latitude }})
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <i class="el-icon-location-outline" />
          拍摄高度
        </template>
        {{ predictResultInfo.information.altitude }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <i class="el-icon-tickets" />
          拍摄时间
        </template>
        {{ predictResultInfo.information.time }}
      </el-descriptions-item>
    </el-descriptions>
    <br>
    <el-row
      :gutter="20"
      align="center"
    >
      <el-col :span="12">
        <el-image
          style="width: 300px; height: 300px"
          :src="predictResultInfo.url.origin"
          :fit="fit"
          :preview-src-list="imageList"
        />
      </el-col>
      <el-col :span="12">
        <el-image
          style="width: 300px; height: 300px"
          :src="predictResultInfo.url.predict"
          :fit="fit"
          :preview-src-list="imageList"
        />
      </el-col>
    </el-row>
  </el-dialog>
</template>
    
    <script>
    
    export default {
      name: "RoadCrackPredictResult",
        // eslint-disable-next-line vue/require-prop-types
      props:['predictResultInfo'],
      data(){
        return {
          predictResultVisible:false,
          fit:'scale-down'
        }
      },
      computed:{
        imageList(){
          const images=[
            this.predictResultInfo.url.origin,
            this.predictResultInfo.url.predict,
          ]
          return images
        }
      },
      methods:{
        
        }
    }
    </script>
    
    <style scoped>
    .circle {
      border-radius: 50%;
      width: 63px;
      height: 63px;
      background: var(--color);
      /* 宽度和高度需要相等 */
    }
    .img{
      height: 300px;
      width: 400px;
      margin-left: 25%;
      margin-right: 25%;
    }
    </style>
    