<template>
  <vc-entity
    ref="entity"
    :position="[location.longitude, location.latitude]"
    description="您所点击的位置所表示的区域"
  >
    <vc-graphics-ellipse
      :material="[0, 255, 0, 125]"
      :semi-major-axis="50.0"
      :semi-minor-axis="50.0"
    />
    <vc-graphics-label
      :pixel-offset="[0, 20]"
      fill-color="red"
      font="20px sans-serif"
      text="起点"
    />
  </vc-entity>
</template>
<script>
export default {
  name: "CesiumTempPoint",
  props: {
    location: {
      type: Object,
      default: () => ({
        longitude:100.246377,
        latitude:25.596945
      })
    }
  }
}
</script>
<style scoped>

</style>