<template>
  <!--      加载三维重建模型-->
  <!--      https://file.zouran.top/-->
  <vc-primitive-tileset
    ref="primitive"
    :url="url"
    :show="show"
    @ready-promise="onReadyPromise"
    @click="onClicked"
  />
</template>
<script>
export default {
  name: "CesiumTileset",
  props:{
    url:{
      type:String,
      default: 'https://file.zouran.top/smart3d_las/tileset.json'
    },
    show:{
      type:Boolean,
      default: false
    }
  },
  emits:['onReadyPromise','onClicked'],
  methods:{
    onReadyPromise(tileset) {
      const cartographic = Cesium.Cartographic.fromCartesian(tileset.boundingSphere.center)
      const surface = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, cartographic.height)
      const offset = Cesium.Cartesian3.fromRadians(cartographic.longitude-0.000009, cartographic.latitude+0.000004, 0)
      const translation = Cesium.Cartesian3.subtract(offset, surface, new Cesium.Cartesian3())
      tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation)
    },

    onClicked(e) {
      let cartographic = Cesium.Cartographic.fromCartesian(e.surfacePosition);
      console.log('lng:',Cesium.Math.toDegrees(cartographic.longitude),',lat:', Cesium.Math.toDegrees(cartographic.latitude))
    },
  }
}
</script>
<style scoped>

</style>