<template>
    <div style="height: 58vh; width: 58vh;margin: 2%" id="main"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
    name: "economic-loss",
    props:{
      econimiclossAll:{
          type:Object,
          required:true
      }
    },
    data(){
        return{
            myChart:null,
            option: {
                title: {
                    text: '经济损失统计图',
                    subtext: '结果仅供参考',
                    left: 'center'
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left'
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: '50%',
                        data: [
                            { value: 735, name: '建筑装修' },
                            { value: 580, name: '室内财产' },
                            { value: 484, name: '基础设施' },
                            { value: 300, name: '主体结构' },
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            },
        }
    },
    watch:{
        econimiclossAll:{
            handler(newVal) {
            console.log(newVal)
                let turnstr = ['paraLd','paraLc','paraLf','paraLs']
            for (let i = 0;i<4;i++){
                this.option.series[0].data[i].value = newVal[turnstr[i]]
            }
            // let i = 0;
            // Object.keys(newVal).forEach(key=>{
            //     if(key !=='paraL'){
            //         this.option.series[0].data[i++].value = newVal[key]
            //     }
            // })
            this.destoryEcharts()
            this.createEcharts()
        },
        deep:true
        }
    },
    mounted() {
        this.createEcharts()
    },
    methods:{
        createEcharts() {
            let chartDom = document.getElementById('main');
            this.myChart = echarts.init(chartDom);
            this.myChart.setOption(this.option);
        },
        destoryEcharts() {
            if (this.myChart) {
                this.myChart.dispose();
                this.myChart = null;
            }
        },
    }
}
</script>

<style scoped>

</style>