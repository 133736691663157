<template>
  <!-- 烈度图 -->
  <vc-datasource-custom
    name="intensity"
    :show="show"
  >
    <vc-entity
      v-for="(item,index) in selectedEarthquakeInfo.intensityLineList "
      :id="'intensity_'+index"
      :key="index"
      :position="[selectedEarthquakeInfo.longitude,selectedEarthquakeInfo.latitude,0]"
      :description="'最外圈烈度:'+item.intensity"
    >
      <vc-graphics-ellipse
        :semi-minor-axis="item.longRadius*1000"
        :semi-major-axis="item.shortRadius*1000"
        :material="[255, (10-item.intensity)*30, 0, 125]"
        :rotation="item.angle"
        :fill="true"
      />
    </vc-entity>
  </vc-datasource-custom>
</template>
<script>
export default {
  name: "CesiumEarthquakeIntensity",
  props:{
    show:{
      type:Boolean,
      default: false
    },
    selectedEarthquakeInfo: {
      type:Object,
      default:()=>({
      "earthquakeId": 1474,
      "earthquakeName": "云南大理州漾濞县",
      "magnitude": 6.4,
      "highIntensity": 8.308250979049514,
      "longitude": 99.87,
      "latitude": 25.67,
      "depth": 8.0,
      "earthquakeTime": "2021-05-21 21:48:34",
      "intensityLineList": [
    {
      "longRadius": 4.831947699520125,
      "shortRadius": 2.1831727770881724,
      "angle": 0.6,
      "intensity": 8,
      "earthquakeId": "1474"
    },
    {
      "longRadius": 27.59874969680684,
      "shortRadius": 14.267550354961134,
      "angle": 0.6,
      "intensity": 7,
      "earthquakeId": "1474"
    },
    {
      "longRadius": 67.17692145364093,
      "shortRadius": 40.692466450766986,
      "angle": 0.6,
      "intensity": 6,
      "earthquakeId": "1474"
    }
  ],
})
    },
  },
  methods:{
    uuid(){
      const s = [];
      const hexDigits = '0123456789abcdef';
      for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.charAt(Math.floor(Math.random() * 0x10));
      }
      s[14] = '4';
      s[19] = hexDigits.charAt((parseInt(s[19], 16) & 0x3) | 0x8);
      s[8] = s[13] = s[18] = s[23] = '-';
      return s.join('');
    },
    pointcolor(item){
      // console.log(item.magnitude)
      if(4>item.magnitude) return '#efe8e8'
      else if(5>item.magnitude) return '#b4f19a'
      else if(6>item.magnitude) return '#f3a201'
      else return '#ff263c'
    }
  }
}
</script>
<style scoped>

</style>