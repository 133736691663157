<template>
<div style="height: 280px;width: 300px" id="main2"></div>
</template>

<script>
import * as echarts from "echarts";

export default {
    name: "economic-lossnew",
    props:{
      economiclossnum:{
          type:Number,
          required:true
      }
    },
    data(){
        return{
            myChart:null,
            option: {
                title: {
                    text: '总损失估计：',
                    left: 'center',
                    top: 10,
                    textStyle: {
                        color: '#ccc'
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                visualMap: {
                    show: false,
                    min: 80,
                    max: 600,
                    inRange: {
                        colorLightness: [0, 1]
                    }
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: '55%',
                        center: ['50%', '50%'],
                        data: [
                            {value: 335, name: 'Direct'},
                            {value: 274, name: 'Union Ads'},
                            {value: 235, name: 'Video Ads'},
                            {value: 400, name: 'Search Engine'}
                        ].sort(function (a, b) {
                            return a.value - b.value;
                        }),
                        roseType: 'radius',
                        label: {
                            color: 'rgba(255, 255, 255, 0.3)'
                        },
                        labelLine: {
                            lineStyle: {
                                color: 'rgba(255, 255, 255, 0.3)'
                            },
                            smooth: 0.2,
                            length: 10,
                            length2: 20
                        },
                        itemStyle: {
                            color: '#c23531',
                            shadowBlur: 200,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        },
                        animationType: 'scale',
                        animationEasing: 'elasticOut',
                        animationDelay: function (idx) {
                            return Math.random() * 200;
                        }
                    }
                ]
            }
        }
    },
    computed:{
      echartsData(){
          let data = [];
          let num = this.economiclossnum
          data = [
              {value: num*0.45, name: '建筑损失'},
              {value:num*0.26, name: '室内装修'},
              {value:num*0.20, name: '室内财产'},
              {value: num*0.09, name: '基础设施'}
          ].sort(function (a, b) {
              return a.value - b.value;
          })
          return data
      }
    },
    mounted() {
        this.createEcharts()
    },
    watch:{
        economiclossnum(newVal){
            this.destoryEcharts();
            this.createEcharts()
        }
    },
    methods:{
        createEcharts() {
            let chartDom = document.getElementById('main2');
            this.myChart = echarts.init(chartDom);
            this.option.title.text = "总损失估计："+this.economiclossnum.toFixed(2)+"W 元"
            this.option.visualMap.max = this.economiclossnum
            this.option.series[0].data= this.echartsData
            this.myChart.setOption(this.option);
        },
        destoryEcharts() {
            if (this.myChart) {
                this.myChart.dispose();
                this.myChart = null;
            }
        },
        refreshecharts(){
            this.destoryEcharts()
            this.createEcharts()
        }

    }
}
</script>

<style scoped>

</style>