<template>
  <el-dialog
    v-model="dialogVisible"
    title="地震灾情快速评估"
    width="68vw"
    center
    top="2.5vh"
  >
    <el-descriptions
      :title="earthquake.earthquakeName"
      :column="2"
      border
    >
      <el-descriptions-item>
        <template #label>
          <i class="el-icon-user" />
          地震名称
        </template>
        {{ earthquake.earthquakeName }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <i class="el-icon-s-data" />
          震级
        </template>
        {{ earthquake.magnitude }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <i class="el-icon-location-outline" />
          震源经纬度
        </template>
        ({{ earthquake.longitude }},{{ earthquake.latitude }})
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <i class="el-icon-tickets" />
          最高烈度
        </template>
        {{ earthquake.highIntensity }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template #label>
          <i class="el-icon-office-building" />
          地震发生时间
        </template>
        {{ earthquake.earthquakeTime }}
      </el-descriptions-item>
    </el-descriptions>
    <br>
    <el-collapse>
      <el-collapse-item name="1">
        <template #title>
          估算的人口密度：&nbsp;{{ estimate.population }}人/km²（计算结果仅供参考)
        </template>
        <div>
          我们以建筑物损坏率为主要参数，选择指数函数作为地震人员伤亡的基本模型。测定结果如下公式所示。
          <br>
          <code>N = 0.461 * αm * αden * αtime * αall * e^(12.285⋅Bdr)</code>
          <br>
          其中N为伤亡人数，Bdr为建筑物损坏比例，αm,αden、αtime和αall分别为地震震级和强度、人口密度、地震发生时间和区域整体抗震水平的修正系数。
          <br>
          而对于经济损失方面的预估，我们选用了基于震中烈度的评估模型
          <br>
          <code>lg L = 0.844 44I － 1.831</code>
          <br>
          L 为地震直接经济损失，I 为震中烈度.
          利用 MATLAB 进行拟合优度分析，确定系数 R － square = 0． 957 6 ＞ 0． 95，说明此次拟合结果良好
        </div>
      </el-collapse-item>
    </el-collapse>
    <br>
    <el-carousel>
      <el-carousel-item
        v-for="index in indexs"
        :key="index"
      >
        <div style="height: 32.5vh">
          <div v-if="index.key==='dataIndex'">
            <div style="float: left;">
              <div
                :style="{'--color': ecoColor}"
                style="float: left"
                class="circle"
              />
              <p style="font-size: large">
                &nbsp;预估经济损失:{{ estimate.predictEconomy }}亿元
              </p>
              <br>
              <el-image src="https://file.zouran.top/imgs/predictdeath.png" />
            </div>
            <div style="float: right;">
              <div
                :style="{'--color': deathColor}"
                style="float:left;"
                class="circle"
              />
              <p style="font-size: large">
                &nbsp;预估死亡人数:{{ estimate.predictDeath }}人
              </p>
              <br>
              <el-image src="https://file.zouran.top/imgs/predictdeath.png" />
            </div>
          </div>
          <div v-if="index.key==='imgsIndex'">
            <el-image
              :src="index.url"
              :fit="fit"
              class="img"
            />
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </el-dialog>
</template>

<script>
import { getEstimateResultByEarthquakeId } from '@/axios/api/others-api'
import {myMessage} from "@/assets/js/utils";

export default {
  name: "EstimateEarthquake",
    // eslint-disable-next-line vue/require-prop-types
  props:['earthquake'],
  data(){
    return {
      fit:'scale-down',
      indexs:[
        {
          key:'dataIndex'
        },
        {
          key:'imgsIndex',
          url:'https://file.zouran.top/imgs/fenxi/pic1.jpg'
        },
        {
          key:'imgsIndex',
          url:'https://file.zouran.top/imgs/fenxi/pic2.jpg'
        },
        {
          key:'imgsIndex',
          url:'https://file.zouran.top/imgs/fenxi/pic3.jpg'
        }
      ],
      test:false,
      dialogVisible:false,
      analyzing:false,
      estimate:{
        predictDeath:'',
        predictEconomy:'',
        population:'',
      },
      deathColor: '#00B14E',
      ecoColor: '#00B14E',
    }
  },
  methods:{
    getEarthquakeSituation(){
      let self=this
      this.analyzing=true
      getEstimateResultByEarthquakeId(this.earthquake.earthquakeId)
          .then(res=>{
            let temp_analyze = res.data;
            let temp_predictDeath= temp_analyze.predictDeath;
            let temp_predictEconomy = temp_analyze.predictEconomy;
            self.estimate.population = temp_analyze.population;
            if(temp_predictDeath <= 1){
              self.estimate.predictDeath = '0-1';
              self.deathColor = '#00B14E'
            }
            else if(temp_predictDeath <= 10){
              self.estimate.predictDeath = '1-10';
              self.deathColor = '#FFFF00'
            }
            else if(temp_predictDeath <= 100){
              self.estimate.predictDeath = '10-100';
              self.deathColor = '#FFFF00'
            }
            else if(temp_predictDeath <= 1000){
              self.estimate.predictDeath = '100-1000';
              self.deathColor = '#FF9900'
            }
            else {
              self.estimate.predictDeath = '大于1000';
              self.deathColor = '#FF0000'
            }
            if(temp_predictEconomy <= 1){
              self.estimate.predictEconomy = temp_predictEconomy;
              self.ecoColor = '#00B14E'
            }
            else if(temp_predictEconomy <= 10){
              self.estimate.predictEconomy = temp_predictEconomy;
              self.ecoColor = '#FFFF00'
            }
            else if(temp_predictEconomy <= 100){
              self.estimate.predictEconomy = temp_predictEconomy;
              self.ecoColor = '#FFFF00'
            }
            else if(temp_predictEconomy <= 1000){
              self.estimate.predictEconomy = temp_predictEconomy;
              self.ecoColor = '#FF9900'
            }
            else {
              self.estimate.predictEconomy = temp_predictEconomy;
              self.ecoColor = '#FF0000'
            }
            self.dialogVisible = true;
            self.analyzing=false
          })
          .catch(()=>{
            self.analyzing=false
            myMessage.elWarning("暂时不支持该地区的灾情分析！")
          })
    },
  }
}
</script>

<style scoped>
.circle {
  border-radius: 50%;
  width: 63px;
  height: 63px;
  background: var(--color);
  /* 宽度和高度需要相等 */
}
.img{
  height: 300px;
  width: 400px;
  margin-left: 25%;
  margin-right: 25%;
}
</style>
