<template>
  <RoadCrackPredictResult
    ref="RoadCrackPredictResult"
    :predict-result-info="predictResultInfo"
  />
  <el-dialog
    v-model="predictVisible"
    title="道路裂缝识别"
    width="50%"
  >
    <el-upload
      ref="upload"
      v-loading="loading"
      drag
      :action="upload"
      multiple
      method="post"
      :element-loading-text="loadingText"
      :file-list="fileList"
      :on-change="onChange"
      :on-progress="onProgress"
      :on-success="onSuccess"
      :before-upload="beforeUpload"
    >
      <el-icon class="el-icon--upload">
        <upload-filled />
      </el-icon>
      <div class="el-upload__text">
        Drop file here or <em>click to upload</em>
      </div>
      <template #tip>
        <div style="text-align: center">
          <p>jpg/png files with a size less than {{ maxSize }} MB</p>
        </div>
      </template>
    </el-upload>
  </el-dialog>
</template>
  
  <script>
  import {UploadFilled} from "@element-plus/icons";
 import {myMessage} from "@/assets/js/utils";
  import RoadCrackPredictResult from "./roadcrack-indentification-result.vue";
  import {baseURLs} from "@/axios/http-config";
  import {road_crack_predict} from "@/axios/api/others-api";
  export default {
    name: "RoadCrackPredict",
    components: {UploadFilled,RoadCrackPredictResult},
    data() {
      return{
        upload:baseURLs.imgApi+'/upload/',
        predict_preview:baseURLs.imgApi+'/predict_preview/',
        predict:baseURLs.imgApi+'/crack_predict/',
        predictVisible:false,
        fileList:[],
        maxSize:10,
        loading:false,
        loadingText:'',
        predictResultInfo:{
        "information": {
            "altitude": null,
            "latitude": 30.8,
            "location": "浙江省嘉兴市南湖区七星街道湘家荡旅游度假区",
            "longitude": 120.806,
            "time": null
        },
        "url": {
            "origin": "/origin_preview/2024-07-25-02-48-57-3141.jpg",
            "predict": "/predict_preview/2024-07-25-02-48-57-3141_predict.jpg"
        }
    }
      }
    },
    methods:{
      onChange(file, list) {
        if(file.status==="success"){
          myMessage.elSuccess('上传成功！')
        }
        if (list.length > 1 && file.status !== "fail") {
          list.splice(0, 1);
        } else if (file.status === "fail") {
          myMessage.elSuccess('上传失败，请重新上传！')
          list.splice(0, 1);
        }
      },
      beforeUpload(file){
        let sizeIsValid=false,formatIsValid=false
        if(file.size/1024/1024<10)
          sizeIsValid=true
        else myMessage.elSuccess('上传文件大小不能超过10MB')
        const suffix=file.name.split('.')[1].toLowerCase()
        console.log(suffix)
        if(suffix==='jpg'||suffix==='png'||suffix==='jpeg'){
          formatIsValid=true
        }
        else {
          myMessage.elSuccess('上传文件格式错误')
        }
        return sizeIsValid&&formatIsValid
      },
      onProgress(evt){
        console.log(evt)
      },
      onSuccess(response){
        this.loadingText='正在识别图像'
        this.loading=true
        const that=this
        road_crack_predict(this.predict+response.data).then(response=>{
          myMessage.elSuccess('识别成功,即将跳转至结果页面....')
          that.loading=false
          console.log(response);
          that.predictResultInfo=response
          that.$refs.RoadCrackPredictResult.predictResultVisible = true;
        //   setTimeout(function() {window.open(that.predict_preview+response.data.name)},1000)
        })
      }
    }
  }
  </script>
  
  <style scoped>
  /deep/ .el-upload{
    width: 100%;
  }
  /deep/ .el-upload .el-upload-dragger{
      display: flex;
      justify-content: center;
      align-items: center;
    width: 100%;
  }
  </style>