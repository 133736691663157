
import {validateFloat,
    validlatitude,
    validlongitude,
    validatedepth,
    valizoom,
    valiimgsize,
    validateIntid,
    validateSize,
    valimodifysize,
    validatexsigma,
    validatedsigma,
    validatepercentage,
    validatepersonNum,
    validateNumber,
    validatefloat
}
    from "@/assets/js/elform-verify/custom_verificate";
//基础校验规则封装

const earthquakepara = {
        mPara: [
            { required: true, message: '请输入震级大小', trigger: 'change' },
            { validator:validateFloat, trigger: 'blur' }
        ],
        maxPara:[
            { required: true, message: '请输入震级峰值', trigger: 'change' },
            { validator:validateFloat, trigger: 'blur' }
        ],
        depPara:[
            { required: true, message: '请输入震源深度', trigger: 'change' },
            {validator:validatedepth, trigger: 'blur'}
        ],
        Time:[
            { required: true, message: '请选择日期', trigger: 'blur' },
        ],
        lonPara:[
            { required: true, message: '请选择震中经度', trigger: 'change' },
            {   validator:validlongitude, trigger: 'blur'}

        ],
        latPara:[
            { required: true, message: '请选择震中纬度', trigger: 'change' },
            {   validator:validlatitude, trigger: 'blur'}
        ],

}

const imgPara = {
        zoom:[
            {required: true,validator:valizoom,trigger: 'blur'}
        ],
        imgsize:[
            {required:true, validator:valiimgsize, trigger:'blur'}
        ]
};

const fileuploadPara={
        type:[{ required: true, message: '请选择文件类型', trigger: 'blur' },],
        id:[{ required: true, message: '请输入地震编号', trigger: 'blur' },
                {validator:validateIntid, trigger: 'blur'}],
        maxSize:[{ required: true, message: '请输入文件最大值 单位（B）', trigger: 'blur' },
                {validator:validateSize, trigger: 'blur'}]
};

const processPara= {
    modifysize:[{required:true, validator:valimodifysize, trigger:'blur'}],
    'sidePara.dPara':[{required:true, validator:validatexsigma, trigger:'blur'}],
    'gsPara.xsigma':[{required:true, validator:validatexsigma, trigger:'blur'}],
    'sidePara.sigma':[{required:true, validator:validatedsigma, trigger:'blur'}],
    'sidePara.sigmaspace':[{required:true, validator:validatedsigma, trigger:'blur'}]
}

const deathParams= {

    paraAm:[{required:true, trigger:'blur'}],
    paraAden:[{required:true,trigger:'blur'}],
    paraAtime:[{required:true, trigger:'blur'}],
    paraAstrength:[{required:true, trigger:'blur'}],
    paraBdr:[{  required:true,
                validator:validatepercentage,
                trigger:'blur'}],
    paraAall:[{ required:true,
                validator:validatepercentage,
                trigger:'blur'}],
}

const savePara ={
    parapopu:[{required:true,validator:validatepersonNum, trigger:'blur'}],
    paralost:[{required:true,validator:validatepersonNum, trigger:'blur'}],
    paraarea:[{required:true, message: '请输入受灾区域面积', trigger:'blur'}],
    parainten:[{required:true, trigger:'blur'}],
}


const innerform={
    area:[{required:true,validator:validatefloat, trigger:'blur'}],
    density:[{required:true,validator: validatefloat, trigger:'blur'}],
    count:[{required:true,validator:validateNumber, trigger:'blur'}],
    type:[{required:true, trigger:'blur'}],
}

const rulerlevel={
    level1:[{required:true,validator:validatepercentage, trigger:'blur'}],
    level2:[{required:true,validator:validatepercentage, trigger:'blur'}],
    level3:[{required:true,validator:validatepercentage, trigger:'blur'}],
    level4:[{required:true,validator:validatepercentage, trigger:'blur'}],
    level5:[{required:true,validator:validatepercentage, trigger:'blur'}],
}

const rulerbury ={
    paraLMD:[{required:true,validator: validatefloat,  trigger:'blur'}],
    paraRij:[{required:true,validator:validatepercentage, trigger:'blur'}],
    paraNi:[{required:true,validator: validatepersonNum,trigger:'blur'}],
    Quakearea:[{required:true, trigger:'blur'}],
    magnValue:[{required:true,validator: validatefloat, trigger:'blur'}],
    time:[{required:true, trigger:'blur'}],
}

export {
    earthquakepara,
    imgPara,
    fileuploadPara,
    processPara,
    deathParams,
    savePara,
    innerform,
    rulerlevel,
    rulerbury
}