<template>
    <el-divider></el-divider>
    <span class="spanText" >救援物资需求配置模块：</span>
    <br>
    <div style="display:flex">
        <div class="showbox"  >
            <sourceItem :saveitem="saveItem" ref="sourceitem"></sourceItem>
        </div>
        <div style="position: absolute;top: 18%;right: 12%">
            <el-form size="medium" label-position="top" :model="saveItem" :rules="saveItemrule">
                <el-form-item label="震级" prop="parainten" >
                    <el-input placeholder="请输入震级大小" v-model="saveItem.parainten">
                    </el-input>
                </el-form-item>
                <el-form-item label="失去住所人数" prop="paralost">
                    <el-input placeholder="请输入失去住所人数" v-model="saveItem.paralost">
                    </el-input>
                </el-form-item>
                <el-form-item label="灾区面积" prop="paraarea">
                    <el-input placeholder="请输入受灾面积（km^2）" v-model="saveItem.paraarea">
                    </el-input>
                </el-form-item>
                <el-form-item label="受灾人口" prop="parapopu">
                    <el-input placeholder="请输入受灾人口" v-model="saveItem.parapopu">
                    </el-input>
                </el-form-item>
            </el-form>
            <span style="left: -40%;position: absolute;margin-top: 5%">当前评估地震信息：</span>
            <el-button type="primary" icon="el-icon-view" style="margin: 3%;margin-left: 24%" size="small" @click="notifynow">查看</el-button>
            <el-button type="primary" icon="el-icon-edit-outline" size="small" style="position: absolute;right: 0;bottom: 1.5%" @click="changeselected">修改</el-button>
            <el-button circle icon="el-icon-cpu" style="position: absolute;background-color: papayawhip;right: -23%" @click="resuletfun"></el-button>
        </div>
    </div>

</template>

<script>
import sourceItem from "@/components/echartsgraph/source-item.vue";
import {myMessage, myNotify} from "@/assets/js/utils";
import {savePara} from "@/assets/js/elform-verify/validate";
import {changeselected} from "@/assets/js/reuse-function";
export default {
    name: "supplies-estimate",
    props:{
        earthquakeInfostring:{
            type:String,
            required:true
        },
        earthquakeMain:{
            type:Number,
            required: true,
        },
    },
    components:{
        sourceItem
    },
    data(){
        return{
            saveItem:{
                parapopu:'',
                paralost:'',
                paraarea:'',
                parainten:''
            },
            saveInfo:"由于投入的物资较多，名称较杂，所以对统计数据进行了处理，衣服、大衣、棉衣按衣服进行统计，被子、棉被、毛巾被、毛毯按被子 统计，活动板房按12 m2/个折算计入帐篷个数，遮阳篷个数计入帐篷个数，方便面按一箱（件）12桶统计， 每3桶（每人1 d的食用量）\n" +
                "                相当于1 kg大米（面）折算计入大米（面）的数据，食用油投入单位为t，\n" +
                "                按照4.7 kg/桶（5 L）折算统计,床垫按床上用品统计,在总结统计图中，食用油一栏，每10桶为一个单位",
            saveItemrule:savePara
        }
    },
    mounted() {
      myNotify.elSuccess(this.saveInfo)
    },
    methods:{
        changeselected,
        notifynow(){
            this.saveItem.parainten = this.earthquakeMain
            this.$notify({
                title: '提示', message:this.earthquakeInfostring, duration: 0});
        },
        resuletfun(){
            this.$refs.sourceitem.refreshfun()
        }
    },
}
</script>

<style scoped>
.contralParaModel{
    height: 275px;
    width: 300px;
    right: 20px;
}
.spanText{
    font-size: 1.5em;
    font-weight: bold;

}
.showbox{
    height: 50vh;
    width: 50vh;
    float: left;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    /*border: 2px solid lightblue;*/
    margin-top: 2%;
    margin-left: 3%;
}
</style>