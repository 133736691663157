<template>
    <el-dialog
            title="救援物资/力量分配"
            v-model="dialogVisible"
            custom-class="custom-dialog"
            width="30%"
            :modal="false"
            >
        <div>
            <div class="modulebox">
                <span>物资分配模块：</span>
                <div class="contentbox">
                    <span>分配数量：</span>
                    <el-input v-model="allocationForm.count" style="width: 30%; margin-right: 3%" size="small"></el-input>
                    <span>分配地区：</span>
                    <el-input v-model="allocationForm.areacount" style="width: 30%;" size="small"></el-input>
                </div>
                <div style="margin-top: 2%;margin-left: 45%">
                    <el-button  @click="allocationItem" size="small" style="background-color: papayawhip" >开始分配</el-button>
                    <el-button  @click="closemenu" size="small" style="background-color: papayawhip">在地图上清除</el-button>
                </div>
            </div>
            <div class="modulebox">
                <span>救援力量分配模块：</span>
                <div class="contentbox">
                    <span>救援方式：</span>
                    <el-radio-group v-model="radiomethods" >
                        <el-radio :label="0">驾车</el-radio>
<!--                        <el-radio :label="1">步行</el-radio>-->
                        <el-radio :label="2">骑行</el-radio>
<!--                        <el-radio :label="3">电动车</el-radio>-->
                    </el-radio-group>
                </div>
                <div >
                    <span>时间限制：</span>
                    <el-input v-model="timeLimit" style="width: 24%"></el-input>
                    <span > 之内 单位（分）</span>
                    <el-button size="small" style="margin-left: 8%;background-color: papayawhip" @click="allocation" >开始分配</el-button>
                </div>
            </div>
            <div class="modulebox">
                <span>当前所选地震点信息：</span>
                <el-button type="primary" icon="el-icon-view"  size="small" @click="notifynow">查看</el-button>
                <el-button type="primary" icon="el-icon-edit-outline" size="small" @click="changeselected">修改</el-button>
                <el-button type="primary" icon="el-icon-view"  size="small" @click="viewtoearthquakearea">直达震区</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import {changeselected} from "@/assets/js/reuse-function";
import {getNearearthquakeBydisscope} from "@/axios/api/others-api";
import axios from "axios";
export default {
    name: "save-allocation",
    props:{
        selectedearthquake:{
            type:Object,
            required: true
        }
    },
    data(){
        return{
            dialogVisible:false,
            radio:true,
            allocationForm:{
                count:1000,
                areacount:15
            },
            radiomethods:0,
            timeLimit:60,
            earquakeScopeforceList:[],
            resultForcePointList:[]
        }
    },
    computed:{
        earquakeInfoAll(){
            let stringtemp = '';
            Object.keys(this.selectedearthquake).forEach(key => {
                if (key !== 'intensityLineList') {  // 检查键而不是值
                    let value = this.selectedearthquake[key];
                    // 处理不同类型的数据，确保所有内容都能被转换为字符串
                    if (value !== null && value !== undefined) {
                        stringtemp += this.textearthquake(key) + String(value) + "  ";
                    }
                }
                if (key ==='magnitude'){
                    this.earquakeMani =  this.selectedearthquake[key];
                }
                if (key ==='highIntensity'){
                    this.earquakeInten = this.selectedearthquake[key];
                }
            });
            return stringtemp
        }
    },
    methods:{
        changeselected,
        allocationItem(){
            this.$emit('allocation',this.allocationForm,true)
        },
        closemenu(){
            this.$emit('allocation',[],false)
        },
        textearthquake(type){
            if (type === 'earthquakeId'){
                return '地震编号：'
            }else if (type === 'earthquakeName'){
                return '地震名称：'
            }else if (type === 'magnitude'){
                return '地震震级：'
            }else if (type === 'highIntensity'){
                return '地震烈度：'
            }else if (type === 'longitude'){
                return '经度：'
            }else if (type === 'latitude'){
                return '纬度：'
            }else if (type === 'depth'){
                return '地震深度(m)：'
            }else if (type === 'earthquakeTime'){
                return '震发时间：'
            }else {
                return ''
            }
        },
        notifynow(){
            this.$notify({title: '提示', message:this.earquakeInfoAll, duration: 0});
        },
        viewtoearthquakearea(){
            this.$emit('viewtonow',true)
        },
        allocation(){
            let that = this
            let savemethodsList = ['driving','walking','bicycling','electrobike']

            let saveMethods = savemethodsList[this.radiomethods]
            let searchIndex = 2
            let saveTimeLimit = this.timeLimit*60
            let estimateDis = 22.5*this.timeLimit*60*searchIndex
            let longitude = this.selectedearthquake["longitude"]
            let latitude = this.selectedearthquake["latitude"]
            let params={
                distince:estimateDis,
                lontitude:longitude,
                latitude:latitude
            }
            //不使用 电动车和步行方式 原因是救援队点距离过远或受电源匮乏的影像api请求报错
            getNearearthquakeBydisscope(params).then(res=>{
                console.log(res)
                that.filtraForceList(res,saveMethods,saveTimeLimit,longitude,latitude)
            })

        },
        async filtraForceList(list, methods, timiLimit, longitude, latitude) {
            let count = list.length;
            let base_url = "https://restapi.amap.com/v5/direction/" + methods + "?";
            let key = "key=" + this.$gdapi;
            let strategy = "strategy=38";
            let show_fields = "show_fields=cost";
            let resultForcePoint = [];

            //最大并发请求个数1s/3个
            let batchSize = 3;
            function delay(ms) {
                return new Promise(resolve => setTimeout(resolve, ms));
            }
            //如果升级并发请求个数   注释掉delay函数


            const that = this;
            let MaxNum = 3;
            // 使用 for...of 循环和 async/await 顺序发起请求
            for (let i = 0; i < count; i++) {
                let origin = "origin=" + list[i].lontitude + "," + list[i].latitude;
                let destination = "destination=" + longitude + "," + latitude;
                let gdurl = base_url + origin + "&" + destination + "&" + strategy + "&" + show_fields + "&" + key;

                try {
                    let response = await axios.get(gdurl);
                    let result = response.data;
                    let getcostime = null

                    if(methods === 'driving'){
                        getcostime = parseFloat(result.route.paths[0].cost.duration);
                    }else
                    {
                        getcostime = parseFloat(result.route.paths[0].duration);
                    }


                    if (timiLimit >= getcostime) {
                        MaxNum = MaxNum -1
                        resultForcePoint.push(list[i]);

                    }
                } catch (error) {
                    console.log(error);
                }
                if (MaxNum === 0){
                    break
                }

                //如果升级并发请求个数   注释掉延迟逻辑
                if ((i + 1) % batchSize === 0) {
                    await delay(1000); // 延迟1秒
                }


            }

            // 所有请求完成后执行
            that.resultForcePointList = resultForcePoint;
            console.log(that.resultForcePointList);
            this.$emit("showForcePoint", that.resultForcePointList, true);
        },
    }
}
</script>

<style >

.custom-dialog {
    position: absolute;
    top: 10%; /* 离顶部的距离 */
    right: 2%; /* 离右边的距离 */
    margin-right: 2%; /* 重置默认的 margin */
    width: 34%;
}


.modulebox{
    margin: 4%;
}

.modulebox >span:nth-child(1){
    font-weight: bolder;
    font-size: 1.3em;

}

.modulebox .el-radio{
    font-weight: bolder;
    font-size: 1.3em;
    color: gold;
}

.modulebox .contentbox{
    margin-top: 7%;
}
</style>