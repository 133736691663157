import {baseURLs, http} from "../http-config";

//按名字搜索历史地震,params为地震名字
function getHistoryEarthquakeInfoList(params) {
    return http.get('/earthquakeInfo/getHistoryEarthquakeInfoList',{'EarthquakeName':params})
}
//地震id获取评估结果
function getEstimateResultByEarthquakeId(params) {
    return http.get('/estimate/getEstimateResultByEarthquakeId',{'earthquakeId':params})
}
//条件获取地震信息(分页)
function getEarthquakeInfoByCondition(params) {
    return http.post('/earthquakeInfo/getEarthquakeInfoByCondition',params).then(response=>{
        return response.data.list;
    })
}
//切换下一页用,初始化用
function getNextPageApi(params) {
    return http.post('/earthquakeInfo/getEarthquakeInfoByCondition',{'limit':10,'page':params,'min':4}).then(response=>{
        return response.data.list;
    })
}
//条件获取地震位置信息(地图渲染用)
function getEarthquakePositionInfoByCondition(params) {
    return http.post('/earthquakeInfo/getEarthquakePositionInfoByCondition',params).then(response=>{return response.data})
}
//查询地震带区域内信息
function getSeismicZonesEarthquakeInfo(params) {
    return http.get('/earthquakeInfo/getSeismicZonesEarthquakeInfo',params)
}
function img_predict(url,params) {
    // const url='/predict/'+name
    return http.post(
        url,params,'imgApi').then(response=>{
        response=response.data
        console.log(response.url);
        response.url[0]=baseURLs.imgApi+response.url[0]
        response.url[1]=baseURLs.imgApi+response.url[1]
        return response})
}
function regional_analysis(params){
    return http.post(
        '/regional_analysis/',params,'imgApi').then(response=>{
        response=response.data
        console.log(response);
        // response.url[0]=baseURLs.imgApi+response.url[0]
        // response.url[1]=baseURLs.imgApi+response.url[1]
        return response})
}

function road_crack_predict(url,params){
    return http.post(
        url,params,'imgApi').then(response=>{
        response=response.data
        console.log(response.url);
        response.url.origin=baseURLs.imgApi+response.url.origin
        response.url.predict=baseURLs.imgApi+response.url.predict
        return response})
}

function testPara(params){
    return http.post("/testpara", params, 'ximgApi').then(res => {
        console.log(res);
    });
}

function intentityImg(params){
    return http.post("/draweclipse",params,'ximgApi').then(res=>{
        console.log(res)
    })
}

function computedR(params) {
    return http.post("/economyloss/computedr", params).then(res => {
        return res.data;
    })
}

function houseStructureAll(){
    return http.get("/economyloss/getAllstructure").then(res=>{
        return res
    })
}

function baseStructureByall(){
    return http.get("/economyloss/getbaseall").then(res=>{
        return res.data;
    })
}

function cityStructureprice(params){
    return http.get("/economyloss/price",{'city':params}).then(res=>{
        return res.data
    })
}

function getMainStruceurePara(labelList,index){
    return http.post("/economyloss/getmainstructpara",{"labelList":labelList,'index':index}).then(res=>{
        return res.data
    })
}
function getFileUploadUrl(fileName,earthquakeId,description){
    return http.get("/cos/put/uri",{'fileName':fileName,'earthquakeId':earthquakeId,'description':description}).then(res=>{
        return res.data
    })
}

function getUploadFileStatus(params){
    return http.get('/updateFileStatus',{'fileId':params}).then(res=>{
        return res.data
    })
}

function searchFileBypagesize(params){
    return http.post('/getFileInfoListById',params).then(res=>{
        return res.data
    })
}

function getFileDownloadUrl(params){
    return http.get('/cos/get/uri',{'fileName':params}).then(res=>{
        return res.data
    })
}

function shpeFileconvertgeojson(formData){
    return http.post('/convertgeojson',formData,'ximgApi').then(res=>{
        return res.data
    })
}

function getEarthquakeInfoByyear(params){
    return http.get('/earthquakeInfo/getEarthquakeInfostaticByyear',{year:params}).then(res=>{
        return res.data
    })
}


function getSauverAllForce(){
    return http.get("/sauver/getallForce").then(res=>{
        return res.data
    })
}
function getSauverForceBylimit(params){
    return http.post("/sauver/getforceBylimit",params).then(res=>{
        return res.data
    })
}

function getSauverAllGoods(){
    return http.get("/sauver/getallgoods").then(res=>{
        return res.data
    })
}

function getPointGoodsByid(params){
    return http.get("/sauver/getpointgoodsByid",{'id':params}).then(res=>{
        return res.data
    })
}


function getNearearthquakeBydisscope(params){
    return http.post("/sauver/getNearEarthquakeBydis",params).then(res=>{
        return res.data
    })
}

function getPoputationScope(params){
    return http.post("/estimate/getPoputionScope",params).then(res=>{
        return res.data
    })
}

const uploadZipUrl=baseURLs.imgApi+'/upload/files/'

export{
getHistoryEarthquakeInfoList,
getEstimateResultByEarthquakeId,
getEarthquakeInfoByCondition,
getNextPageApi,
getEarthquakePositionInfoByCondition,
getSeismicZonesEarthquakeInfo,
img_predict,
testPara,
intentityImg,
computedR,
baseStructureByall,
houseStructureAll,
cityStructureprice,
getMainStruceurePara,
getFileUploadUrl,
getUploadFileStatus,
searchFileBypagesize,
getFileDownloadUrl,
shpeFileconvertgeojson,
getEarthquakeInfoByyear,
getSauverAllForce,
getSauverAllGoods,
    getSauverForceBylimit,
    getPointGoodsByid,
    getNearearthquakeBydisscope,
    regional_analysis,
    road_crack_predict,
    getPoputationScope,
uploadZipUrl
}