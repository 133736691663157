import * as turf from "@turf/turf";
import YunNanData from "../json/yunnan.json";
 function selectEarthquakeList(data) {
    const position=[]
    for(let i=0;i<data.length;i++){
        let arr=[]
        arr.push(data[i]['longitude'])
        arr.push(data[i]['latitude'])
        position.push(arr)
    }
    const points = turf.points(position);
    const searchWithin = turf.polygon(YunNanData['features'][0]['geometry']['coordinates']);
    const ptsWithin = turf.pointsWithinPolygon(points, searchWithin);
    // console.log(ptsWithin)
    const targetList=[]
    for (let i=0;i<ptsWithin['features'].length;i++){
        targetList.push(ptsWithin['features'][i]['geometry']['coordinates'])
    }
    // console.log(targetList)
    let earthquakeList=[]
    for(let i=0;i<targetList.length;i++){
        for (let j=0;j<data.length;j++)
            if(data[j]['longitude']===targetList[i][0]&&data[j]['latitude']===targetList[i][1])
                earthquakeList.push(data[j])
    }
    return earthquakeList
}
function sortByProperty(array,prop) {
    const compare = function (prop) {
        return function (obj1, obj2) {
            const val1 = obj1[prop];
            const val2 = obj2[prop];if (val1 < val2) {
                return -1;
            } else if (val1 > val2) {
                return 1;
            } else {
                return 0;
            }
        }
    }
    return array.sort(compare(prop))
}
function changeGeoJsonToPosition(){
     const json=YunNanData['features'][0]['geometry']['coordinates'][0];
     const position={
        west:json[0][0],
        east:json[0][0],
        north:json[0][1],
        south:json[0][1]
     }
    for(let i=0;i<json.length;i++){
        if(position['west']>json[i][0]) position['west']=json[i][0];
        if(position['east']<json[i][0]) position['east']=json[i][0];
        if(position['north']<json[i][1]) position['north']=json[i][1];
        if(position['south']>json[i][1]) position['south']=json[i][1];
    }
    return position
}

export {
     selectEarthquakeList,
     sortByProperty,
    changeGeoJsonToPosition,
}